
<div class="row">
  <app-current-state [show] = "show"></app-current-state>
</div>

      
<div class="row" style="font-family: 'Inconsolata', monospace;">
  <section class="content col-md-12 groups-container">
        <!-- Section 1 -->
    <div class="row">
        <div class="col-md-4"  style="padding-right: 0px;"> 
            <div class="card box-style">
            <div class="card-body">
                <app-activity-chart ></app-activity-chart>
            </div>
            </div>
        </div>
        <div class="col-md-4"  style="padding-right: 0px;">
            <div>
                <div class="card box-style">
                    <div class="card-body">
                        <app-activity-time-chart ></app-activity-time-chart>
                    </div>
                </div>
            </div>
        </div>
        
        
            <div class="col-md-4">
                <div>
                    <div class="card box-style">
                        <div class="card-body">
                            
                            <app-stop-chart></app-stop-chart>
                        </div>
                    </div>
                </div>
            </div>
    </div>
        <!-- Section 2 -->
    <div class="row map-section" style="margin-top: 15px">
        <div class="col-md-8" style="padding-right: 0;">    
            <div class="card box-style" style="position: relative;">
                <div class="card-body" >
                    <div class="row" style="margin: 0;">
                        <section class="content col-md-4 groups-container rm-bgcolor" >
                            <div class="col-md-12">
                                <div class="box-poi box-info collapsed-box" style="margin-bottom: 5px;margin-left: -7px;">
                                    <div class="box-header with-border">
                                        <h3 class="box-title"
                                            style="font-family: 'Inconsolata', monospace;font-size: 14px;;font-weight: bold;color: black">
                                            <i class="fa fa-map"></i> Position
                                            par P.O.I</h3>
                                        <div class="box-tools pull-right">
                                            <button type="button" class="btn btn-box-tool" data-widget="collapse" (click)="changeContentStyle()"><i class="fa fa-plus"
                                                    style="color:black"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="box-body">
                                        <div class="conten" data-widget="collapse">
                                            <app-vehicule-according-poi></app-vehicule-according-poi>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="col-12">
                            <app-leaflet [according]="true"></app-leaflet> 
                        </div>
                    </div>
                    
                                
                </div>
            </div>
            
        </div>
        <div class="col-md-4" >
            <div class="card box-style" style="height: 100%;">
                <div class="card-body car-by-group" style="padding-top: 25px;">
                    <app-monthly-available-vehicules-chart [groups]="groups">
                    </app-monthly-available-vehicules-chart>   
                </div>
            </div>
        </div> 
    </div>
        <!-- Section 3 -->
    <div class="row " style="margin-top: 15px;margin-bottom: 0px; display: flex;" >
        <div class="col-md-8"  style="padding-right: 0px;">
            <div style="height: 100%;">
                <div class="card box-style" style="height: 100%;">
                    <div class="card-body">
                    <app-daily-kilomtrage-chart [groupe] = "groups"></app-daily-kilomtrage-chart>              
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4" >
            <div style="height: 100%;">
                <div class="card box-style" style="height: 100%;">
                    <div class="card-body">
                    <app-kilomtrage-chart></app-kilomtrage-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <!-- Section 4 -->
    <div class="row " style="margin-top: 30px;margin-bottom: 0px;">
        <div class="col-md-12"  style="padding-right: 15px;">
            <div>
                <div class="card box-style" style="height: 555px; position: relative;">
                    <div class="card-body">
                            <app-quality-driving></app-quality-driving>
                    </div>
                </div>
            </div>
        </div> 
    </div>
  </section>

</div>


<!-- """""""""""""""""""""""""""""""""""""""""""""""""""""""""" -->




