import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validator } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';
import { Router } from '@angular/router';
import {
  DataManagementService,
  PoiClient,
  PointInterest,
  StateMission
} from '../../data-management';
import { PathService } from '../../historical';
import { PesageService } from '../parametrage/pesage.service';
import { Pesage } from '../parametrage/pesages';
import { Schedule } from './schedule';
import { ScheduleService } from './schedule.service';
import { Passage, PassageService } from "../parametrage/passage";
import { TransportTypeInfoDto, TransportTypeService } from "../parametrage/transport-type";
import { ValidatorDto, ValidatorSettingsService } from "../parametrage/validator-settings";

declare var $: any;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})
export class ScheduleComponent implements OnInit {
  clientSource: number;
  scheduleList: Schedule[] = [];
  scheduleListOrg: Schedule[] = [];
  scheduleListForm: Schedule[] = [];
  scheduleListBreakForm: Schedule[] = [];
  charbool = true;
  pesages: Pesage[] = [];
  matricule: Set<String> = new Set();
  dateInterval: DateInterval = new DateInterval();
  selectedPassage: Passage = new Passage();
  selectedTransport: TransportTypeInfoDto = new TransportTypeInfoDto();
  selectedType = 1;

  loading = false;

  /** states of the last schedule */
  vehiculesLength = 0;
  availableLength = 0;
  productionLength = 0;
  ActiveLength = 0;
  availableInOtherSiteLength = 0;
  backToSiteLength = 0;
  breakDownLength = 0;
  unactiveLength = 0;
  searchText: any = { matricule: '' };

  /** UPDATE TRACKING TIMER */
  updateTrackingTimer: any | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;




  @ViewChild('clientModal', { static: false }) clientModal: ModalDirective;
  @ViewChild('OTModal', { static: false }) OTModal: ModalDirective;
  @ViewChild('breakModal', { static: false }) breakModal: ModalDirective;



  startDate: Date = new Date();

  now: Date = new Date();

  isToday = true;

  selectedTransportType: string;

  isBreakDownModal = false;

  validators: ValidatorDto[] = [];

  pointInterests: String[] = [];

  vehiculesId: number[] = [];

  //poiClient: PoiClient[] = []

  poiClient: PointInterest[] = [];



  constructor(
    private stopService: PathService,
    public passageService: PassageService,
    public transportTypeService: TransportTypeService,
    public toastr: ToastrService,
    private pesageService: PesageService,
    public validatorSettingService: ValidatorSettingsService,
    private dataManagementService: DataManagementService,
    private scheduleService: ScheduleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadTransportTypes();
    this.loadValidators();
    this.loadPointInterests();
  }
  matricules: string[];
  loadTrackingInfo() {
    var idSite = this.selectedPassage.mainPoi[0].idPointInterest;
    this.scheduleService.trackingInfo(idSite).subscribe((matricules) => {
      this.backToSiteLength = matricules.length;
    });
  }





  loadPointInterests() {
    //getListOfPointClientsByType
    this.dataManagementService.getAllPointInterestsByType('MARKER').subscribe(
      (pointInterests) => {
        this.poiClient = pointInterests;
        this.pointInterests = this.poiClient.map((p) => {
          return p.name;
        });
      },
      (err) => {
        this.toastr.error('Erreur lors de chargements', 'P.O.I');
      }
    );
  }

  loadValidators() {
    this.validatorSettingService.findAllValidators().subscribe(
      (validators) => {
        this.validators = validators;
      },
      (err) => {
        this.toastr.error('Erreur lors de chargements', 'Validateur');
      }
    );
  }

  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.loading = true;
      this.transportTypeService.findAllTransportTypes().subscribe(
        (transportTypes) => {
          this.transportTypeService.transportTypes = transportTypes;
          if (transportTypes.length > 0) {
            this.selectedTransport = transportTypes[transportTypes.length - 1];
            this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
              return v.idVehicule;
            });
            this.passagesData();
            //this.loadTrackingInfo();
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Type de transport');
        }
      );
    } else {
      this.selectedTransport =
        this.transportTypeService.transportTypes[
        this.transportTypeService.transportTypes.length - 1
        ];
      this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
        return v.idVehicule;
      });
      this.passagesData();
    }
  }

  loadPassage() {
    this.loading = true;
    this.pesageService
      .getPesageInPoiOfExit(
        this.dateInterval,
        this.selectedTransport.idTransportType
      )
      .subscribe(
        (pesages) => {
          this.pesages = pesages;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Passage');
        }
      );
      
  }

  ngOnDestroy() {
    if (this.updateTrackingTimerSubscription)
      this.updateTrackingTimerSubscription.unsubscribe();
  }

  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription)
      this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 1200;

    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer;

      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.stop();
      }

    });
  }

  chooseTransport(transport: any) {
    this.selectedTransport = transport;
    this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
      return v.idVehicule;
    });
    this.stopTracking();
  }

  /** STOP TRACKING */
  stopTracking() {
    if (this.updateTrackingTimerSubscription)
      this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }

  loadAllPassage() {
    this.loading = true;
    this.passageService.findAllPassages().subscribe(
      (passages) => {
        this.passageService.passages = passages;
        if (this.passageService.passages.length > 0) {
          this.selectedPassage = this.passageService.passages[0];
          this.stop();
        } else {
          this.loading = false;
        }
      },
      (error) => {
        this.toastr.error('Erreur lors de chargements', 'Passage');
        this.loading = false;
      }
    );
  }


  passagesData() {
    if (this.passageService.passages.length > 0) {
      this.selectedPassage = this.passageService.passages[0];
      this.stop();
    } else {
      this.loadAllPassage();
    }
  }

  changeType(type: any) {
    this.selectedType = type;
  }

  chooseSchedule(passage: any) {
    this.selectedPassage = passage;
    this.stopTracking();
  }

  onChangeStartDate() {
    this.stopTracking();
  }

  
  showClientForm() {
    this.scheduleListForm = [];
    if (this.availableLength > 0 || this.availableInOtherSiteLength > 0) {
      this.matricule.forEach((mat) => {
        let schedules = this.scheduleList.filter((gr) => gr.matricule == mat);
        if (
          schedules.length > 0 &&
          schedules[schedules.length - 1].type == 'Stop'
        ) {
          this.scheduleListForm.push(schedules[schedules.length - 1]);
        }
      });
    }

    this.clientSource = this.selectedPassage.mainPoi[0].idPointInterest;
    this.clientModal.show();
  }

  clientWasSaved() {
    this.updateTrackingTimer = null;
    this.stop();
    this.clientModal.hide();
  }

  hideschedule() {
    this.clientModal.hide();
  }

  /**
   * show Break Form
   */
  showBreakForm(isBreakDownModal: boolean) {
    this.isBreakDownModal = isBreakDownModal;
    this.scheduleListBreakForm = [];
    if (this.vehiculesLength > 0) {
      this.matricule.forEach((mat) => {
        let schedules = this.scheduleList.filter((gr) => gr.matricule == mat);
        if (schedules.length > 0) {
          this.scheduleListBreakForm.push(schedules[schedules.length - 1]);
        }
      });
    }
    this.breakModal.show();
  }

  breakWasSaved() {
    this.updateTrackingTimer = null;
    this.stop();
    this.breakModal.hide();

  }

  vehiculeList: any[] = [];
  StopEndTimeMap: Map<number, Date> = new Map();

  stop() {
    this.loading = true;
    this.selectedTransportType = this.selectedTransport.name;
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.startDate.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.isToday =
      this.dateInterval.startDate.toDateString() == this.now.toDateString();

    this.availableLength = 0;
    this.productionLength = 0;
    this.ActiveLength = 0;
    this.availableInOtherSiteLength = 0;
    this.breakDownLength = 0;
    this.unactiveLength = 0;
    this.scheduleList = [];

    this.loadTrackingInfo();

    this.stopService
      .schedule({
        startDate: this.dateInterval.startDate,
        endDate: this.dateInterval.endDate,
        vehiculesId: this.vehiculesId,
        passage: this.selectedPassage,
      })
      .subscribe(
        (data) => {
          this.scheduleList = data;
          this.scheduleListOrg = data;
          this.vehiculeList = data;
          this.getOtNumberList();
          this.matricule = new Set(
            this.scheduleList.map((s) => {
              return s.matricule;
            })
          );
          this.vehiculesLength = this.matricule.size;
          this.matricule.forEach((mat) => {
            let schedules = this.scheduleList.filter((g) => g.matricule == mat);
            let lastStop = schedules.filter((s) => s.type == 'Stop');
            let maxTime = new Date(
              Math.max.apply(
                null,
                lastStop.map((e) => {
                  return e.endStopTime instanceof Date ? e.endStopTime.getTime() : new Date(e.endStopTime).getTime();
                })
              )
            );

            let lastStop2 = lastStop.find(
              (l) =>
                new Date(l.endStopTime).getTime() == new Date(maxTime).getTime()
            );
            if (lastStop2)
              this.StopEndTimeMap.set(
                lastStop2.deviceId,
                lastStop2.endStopTime
              );
            if (schedules[schedules.length - 1].type == 'Stop') {
              this.availableLength++;
            } else if (schedules[schedules.length - 1].type == 'Between') {
              this.productionLength++;
            } else if (schedules[schedules.length - 1].type == 'Start') {
              this.ActiveLength++;
            } else if (schedules[schedules.length - 1].type == 'Other') {
              this.availableInOtherSiteLength++;
            } else if (schedules[schedules.length - 1].type == 'BreakDown') {
              this.breakDownLength++;
            } else {
              this.unactiveLength++;
            }
          });

          if (this.isToday) this.startUpdateTrackingTimer();

          this.loading = false;
          //this.loadTrackingInfo();
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  showOTForm() {
    this.availableVehicule();
    this.OTModal.show();
  }
  availableVehicule() {
    this.scheduleListForm = [];
    if (
      this.availableInOtherSiteLength > 0 ||
      this.productionLength > 0 ||
      this.ActiveLength > 0
    ) {
      this.matricule.forEach((mat) => {
        let schedules = this.scheduleList.filter((gr) => gr.matricule == mat);
        if (
          schedules.length > 0 &&
          (schedules[schedules.length - 1].type == 'Between' ||
            schedules[schedules.length - 1].type == 'Start')
        ) {
          this.scheduleListForm.push(schedules[schedules.length - 1]);
        }
      });
    }
    this.scheduleListForm = this.scheduleListForm.filter(
      (item) =>
        !this.otSetVehicule.has(item.deviceId) ||
        new Date(this.otSetVehicule.get(item.deviceId)).getTime() <=
        new Date(this.StopEndTimeMap.get(item.deviceId)).getTime()
    );
  }

  hideOtMadal(event: any) {
    if (event != null) {
      this.otSetVehicule.set(event.deviceId, event.createdAt);
      for (let veh of this.vehiculeOTList) {
        if (veh.deviceId == event.deviceId) {
          veh.background = '#fff';
          veh.color = '#000';
          veh.date = event.createdAt;
          veh.otNumber = event.otNumber;
          break;
        }
      }
    }
    this.OTModal.hide();
  }
  vehiculeOTList: {
    deviceId: number;
    matricule: string;
    otNumber: string;
    date: Date;
    color: string;
    background: string;
  }[] = [];
  otExpanded: boolean = false;
  otSetVehicule: Map<number, Date> = new Map();
  getOtNumberList() {
    this.vehiculeOTList = [];
    var dateInterval = new DateInterval();
    dateInterval.startDate = new Date(this.startDate.getTime());
    dateInterval.endDate = new Date(this.startDate.getTime());
    dateInterval.startDate.setHours(0, 0, 0, 0);
    dateInterval.endDate.setHours(23, 59, 59, 59);
    this.scheduleService.getNumeroOt(dateInterval).subscribe((otList) => {
      for (let ot of otList) {
        this.otSetVehicule.set(ot.deviceId, ot.createdAt);
      }
      var vecList: any[] = [];
      if (
        this.productionLength > 0 ||
        this.ActiveLength > 0 ||
        this.availableLength > 0
      ) {
        this.matricule.forEach((mat) => {
          let schedules = this.scheduleList.filter((gr) => gr.matricule == mat);
          if (
            schedules.length > 0 &&
            (schedules[schedules.length - 1].type == 'Between' ||
              schedules[schedules.length - 1].type == 'Start' ||
              schedules[schedules.length - 1].type == 'Stop')
          ) {
            vecList.push(schedules[schedules.length - 1]);
          }
        });
      }

      for (let lv of vecList) {
        var vec = otList.find((ot) => ot.deviceId == lv.deviceId);
        if (vec != null) {
          if (
            new Date(this.otSetVehicule.get(vec.deviceId)).getTime() >=
            new Date(this.StopEndTimeMap.get(vec.deviceId)).getTime()
          ) {
            this.vehiculeOTList.push({
              deviceId: lv.deviceId,
              matricule: lv.matricule,
              otNumber: vec.otNumber,
              date: vec.createdAt,
              background: '#fff',
              color: '#000',
            });
          } else {
            this.vehiculeOTList.push({
              deviceId: lv.deviceId,
              matricule: lv.matricule,
              otNumber: '',
              date: new Date(),
              background: '#E74C3C',
              color: '#fff',
            });
          }
        } else {
          this.vehiculeOTList.push({
            deviceId: lv.deviceId,
            matricule: lv.matricule,
            otNumber: '',
            date: null,
            background: '#E74C3C',
            color: '#fff',
          });
        }
      }
    });
  }

  expendTable() {
    this.otExpanded = !this.otExpanded;
  }

  getTrackingTime(): string {
    if (this.updateTrackingTimer >= 100)
      return Math.round(this.updateTrackingTimer / 60) + " ' ";
    else var sec = this.updateTrackingTimer / 60;
    return sec.toFixed(1) + ' " ';
  }

  // Author: Zakaria Moujtazi
  getTrackingByFiltre(type: string) {
    this.loading = true;
    this.scheduleListOrg = [];
    if (type != "ALL") {
      this.matricule.forEach((mat) => {
        let schedules = this.scheduleList.filter((g) => g.matricule == mat);
        if (schedules[schedules.length - 1].type == type) {
          for (var i = 0; i < schedules.length; i++) {
            this.scheduleListOrg.push(schedules[i]);
          }
        }
      });
    } else {
      this.scheduleListOrg = this.scheduleList;
    }
    setTimeout(() => {
      this.loading = false;
    }, 10);

  }

  // Author: Zakaria Moujtazi
  navigateToAnotherPage() {
    this.router.navigate(['/client/chargesPfbMissionReturn']);
  }

  clientModalhide() {
    this.clientModal.hide();
  }



  breakModalhide() {
    this.breakModal.hide();
  }

  OTModalhide(){
    this.OTModal.hide();
  }

}
