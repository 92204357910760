import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { DataManagementService, PointInterest } from 'src/app/client-management/data-management';
import { PassageService } from '../passage.service';
import { Passage, PassageForm } from '../passages';

@Component({
  selector: 'app-passage-form',
  templateUrl: './passage-form.component.html',
  styleUrls: ['./passage-form.component.css']
})
export class PassageFormComponent implements OnInit {

  mode: "ADD" | "UPDATE";
  loading: boolean = false;
  currentPassage: Passage = new Passage();

  pointInterests: PointInterest[] = [];
  error: { errorMessage: string } = { errorMessage: '' };

  mainPoiDropdownSettings = {};
  secondaryPoiDropdownSettings = {};
  groupDropdownSettings : any;

  constructor(private route: ActivatedRoute, public toastr: ToastrService,
    private router: Router,
    private passageService: PassageService, private dataManagementService: DataManagementService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });

    this.secondaryPoiDropdownSettings = {
      singleSelection: false,
      idField: 'idPointInterest',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idPointInterest', // Must match the property in pointInterests
      textField: 'name',          // Must match the property in pointInterests
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    
  }

  init(passageId: any) {


    if (this.mode == "ADD") {
      this.loading = true;
      this.dataManagementService.getAllPointInterestsByType('MARKER').subscribe(pointInterests => {
        this.pointInterests = pointInterests;
        this.loading = false;
      },
        error => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );

    }

    if (this.mode == "UPDATE") {
      this.loading = true;
      forkJoin(
        [
          this.dataManagementService.getAllPointInterestsByType('MARKER'),
          this.passageService.getOnePassage(passageId)]
      ).subscribe(
        ([pointInterests, passage]) => {
          this.currentPassage = passage;
          this.pointInterests = pointInterests;
          this.loading = false;
        },
        error => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );
    }
  }

  cancel() {
    this.router.navigate(["/client/parametrage/chargesPfbPassage"]);
  }


  addPassage() {

    this.loading = true;
    let passageForm: PassageForm = new PassageForm();
    Object.assign(passageForm, this.currentPassage);
    passageForm.mainPoi = this.currentPassage.mainPoi.map(p => p.idPointInterest);
    passageForm.secondaryPoi = this.currentPassage.secondaryPoi.map(p => p.idPointInterest);

    this.passageService
      .addPassage(passageForm)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        passage => {
          this.loading = false;
          this.passageService.passages.push(passage);
          this.toastr.success("Passage bien ajouter !");
          this.router.navigate(["/client/parametrage/chargesPfbPassage"]);
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

  updatePassage() {
    this.loading = true;
    let passageForm: PassageForm = new PassageForm();
    Object.assign(passageForm, this.currentPassage);
    passageForm.mainPoi = this.currentPassage.mainPoi.map(p => p.idPointInterest);
    passageForm.secondaryPoi = this.currentPassage.secondaryPoi.map(p => p.idPointInterest);

    this.passageService
      .updatePassage(passageForm)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        passage => {
          this.loading = false;
          for (let passage_ of this.passageService.passages) {
            if (passage_.idPassage == passage.idPassage) {
              passage_ = passage;
              break;
            }
          }
          this.router.navigate(["/client/parametrage/chargesPfbPassage"]);
          this.toastr.success("Passage bien modifié !");
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

}
