<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row">

  <div class="col-md-12">

    <!-- Error Section !-->
    <ng-container *ngIf="error.errorMessage">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{error.errorMessage}}
        </div>
      </div>
    </ng-container>

    <div class="container">
      <ng-container *ngIf="(mode == 'ADD' || mode == 'UPDATE' )&& !loading">
        <div class=" mt-2 mb-2 text-center   well bgg">
          <h4 *ngIf="mode == 'ADD'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
            <i class="fa fa-plus-circle add"></i>
            <span>Ajouter un nouveau passage </span>
          </h4>

          <h4 *ngIf="mode == 'UPDATE'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
            <i class="fa fa-cog"></i>
            <span>Modifier passage </span>
          </h4>
        </div>
        <div class="col-lg-12 well formBC">
          <div class="row">
            <form #passageForm="ngForm" class="form-horizontal">
              <div class="col-sm-12">

                <!-- label -->
                <div class="form-group mrg">
                  <label>Label</label>
                  <input [(ngModel)]="currentPassage.name" required name="label" type="text" placeholder="Label"
                    class="form-control">
                </div>

                <!-- moyenne rotation -->

                <div class="form-group mrg">
                  <label>Nbr Passage</label>
                  <input [(ngModel)]="currentPassage.numberOfPassage" required name="numberOfPassage" type="number"
                    min="0" placeholder="Nbr passage" class="form-control">
                </div>

                <div class="form-group mrg">

                  <label>POI principal</label>
                  <ng-multiselect-dropdown *ngIf="pointInterests" name="PoiSelected" [placeholder]="'Personnalisé'"
                    [settings]="groupDropdownSettings" [data]="pointInterests"
                    [(ngModel)]="currentPassage.mainPoi"></ng-multiselect-dropdown>

                  <label>POIs secondaires</label>
                  <ng-multiselect-dropdown *ngIf="pointInterests" name="Test" [placeholder]="'Select POI'"
                    [settings]="secondaryPoiDropdownSettings" [data]="pointInterests"
                    [(ngModel)]="currentPassage.secondaryPoi"></ng-multiselect-dropdown>

                </div>
              </div>
              <!-- ========================== Modal FOOTER (START) ===============================-->
              <div class="box-footer">
                <button (click)="addPassage()" *ngIf="mode == 'ADD'" class="btn btn-info pull-right"
                  [disabled]="!passageForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
                </button>
                <button (click)="updatePassage()" *ngIf="mode == 'UPDATE'" class="btn btn-info pull-right"
                  [disabled]="!passageForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
                </button>
                <button class=" btn btn-dark" (click)="cancel()">
                  Annuler
                </button>
              </div>
              <!-- ========================== Modal FOOTER (END) ===============================-->
            </form>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>