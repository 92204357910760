import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewImportExportService } from '../../../_service/new-import-export.service';
import { User } from 'src/app/authentification/signin/credentials';
import { SigninService } from 'src/app/authentification/signin/signin.service';

@Component({
  selector: 'app-mileage-excel',
  templateUrl: './mileage-excel.component.html',
  styleUrls: ['./mileage-excel.component.scss'],
})
export class MileageExcelComponent {
  excelData: any[][] | null = null;
  excelData$: Subscription;
  current: string = '';
  public currentUser: User = new User();
  selectedRowIndex: number | null = null;
  activiteIndex: number;
  sitesFournisseursIndex: number;
  currentReport: void;
  rowspans: number[] = []; // Tableau pour stocker les valeurs de rowspan

  constructor(
    private importExportService: NewImportExportService,
    private authService: SigninService
  ) {
    const storedUser = localStorage.getItem('currentUser');
    this.currentUser = storedUser ? JSON.parse(storedUser) : {};
  }

  ngOnInit(): void {
    this.excelData$ = this.importExportService
      .getExcelData()
      .subscribe((value) => {
        if (
          value != null &&
          Array.isArray(value) &&
          value.every((row) => Array.isArray(row))
        ) {
          this.excelData = value as any[][];
          this.calculateRowspans();
          this.selectedRowIndex = null;
        }
      });
    console.log(this.excelData);
    this.fetchUser();
  }

  selectRow(index: number): void {
    this.selectedRowIndex = index;
  }

  fetchUser() {
    this.current = this.authService.getCurrentUser().displayName;
  }

  ngOnDestroy(): void {
    if (this.excelData$) {
      this.excelData$.unsubscribe();
    }
    this.importExportService.setExcelData(null);
    this.importExportService.setCurentRapport(null);
    this.importExportService.setPdfToBePrinted(null);
    this.importExportService.setExcelToBeProcessed(null);
    this.selectedRowIndex = null;
  }

  isEmptyColumn(index: number): boolean {
    // Vérifie si `excelData` est défini et contient au moins 5 lignes
    if (!this.excelData || this.excelData.length <= 4) {
      return true; // Considère la colonne comme vide si les données sont insuffisantes
    }

    // Vérifie si toutes les cellules de la colonne sont vides
    return this.excelData.slice(4).every((row) => !row[index]?.trim());
  }

  getRowspanForHeader(index: number): number {
    if (
      !this.excelData ||
      (this.excelData[3].length > 3 && !this.excelData[3][index])
    ) {
      // Si la cellule est vide et la suivante est non vide, fusionner avec la suivante
      return 2; // Par exemple, fusionner avec la suivante
    }
    return 1;
  }

  getRowspanForCell(rowIndex: number, colIndex: number): number {
    // Vérification si la cellule est vide et la cellule suivante est non vide
    if (
      !this.excelData ||
      (this.excelData[rowIndex][colIndex] === '' &&
        this.excelData[rowIndex][colIndex + 1] !== '')
    ) {
      return 0; // Fusionner avec la cellule suivante
    }
    return 1;
  }

  /**
   * Calculer le rowspan pour chaque cellule de la première colonne
   */
  calculateRowspans(): void {
    if (!this.excelData) return;

    const dataRows = this.excelData.slice(4); // Supposons que les données commencent à la 4ème ligne
    this.rowspans = Array(dataRows.length).fill(0); // Initialiser le tableau de rowspans

    let i = 0;
    while (i < dataRows.length) {
      const cellValue = dataRows[i][0];
      if (cellValue && cellValue.trim() !== '') {
        let rowspan = 1;
        let j = i + 1;

        // Compter les lignes successives ayant soit une cellule vide, soit la même valeur
        while (
          j < dataRows.length &&
          (!dataRows[j][0] ||
            dataRows[j][0].trim() === '' ||
            dataRows[j][0] === cellValue)
        ) {
          rowspan++;
          j++;
        }

        // Appliquer le rowspan à la ligne de départ
        this.rowspans[i] = rowspan;

        // Masquer les lignes fusionnées
        for (let k = i + 1; k < i + rowspan; k++) {
          this.rowspans[k] = 0; // 0 indique que la cellule doit être masquée
        }

        i += rowspan; // Sauter les lignes fusionnées
      } else {
        // La cellule est vide et ne fait pas partie d'une fusion, donc masquer
        this.rowspans[i] = 0;
        i++;
      }
    }
  }

  findIndexByValue(value: string): number {
    return this.excelData?.findIndex((row) => row[0] === value) ?? -1;
  }
}
