import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculeComponent } from './vehicule.component'; // Adjust the path if necessary
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // For modal
import { VehiculeService } from '../providers/vehicule.service'; // Ensure the path is correct
import { RouterModule } from '@angular/router'; // If you need routing
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NoValuePipeModule } from 'src/app/pipes/no-value.module';
import { VehiculeDetailsModule } from './vehicule-form/vehicule-details/vehicule-details.module';
import { VehiculeDetailModule } from './vehicule-details/vehicule-details.module';
import { DataManagementModule } from '../data-management.module';

@NgModule({
  declarations: [
    VehiculeComponent  
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),  
    ModalModule.forRoot(), 
    RouterModule,  
    NgxLoadingModule.forRoot({}),
    PaginationModule.forRoot(),
    NoValuePipeModule,
    VehiculeDetailsModule,
    VehiculeDetailModule,
    DataManagementModule
  ],
  providers: [
    VehiculeService
  ],
  exports: [
    VehiculeComponent
  ]
})
export class VehiculeModule { }
