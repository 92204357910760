import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import {
  GroupsVehicules,
  RapportDto,
  ReportAuto,
} from 'src/app/client-management/data-management/data-management.model';

import { DateInterval } from 'src/app/shared/model';
import { User } from 'src/app/authentification/signin/credentials';

@Injectable({
  providedIn: 'root',
})
export class NewImportExportRestService {
  currentUser: User = new User();
  constructor(private _http: HttpClient, private router: Router) {}

  getAllGroups(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'groupes/minifyOnlyGroups', {
      headers: headers,
    });
  }

  getAllVehicules(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'vehicules/minifyVehicules', {
      headers: headers,
    });
  }

  getGlobalStopsRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'stops/globalStop?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getListOfStopsRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'stops/stopList?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getMileageRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'mileage/mileage?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getDailyMileageRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'mileage/daily_mileage?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getEtatActuelReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'devices/etat_actuel?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getClientReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'stops/site_poiclient?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getDriverReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'drivers/driver_list?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }
  getPOIsReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'stops/site_poi?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getEventReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'vehicules/event_report?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getEtatArretReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'devices/etat_boitier_arret?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getActivityContact(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'activities/activity_contact?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getPointageRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'activities/pointage_repport?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getDrivingQuality(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'drivequality/driving_quality?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getActivitySpeed(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'activities/activity_speed?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getDailyActivitySpeed(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'activities/daily_activity_speed?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getSpeedingReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'notification/speeding_report?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getStopNotification(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'stops/stop_notification?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getPathPOI(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'paths/path_poi?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getPathFuel(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'paths/path_fuel?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getFuelVariation(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'fuelings/fuel_variation?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getSuiviVidange(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'oilChanges/suivi_vidange?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }
  getAllPois(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'pointInterests/dtos', { headers: headers });
  }

  getPaths(
    repportOf: string,
    GroupsOrVehicules: GroupsVehicules,
    dateBegin: number,
    dateEnd: number
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'pathsExGlobal?repportOf=' +
        repportOf +
        '&startDate=' +
        dateBegin +
        '&endDate=' +
        dateEnd,
      GroupsOrVehicules,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getArretLieuxConnux(
    pois: string[],
    startDate: number,
    endDate: number,
    minute: number
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'arretLieuxConnusEx?minute=' +
        minute +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate,
      pois,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }
}
