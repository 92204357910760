import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { GroupDto } from 'src/app/client-management/data-management';
import { DateInterval } from 'src/app/shared/model';
import { MonthlyMaintenanceCostsService } from './monthly-maintenance-costs.service';
import { ParentData } from 'src/app/client-management/parc-management';
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/stock')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);

@Component({
  selector: 'app-monthly-maintenance-costs',
  templateUrl: './monthly-maintenance-costs.component.html',
  styleUrls: ['./monthly-maintenance-costs.component.css'],
})
export class MonthlyMaintenanceCostsComponent implements OnInit {
  globalLoading: boolean;
  monthlyMaintenanceCostsChart: Object | null = null;
  monthlyMaintenanceCostsChartModal: Object | null = null;
  monthlyMaintenanceCostsDto: any[] = [];
  monthlyMaintenanceCosts: any[] = [];
  isByMultipleGroups: boolean = false;
  groupIds: GroupDto[] = [];

  @Input()
  groups: GroupDto[] = [];

  @Input()
  depensedataNew: ParentData;

  selectedMonth: number;
  groupDropdownSettings = {};
  dropdownSettings: any;

  months = [
    { id: 1, name: 'Janvier' },
    { id: 2, name: 'Février' },
    { id: 3, name: 'Mars' },
    { id: 4, name: 'Avril' },
    { id: 5, name: 'Mai' },
    { id: 6, name: 'Juin' },
    { id: 7, name: 'Juillet' },
    { id: 8, name: 'Août' },
    { id: 9, name: 'Septembre' },
    { id: 10, name: 'Octobre' },
    { id: 11, name: 'Novembre' },
    { id: 12, name: 'Décembre' },
    { id: -1, name: 'Date Personnaliser' },
  ];
  startDate: Date = new Date();
  endDate: Date = new Date();
  date: DateInterval = new DateInterval();

  displayCalendar: boolean = false;
  showModal: boolean = false;

  constructor(
    private monthlyMaintenanceCostsService: MonthlyMaintenanceCostsService
  ) { }

  ngOnInit() {

    console.log(this.groups);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.selectedMonth = new Date().getMonth() + 1;
    this.endDate = new Date(
      new Date().getFullYear(),
      this.selectedMonth,
      0,
      23,
      59,
      59,
      59
    );
    this.startDate = new Date(
      new Date().getFullYear(),
      this.selectedMonth - 1,
      1,
      0,
      0,
      0,
      0
    );
    this.loadMonthlyMaintenanceCosts();
  }

  ngOnChanges(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    console.log(this.depensedataNew);

    if (this.depensedataNew.groups == "") {
      this.startDate = new Date(this.depensedataNew.datestart);
      this.endDate = new Date(this.depensedataNew.dateEnd);
      this.loadMonthlyMaintenanceCosts();
    } else {
      console.log(this.depensedataNew);
    }

  }


  // onValidate() {
  //   console.log(this.monthlyMaintenanceCosts);
  //   this.monthlyMaintenanceCostsDto = this.monthlyMaintenanceCosts.filter((m) =>{
  //     if(m.id == this.depensedataNew.groups){
  //       return m;
  //     }
  //   } );
  //   console.log(this.monthlyMaintenanceCostsDto);
  //   this.monthlyMaintenanceCostsChartWithDetails();
  // }

  onItemSelect(event: any) {
  }


  onValidate() {
    this.monthlyMaintenanceCostsDto = this.monthlyMaintenanceCosts.filter((m) =>
      this.groupIds.map((g) => g.idGroupe).includes(m.id)
    );
    this.monthlyMaintenanceCostsChartWithDetails();
  }


  onChangeByMultipleGroups() {
    this.groupIds = [];
    if (!this.isByMultipleGroups) {
      this.monthlyMaintenanceCostsDto = this.monthlyMaintenanceCosts;
      this.monthlyMaintenanceCostsChartWithDetails();
    }
  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.endDate < this.startDate) {
      this.endDate = null;
    }
  }

  onMonthChange() {
    if (this.selectedMonth != null) {
      if (this.selectedMonth > 0) {
        this.displayCalendar = false;
        this.endDate = new Date(
          new Date().getFullYear(),
          this.selectedMonth,
          0,
          23,
          59,
          59,
          59
        );
        this.startDate = new Date(
          new Date().getFullYear(),
          this.selectedMonth - 1,
          1,
          0,
          0,
          0,
          0
        );
        this.loadMonthlyMaintenanceCosts();
      } else {
        this.displayCalendar = true;
      }
    }
  }

  loadMonthlyMaintenanceCosts() {
    this.globalLoading = true;
    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    this.monthlyMaintenanceCostsService
      .getMonthlyCostOfMaintenaceByGroup(this.date)
      .subscribe((res) => {
        this.monthlyMaintenanceCosts = res;

        if (this.isByMultipleGroups)
          this.monthlyMaintenanceCostsDto = this.monthlyMaintenanceCosts.filter(
            (m) => this.groupIds.map((g) => g.idGroupe).includes(m.id)
          );
        else this.monthlyMaintenanceCostsDto = res;

        this.monthlyMaintenanceCostsChartWithDetails();
        this.globalLoading = false;
      });
  }

  MonthlyMaintenanceCostsChart() {
    this.setHighCartsLang();
    /** Category **/
    var cat: any[] = [];
    // Create the chart
    var dataPneu: any[] = [];
    var dataReparation: any[] = [];
    var dataBatterie: any[] = [];
    var dataFrein: any[] = [];
    var averageData: number[] = [];
    var length = this.monthlyMaintenanceCostsDto.length;

    this.monthlyMaintenanceCostsDto.forEach((maintenance) => {
      cat.push(maintenance.nom);
      dataPneu.push(maintenance.tire);
      dataReparation.push(maintenance.reparation);
      dataBatterie.push(maintenance.battery);
      dataFrein.push(maintenance.brake);
      averageData.push(
        (maintenance.tire +
          maintenance.reparation +
          maintenance.battery +
          maintenance.brake) /
        4
      );
    });

    this.monthlyMaintenanceCostsChart = {
      chart: {
        type: 'column',
        // height: 320,
        styledMode: true,
      },
      title: {
        text: 'Coût de maintenance du Parc Auto en DH Mensuel',

        style: {
          fontWeight: 'bold',
        },
      },

      xAxis: {
        categories: cat,
        crosshair: true,
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontSize: '11px',
            textDecoration: 'underline',
          },
        },
        title: {
          text: null,
        },
        min: 0,
        max: length < 6 ? length - 1 : 6,
        scrollbar: {
          enabled: length < 6 ? false : true,
        },
      },
      yAxis: {
        // min: 0,
        // max: 20000,
        title: {
          text: 'DH',
        },
        labels: {
          // formatter: function () {
          //   return this.value + ' DH';
          // },
        },
        valueSuffix: 'DH',
      },
      tooltip: {
        followPointer: false,
        shared: true,
        valueDecimals: 2,
        valueSuffix: 'DH',
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          borderRadius: 5,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Pneu',
          data: dataPneu,
        },
        {
          name: 'Batterie',
          data: dataBatterie,
        },
        {
          name: 'Réparation',
          data: dataReparation,
        },
        {
          name: 'Frein',
          data: dataFrein,
        },
        {
          type: 'spline',
          name: 'Moyenne',
          data: averageData,
          marker: {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[4],
            fillColor: 'white',
          },
        },
        {
          type: 'pie',
          name: 'Total',
          data: [
            {
              name: 'Pneu',
              y: dataPneu.reduce((acc, cur) => acc + cur, 0),
              color: Highcharts.getOptions().colors[0], // Pneu color
            },
            {
              name: 'Batterie',
              y: dataBatterie.reduce((acc, cur) => acc + cur, 0),
              color: Highcharts.getOptions().colors[1], // Batterie color
            },
            {
              name: 'Réparation',
              y: dataReparation.reduce((acc, cur) => acc + cur, 0),
              color: Highcharts.getOptions().colors[2], // Réparation color
            },
            {
              name: 'Frein',
              y: dataFrein.reduce((acc, cur) => acc + cur, 0),
              color: Highcharts.getOptions().colors[3], // Frein color
            },
          ],
          center: [100, 20],
          size: 82,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    Highcharts.chart(
      'monthlyMaintenanceCosts',
      this.monthlyMaintenanceCostsChart
    );
  }

  monthlyMaintenanceCostsChartWithDetails() {
    this.setHighCartsLang();
    var that = this;
    var drilldata: { name: any; id: any; data: any[]; }[] = [];
    var data: { name: string; y: number; p: number; drilldown: number }[] = [];

    var total = 0;

    for (var i = 0; i < this.monthlyMaintenanceCostsDto.length; i++) {
      total +=
        this.monthlyMaintenanceCostsDto[i].tire +
        this.monthlyMaintenanceCostsDto[i].reparation +
        this.monthlyMaintenanceCostsDto[i].battery +
        this.monthlyMaintenanceCostsDto[i].brake;
    }

    this.monthlyMaintenanceCostsDto.forEach((maintenance) => {
      var cost =
        maintenance.tire +
        maintenance.reparation +
        maintenance.battery +
        maintenance.brake +
        maintenance.oilchange;
      data.push({
        name: maintenance.nom,
        y: cost,
        p: (cost * 100) / total,
        drilldown: maintenance.id,
      });
    });

    this.monthlyMaintenanceCostsChart = {
      chart: {
        type: 'column',
        height: 370,
        events: {
          drilldown: function (e: any) {
            if (!e.seriesOptions) {
              var vehiculesdata: any[] = [];
              that.monthlyMaintenanceCostsService
                .getMonthlyCostOfMaintenaceByGroupId(
                  e.point.drilldown,
                  that.date
                )
                .subscribe((res) => {
                  for (let i = 0; i < res.length; i++) {
                    vehiculesdata.push([
                      'Vehicule : ' + res[i].nom + '  <br /> ',
                      res[i].tire +
                      res[i].reparation +
                      res[i].battery +
                      res[i].brake +
                      res[i].oilchange,
                    ]);
                    drilldata.push({
                      name: e.point.name,
                      id: e.point.drilldown,
                      data: vehiculesdata,
                    });
                  }
                });
            }
          },
        },
      },
      title: {
        text: 'Coût de maintenance du Parc Auto en DH Mensuel',
        style: {
          fontWeight: 'bold',
        },
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: 'underline',
          },
        },
      },
      yAxis: {
        // min: 0,
        // max: 20000,
        title: {
          text: 'DH',
        },

        labels: {
          // formatter: function(value :any) {
          //    return this.value + ' DH';
          // },

          formatter: function (value: string) {
            return value + ' DH';
          },

        },
        valueSuffix: 'DH',
      },
      tooltip: {
        followPointer: false,
        valueDecimals: 2,
        valueSuffix: 'DH',
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span> <b>Coût : {point.y:,.2f}DH</b> <br/>',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f} ',
            softThreshold: true,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Groupe',
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f}',
          },
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">{point.name} </span><br/><b>Coût : {point.y:,.2f}DH</b> <br />' +
              ' <b>{point.p:.2f}%</b>',
          },
          data: data,
        },
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        series: drilldata,
      },
    };

    this.monthlyMaintenanceCostsChartModal = {
      chart: {
        type: 'column',
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; name: any; }; }) {
            if (!e.seriesOptions) {
              var vehiculesdata: any[] = [];
              that.monthlyMaintenanceCostsService
                .getMonthlyCostOfMaintenaceByGroupId(
                  e.point.drilldown,
                  that.date
                )
                .subscribe((res) => {
                  for (let i = 0; i < res.length; i++) {
                    vehiculesdata.push([
                      'Vehicule : ' + res[i].nom + '  <br /> ',
                      res[i].tire +
                      res[i].reparation +
                      res[i].battery +
                      res[i].brake +
                      res[i].oilchange,
                    ]);
                    drilldata.push({
                      name: e.point.name,
                      id: e.point.drilldown,
                      data: vehiculesdata,
                    });
                  }
                });
            }
          },
        },
      },
      title: {
        text: 'Coût de maintenance du Parc Auto en DH Mensuel',
        style: {
          fontWeight: 'bold',
        },
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: 'underline',
          },
        },
      },
      yAxis: {
        // min: 0,
        // max: 20000,
        title: {
          text: 'DH',
        },
        labels: {
          // formatter: function () {
          //   return this.value + ' DH';
          // },
        },
        valueSuffix: 'DH',
      },
      tooltip: {
        followPointer: false,
        valueDecimals: 2,
        valueSuffix: 'DH',
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span> <b>Coût : {point.y:,.2f}DH</b> <br/>',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f} ',
            softThreshold: true,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Groupe',
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f}',
          },
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">{point.name} </span><br/><b>Coût : {point.y:,.2f}DH</b> <br />' +
              ' <b>{point.p:.2f}%</b>',
          },
          data: data,
        },
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        series: drilldata,
      },
    };
    Highcharts.chart(
      'monthlyMaintenanceCosts',
      this.monthlyMaintenanceCostsChart
    );
    Highcharts.chart(
      'monthlyMaintenanceCostsModal',
      this.monthlyMaintenanceCostsChartModal
    );
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7',
        '#e77e22',
        '#3ea6dc',
        '#47a4d2',
        '#51a2c7',
        '#5a9fbd',
        '#639db3',
        '#6d9ba8',
        '#76999e',
        '#7f9694',
        '#899489',
        '#92927f',
        '#9c9074',
        '#a58d6a',
        '#ae8b60',
        '#b88955',
        '#c1874b',
        '#ca8441',
        '#d48236',
        '#dd802c',
        '#e77e22',
      ],
      //colors: ['#f39c12', '#24CBE5', '#64E572', '#007bff', '#66B92E', '#DA932C', '#D65B4A', '#6AF9C4', '#FF9655', '#854442', '#3d1e6d', '#000000', '#adcbe3', '#f37736', '#c68642', '#00c0ef', ' #451e3e', '#D81B60', '#3D9970', '#605ca8', '#001F3F'],
      lang: {
        printChart: 'Imprimer',
        decimalPoint: ',',
        thousandsSep: ' ',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: "Aucune donnée n'est disponible",
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
    });
  }
}
