<div class="text-center">

    <highcharts-chart
     *ngIf="_fuels.length > 0  && currentCarbSetting.fuelAverage" 
     style="display: inline-block; width: 100%"
     [Highcharts]="Highcharts"
     [options]="options">
    </highcharts-chart>
    

    <div *ngIf="_fuels.length == 0  || !currentCarbSetting.fuelAverage" class="alert alert-dismissible" style="border-radius: 0px;">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <h4 style="color: orange"><i class="fa fa-info-circle"></i> Information</h4>
        <div *ngIf="_fuels.length == 0">Veuillez ajouter des informations pour carburants.</div>
        <div *ngIf="!currentCarbSetting.fuelAverage">
                <a style="color: white; text-decoration-color: white;" [routerLink]="['/client/statistical/fuelSettings']">Cliquer ici</a>
             pour ajouter la consomation théorique de la véhicule «{{selectedVehicule?.matricule}}».</div>
    </div>



</div>

