<div class="driver-actvity-container">

    <div style="display: flex; gap: 20px;">
        <div class="row" style="padding-top:  3px;">
            <div class="form-group" class="col-md-6">


                <input *ngIf="isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="startDate"
                    placeholder="Date Début" [(ngModel)]="repport.dateInterval.startDate" [bsConfig]="{
                        dateInputFormat: 'DD-MM-YYYY',
                        containerClass: 'theme-dark-blue'
                      }" [maxDate]="maxDate" placement="bottom" autocomplete="off" class="calender-item" />

                <input *ngIf="!isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="startDate"
                    placeholder="Date Début" [(ngModel)]="repport.dateInterval.startDate" [bsConfig]="{
                        dateInputFormat: 'DD-MM-YYYY HH:mm',
                        containerClass: 'theme-dark-blue'
                      }" [maxDate]="maxDate" placement="bottom" autocomplete="off" class="calender-item" />

            </div>
            <div class="form-group" class="col-md-6">

                <input *ngIf="isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="endDate"
                    placeholder="Date Fin" [(ngModel)]="repport.dateInterval.endDate" [bsConfig]="{
                            dateInputFormat: 'DD-MM-YYYY',
                            containerClass: 'theme-dark-blue'
                        }" [minDate]="repport.dateInterval.startDate" placement="bottom" autocomplete="off"
                    class="calender-item" />

                <input *ngIf="!isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="endDate"
                    placeholder="Date Fin" [(ngModel)]="repport.dateInterval.endDate" [bsConfig]="{
                            dateInputFormat: 'DD-MM-YYYY HH:mm',
                            containerClass: 'theme-dark-blue'
                        }" [minDate]="repport.dateInterval.startDate" placement="bottom" autocomplete="off"
                    (ngModelChange)="setTimeTo2359()" class="calender-item" />

            </div>
        </div>

        <div class="inputs-container">
            <!-- choose report option (all vehicule ,by group or by vehicule)-->
            <div>

                <select class="form-control" [(ngModel)]="Selectedoption" (change)="onSelectingReportOf()"
                    name="selectedoption" class="calender-item" disabled>
                    <option style="font-size: 12px" *ngFor="let opt of selectReportOf" [value]="opt.name">
                        {{ opt.label }}
                    </option>
                </select>

            </div>
            <!--display al groups if user has choose it-->
            <div *ngIf="this.repport.rapportPayloadDto.byGroups ">
                <!-- <label class="col-sm-2 control-label" style="color: limegreen">Groupes</label> -->
                <div>
                    <ng-select *ngIf="groupsItems.length != 0 && multipleSelect" [items]="groupsItems"
                        [(ngModel)]="selectedGroupsItems" [multiple]="true" bindLabel="text" bindValue="id"
                        (data)="selectedGroups($event)" [clearable]="true" placeholder="Aucun groupe"
                        class="ng-select-container">
                    </ng-select>
                    <div *ngIf="!multipleSelect" class="scrollable-menu" [(ngModel)]="repport.rapportPayloadDto.idGroup"
                        name="groups">
                        <option style="font-size: 11px" *ngFor="let g of groups" [ngValue]="g.idGroupe">
                            <span *ngIf="g.nameGroupParent">{{
                                g.nameGroupParent | capitalizefirst | truncate : [30]
                                }}
                                / </span>{{ g.nom }}
                        </option>
                    </div>

                </div>
            </div>

            <!-- display all vehicules of user choose this option-->
            <div *ngIf="this.repport.rapportPayloadDto.byVehicules ">
                <!-- <label class="col-sm-2 control-label" style="color: limegreen">Véhicules</label> -->
                <div>
                    <ng-select *ngIf="vehiculesItems.length != 0 && multipleSelect" [multiple]="true"
                        [items]="vehiculesItems" bindLabel="text" bindValue="id" placeholder="Aucune véhicule"
                        [(ngModel)]="vehiculesSelected" [clearable]="true" (data)="selectedVehicules($event)"
                        class="ng-select-container">
                    </ng-select>

                    <select *ngIf="!multipleSelect" [(ngModel)]="repport.rapportPayloadDto.idVehicule" name="vehicules">
                        <option style="font-size: 11px" *ngFor="let v of vehicules" [ngValue]="v.idVehicule">
                            {{ v.mark != null && v.mark != "" ? v.mark : v.matricule }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <!--if report neads to choose start and end date-->

    <div class="action-buttons">
        <div style="padding-right: 10px;">
            <button *ngIf=" !isShowingExcel" class="download-button"
                style="background: rgba(0, 128, 0, .788); color: beige;" (click)="showExcel()">
                <i class="fa fa-file-excel-o" style="font-size: 14px;"></i>
            </button>
            <div *ngIf="isShowingExcel" class="download-button"
                style="background: rgba(0, 128, 0, .788);color: beige; size:inherit;">
                <span class="display-loader"></span>
            </div>
        </div>




        <!-- Global Download Button (if needed) -->
        <div>
            <button *ngIf=" !isDownloading" class="download-button" (click)="downloadExcel()">
                <mat-icon style="   height: 20px;
    width: 24px;font-size: 18px; ">download</mat-icon>
            </button>
            <div *ngIf="isDownloading" class="download-button">
                <span class="loader"></span>
            </div>
        </div>
    </div>
</div>