<ngx-loading [show]="globalLoading"></ngx-loading>
<div class="card">
  <div class="card-header">
    <div class="row" #customScroll style="font-size: 10px;">
      <div class="col-md-1">
        <p (click)="openModal()">
          ...
        </p>
      </div>
      <div class="col-md-3" style="    padding: 2px;"   [ngClass]="{'col-md-3': isByMultipleGroups, 'col-md-5': !isByMultipleGroups}">
        <select class="form-control input-sm select3" (change)="onChange($event.target.value)">
          <option [value]="ChartTypes[i].id" *ngFor="let charttype of ChartTypes; let i = index">
            {{ ChartTypes[i].name }}
          </option>
        </select>
      </div>
      <div  *ngIf="!IsByParent" style=" padding: 2px;"    [ngClass]="{'col-md-3': isByMultipleGroups, 'col-md-5': !isByMultipleGroups}" >
        <select class="form-control input-sm select3" [(ngModel)]="isByMultipleGroups"
          (ngModelChange)="onChangeByMultipleGroups()">
          <option [ngValue]="false">Tous</option>
          <option [ngValue]="true">Groupe</option>
        </select>
      </div>
      <div class="col-md-4" style=" padding-left: 2px; top: 2px;"  *ngIf="isByMultipleGroups">
        <ng-multiselect-dropdown [placeholder]="'personnalisé'" [settings]="groupDropdownSettings" [data]="groups"
          [(ngModel)]="groupIds" (onSelect)="onItemSelect($event)">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-md-1 btnSerch"  *ngIf="isByMultipleGroups">
        <span class="spanBtnSerch" (click)="onValidate()" style="background-color: rgb(7, 146, 7);">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>

    <!-- <div *ngIf="isByMultipleGroups && !IsByParent" class="row">
      <div class="col-md-12">
        <form #groupForm="ngForm" class="form-horizontal">
          <div class="row">


            <div class="col-lg-4 col-md-4">
              <div class="form-group">
                <button type="button" (click)="onValidate()" class="btn btn-success btn-block">
                  <i class="fa fa-search"></i>
                  Valider
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div> -->

    
  </div>
  <div class="card-body">
    <div id="bar" style="zoom: 0.54" class="w-100 d-inline-block"></div>
  </div>
</div>

<!-- Bootstrap Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{ show: showModal }" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <p>Historique Canal / Groupe / vehicule par Mois</p> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="barModal" style="width: 100%; height: 100%"></div>
      </div>
    </div>
  </div>
</div>
