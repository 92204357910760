import { Component } from '@angular/core';
import { NewImportExportService } from '../../_service/new-import-export.service';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-pdf-table',
  templateUrl: './pdf-table.component.html',
  styleUrls: ['./pdf-table.component.scss'],
})
export class PdfTableComponent {
  private isDarkTheme: boolean;
  private isSidebarDarkTheme: boolean;
  private isRightSidebarTheme: boolean;
  private isHideSidebarTheme: boolean;
  private isHeaderDarkTheme: boolean;
  private isCardBorderTheme: boolean;
  private isCardBorderRadiusTheme: boolean;
  private isRTLEnabledTheme: boolean;

  constructor(
    private importExpotService: NewImportExportService,
    public signinService: SigninService
  ) {
    this.isDarkTheme = JSON.parse(localStorage.getItem('isDarkTheme')!);
    this.isSidebarDarkTheme = JSON.parse(
      localStorage.getItem('isSidebarDarkTheme')!
    );
    this.isRightSidebarTheme = JSON.parse(
      localStorage.getItem('isRightSidebarTheme')!
    );
    this.isHideSidebarTheme = JSON.parse(
      localStorage.getItem('isHideSidebarTheme')!
    );
    this.isHeaderDarkTheme = JSON.parse(
      localStorage.getItem('isHeaderDarkTheme')!
    );
    this.isCardBorderTheme = JSON.parse(
      localStorage.getItem('isCardBorderTheme')!
    );
    this.isCardBorderRadiusTheme = JSON.parse(
      localStorage.getItem('isCardBorderRadiusTheme')!
    );
    this.isRTLEnabledTheme = JSON.parse(
      localStorage.getItem('isRTLEnabledTheme')!
    );
  }

  panelOpenState = false;

  setCurentRapport(name: string) { 
    this.importExpotService.setCurentRapport(name);
  }

  changeStyle(event: Event): void {
    const target = event.target as HTMLElement;

    // Ajout ou suppression d'une classe pour modifier le style
    if (event.type === 'mouseenter') {
      target.classList.add('hovered');
    } else if (event.type === 'mouseleave') {
      target.classList.remove('hovered');
    }
  }
  onClick() {
    this.importExpotService.showPdfEmitter.emit();
  }

  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme;
    localStorage.setItem('isDarkTheme', JSON.stringify(this.isDarkTheme));
  }

  toggleSidebarTheme() {
    this.isSidebarDarkTheme = !this.isSidebarDarkTheme;
    localStorage.setItem(
      'isSidebarDarkTheme',
      JSON.stringify(this.isSidebarDarkTheme)
    );
  }

  toggleRightSidebarTheme() {
    this.isRightSidebarTheme = !this.isRightSidebarTheme;
    localStorage.setItem(
      'isRightSidebarTheme',
      JSON.stringify(this.isRightSidebarTheme)
    );
  }

  toggleHideSidebarTheme() {
    this.isHideSidebarTheme = !this.isHideSidebarTheme;
    localStorage.setItem(
      'isHideSidebarTheme',
      JSON.stringify(this.isHideSidebarTheme)
    );
  }

  toggleHeaderTheme() {
    this.isHeaderDarkTheme = !this.isHeaderDarkTheme;
    localStorage.setItem(
      'isHeaderDarkTheme',
      JSON.stringify(this.isHeaderDarkTheme)
    );
  }

  toggleCardBorderTheme() {
    this.isCardBorderTheme = !this.isCardBorderTheme;
    localStorage.setItem(
      'isCardBorderTheme',
      JSON.stringify(this.isCardBorderTheme)
    );
  }

  toggleCardBorderRadiusTheme() {
    this.isCardBorderRadiusTheme = !this.isCardBorderRadiusTheme;
    localStorage.setItem(
      'isCardBorderRadiusTheme',
      JSON.stringify(this.isCardBorderRadiusTheme)
    );
  }

  toggleRTLEnabledTheme() {
    this.isRTLEnabledTheme = !this.isRTLEnabledTheme;
    localStorage.setItem(
      'isRTLEnabledTheme',
      JSON.stringify(this.isRTLEnabledTheme)
    );
  }

  isDark() {
    return this.isDarkTheme;
  }

  isSidebarDark() {
    return this.isSidebarDarkTheme;
  }

  isRightSidebar() {
    return this.isRightSidebarTheme;
  }

  isHideSidebar() {
    return this.isHideSidebarTheme;
  }

  isHeaderDark() {
    return this.isHeaderDarkTheme;
  }

  isCardBorder() {
    return this.isCardBorderTheme;
  }

  isCardBorderRadius() {
    return this.isCardBorderRadiusTheme;
  }

  isRTLEnabled() {
    return this.isRTLEnabledTheme;
  }

  private isToggled = new BehaviorSubject<boolean>(false);

  get isToggled$() {
    return this.isToggled.asObservable();
  }

  toggle() {
    this.isToggled.next(!this.isToggled.value);
  }
}
