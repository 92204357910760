import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import { Subscription } from 'rxjs';
import { MapService } from './service/map.service';
import { DataManagementService } from '../../client-management/data-management/data-management.service';
import { imagesPOIDir } from '../../global.config';
import {
  PointInterest,
  PoiClient,
} from '../../client-management/data-management/data-management.model';
import { GeocodingService } from './service/geocoding.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../authentification/signin/credentials';
import 'leaflet-draw';
import * as Geocoding from 'esri-leaflet-geocoder';
import { ModalDirective } from 'ngx-bootstrap/modal';



@Component({
  selector: 'app-leaflet',
  templateUrl: './leaflet.component.html',
  styleUrls: ['./leaflet.component.css'],
})
export class LeafletComponent implements OnInit, OnDestroy {
  public currentUser: User = new User();
  apikey: string = "AAPTxy8BH1VEsoebNVZXo8HurBfdRBARffau4-9lr0xdYk5iPy0cT6o5xhqeu_UYCPo71zwPrqj7bV_6bpb20farc_WQ_5SVqWCLmjew6iApUg5NO69PdnfAHQ8aN8XqXl3v5SUJVLkHVW4Lj0XgyROjh1Mzt84frDPOxiMZLOni91bFwu8LSoX3k6M2hLJ_Pv5r2TM4VAxsldSAP63yBhPYZ8foG-l_SCRFbDVS_9KjoNc.AT1_7dkDNsGF"

  markerWasAddedSubscription: Subscription;
  subsctiptionLoadingIntersts: Subscription;

  pointInterests: PointInterest[];
  pointInterest: PointInterest = new PointInterest();
  selectedPoiForm: String = 'POI';
  poiClient: PoiClient = new PoiClient();
  poiClients: PoiClient[];
  poiClientsPoint: PoiClient[] = [];
  poisInMap = false;
  selectedType: any;
  showButtons = true;
  Selectedclient = false;
  SelectedPoi = false;
  status: boolean = false;
  isFullscreen = this.mapService.isFullscreen;

  @ViewChild('childModal') public childModal: ModalDirective;

  @Input()
  according: boolean = false;

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  @Input()
  set item(type: any) {
    this.selectedType = type;
  }

  @Input()
  set hidebutton(hidebutton: boolean) {
    this.showButtons = hidebutton;
  }

  constructor(
    private mapService: MapService,
    private dataManagementService: DataManagementService,
    private geocodingService: GeocodingService,
    public toastr: ToastrService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  selectedPoiType(mode: string) {
    if (mode == '1') {
      this.selectedPoiForm = 'CLIENT';
      this.status = !this.status;
    } else {
      this.selectedPoiForm = 'POI';
      this.status = !this.status;
    }
  }

  ngAfterViewInit() {
    this.markerWasAddedSubscription = this.mapService.markerWasAdded.subscribe(
      (e) => {
        let type = e.layerType;
        if (type == 'marker') {
          if (this.selectedType == 'client') {
            this.poiClient.coordinate = e.layer._latlng;
            this.poiClient.type = 0;
          } else {
            this.pointInterest.coordinate = e.layer._latlng;
            this.pointInterest.type = 0;
          }
        }

        if (type == 'polygon') {
          let polygon = new L.Polygon(e.layer._latlngs[0]);
          if (this.selectedType == 'client') {
            this.poiClient.hash = this.decode(e.layer._latlngs[0]);
            this.poiClient.coordinate = polygon.getBounds().getCenter();
            this.poiClient.type = 1;
            this.poiClient.myPois = [];
          } else {
            this.pointInterest.hash = this.decode(e.layer._latlngs[0]);
            this.pointInterest.coordinate = polygon.getBounds().getCenter();
            this.pointInterest.type = 1;
          }
        }

        var geoLat: number;
        var geoLng: number;
        if (this.selectedType == 'client') {
          geoLat = this.poiClient.coordinate.lat;
          geoLng = this.poiClient.coordinate.lng;
        } else {
          geoLat = this.pointInterest.coordinate.lat;
          geoLng = this.pointInterest.coordinate.lng;
        }
        this.geocodingService
          .inverseGeoconding(geoLat, geoLng, 17)
          .subscribe((adress) => {
            if (this.selectedType == 'client')
              this.poiClient.address = adress.display_name;
            else this.pointInterest.address = adress.display_name;
          });
        this.childModal.show();
      }
    );
    if (this.selectedType == 'client') {
      if (this.dataManagementService.pointClient) {
        this.poiClients = this.dataManagementService.pointClient;
        this.poiClientsPoint = this.poiClients.filter(
          (p) => p.type === 'MARKER'
        );
      } else {
        this.dataManagementService
          .getAllPointClients()
          .subscribe((pointClients) => {
            this.dataManagementService.pointClient = pointClients;
            this.poiClients = pointClients;
            this.poiClientsPoint = this.poiClients.filter(
              (p) => p.type === 'MARKER'
            );
          });
      }
    } else {
      if (this.dataManagementService.pointInterests) {
        this.pointInterests = this.dataManagementService.pointInterests;
      } else {
        this.dataManagementService
          .getAllPointInterests()
          .subscribe((pointInterests) => {
            this.dataManagementService.pointInterests = pointInterests;
            this.pointInterests = pointInterests;
          });
      }
    }
  }

  ngOnDestroy() {
    this.childModal = null;
    this.subsctiptionLoadingIntersts.unsubscribe();
    if (this.mapService.map) {
      this.mapService.map.remove();
    }
  }


  showButtonPoi: boolean = false;

  showpoi: boolean = false;
  showZone: boolean = false;

  ngOnInit() {
    setTimeout(() => {
      this.mapConfig();
    }, 50);
    this.subsctiptionLoadingIntersts = this.mapService
      .getDrawIntrests()
      .subscribe(() => {
        
        if(this.showButtonPoi){
          this.showButtonPoi = false;
        }else{
          this.showButtonPoi = true;
        }

      });
  }

  showpoiclick() {
    this.showZone = false;
    this.showpoi = true;
    this.drawPointInterests();

  }

  showpoizoneclick() {
    this.showZone = true;
    this.showpoi = false;
    this.drawPointInterests();
  }



  mapConfig() {
    let defaultLayer = this.mapService.baseMaps.OpenStreetMap;
    var polygonOption: any = false;
    var markerOption: any = false;

    let optionsSpec: any = {
      zoomControl: false,
      attributionControl: false,
      center: L.latLng(28.59705693258839, -14.844456375015065),
      zoom: 5.3,
      zoomSnap: 0.3,
      minZoom: 3,
      maxZoom: 20,
      maxNativeZoom: 17,
      layers: [defaultLayer]
    };

    let map = L.map('map', optionsSpec);
    map.invalidateSize();
    this.drawMoroccoMap(map);
    this.drawMoroccoRegions();
    this.mapService.map = map;
    L.control.scale({ position: 'bottomleft' }).addTo(map);
    this.mapService.mapLoaded.emit(true);

    this.drawButtons(map);

    map.on(L.Draw.Event.CREATED, (e: any) => {
      var type = e.layerType;

      if (type === 'marker' || type === 'polygon') {
        console.log("Marker or Polygon added");

        this.mapService.markerWasAdded.emit(e);
      }
    });



    this.mapService.map = map;
    this.mapService.buttonConfig();
    L.control
      .layers(this.mapService.baseMaps, null, { position: 'topright' })
      .addTo(map);
    L.control.zoom({ position: 'topright' }).addTo(map);
  }

  drawButtons(map: L.Map) {
    const customIcon = L.icon({
      iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png', // Correct default icon
      iconSize: [18, 30],
      iconAnchor: [8, 35],
      popupAnchor: [1, -30],
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png', // Optional shadow image
      shadowSize: [36, 36],
    });

    const polygonOption: L.DrawOptions.PolygonOptions = {
      allowIntersection: false,
      drawError: {
        color: '#e1e100',
        message: "<strong>Oh snap!<strong> you can't draw that!",
      },
      shapeOptions: {
        color: '#bada55',
      },
      showArea: false,
    };

    const markerOption: L.DrawOptions.MarkerOptions = {
      icon: customIcon,
    };

    const drawControl = new L.Control.Draw({
      position: 'topright',
      draw: {
        rectangle: false,
        circle: false,
        polyline: false,
        circlemarker: false,
        marker: markerOption,
        polygon: polygonOption,
      },
    });

    map.addControl(drawControl);
  }


  searchButton(map: any) {
    let arcgisOnline = (Geocoding as any).arcgisOnlineProvider({
      countries: ['MAR, MA'],
      apikey: this.apikey,
    });
    const searchControl = (Geocoding as any).geosearch({
      position: 'topright',
      placeholder: 'Rechercher des lieux ou des adresses',
      title: 'Rechercher sur la carte',
      providers: [arcgisOnline],
    }).addTo(map);
    const results = L.layerGroup([]).addTo(map);
    // listen for the results event and add every result to the map
    searchControl.on('results', (data: any) => {
      results.clearLayers();
      for (let i = (<any>data).results.length - 1; i >= 0; i--) {
        results.addLayer(L.marker((<any>data).results[i].latlng));
      }
    });
  }

  drawPointInterests() {
    this.mapService.removeMarkersPoiFromMap();
    this.mapService.removePolygonsPoiFromMap();
    this.mapService.removeCirclesFromMap();

    if (this.poisInMap) {
      this.poisInMap = false;
      return;
    } else {
      if (this.selectedType == 'client') {
        if (this.dataManagementService.pointClient == null) {
          this.dataManagementService
            .getAllPointClients()
            .subscribe((pointClients) => {
              this.dataManagementService.pointClient = pointClients;
              this.poiClients = pointClients;
              pointClients.forEach((pointClient) => {
                this.drawPointInterest(pointClient);
              });
            });
        } else {
          this.dataManagementService.pointClient.forEach((pointClient) => {
            this.drawPointInterest(pointClient);
          });
        }
      } else {
        if (this.dataManagementService.pointInterests == null) {
          this.dataManagementService
            .getAllPointInterests()
            .subscribe((pointInterests) => {
              this.dataManagementService.pointInterests = pointInterests;
              this.pointInterests = pointInterests;
              pointInterests.forEach((pointInterest) => {
                this.drawPointInterest(pointInterest);
              });
            });
        } else {
          this.dataManagementService.pointInterests.forEach((pointInterest) => {
            this.drawPointInterest(pointInterest);
          });
        }
      }
      this.poisInMap = true;
    }
  }



  drawMoroccoMap(map: L.Map) {
    fetch('assets/geoJson/morocco.geojson')
      .then(response => response.json())
      .then(data => {
        L.geoJSON(data, {
          style: {
            fillColor: 'transparent',
            color: '#053887',
            weight: 1
          }
        }).addTo(map);
      })
      .catch(error => {
        console.error('Error loading GeoJSON:', error);
      });
  }
  drawMoroccoRegions() {
    fetch('assets/geoJson/ma.json')
      .then(response => response.json())
      .then(data => {
        this.processJSONData(data);
      })
      .catch(error => {
        console.error('Error loading GeoJSON:', error);
      });
  }
  processJSONData(data: any): void {
    const features = data.features;
    features.forEach((feature: any) => {
      const name = feature.properties.name;
      const coordinates: any = feature.geometry.coordinates;
      coordinates.forEach((ring: any[]) => {
        ring.forEach(coord => coord.reverse());
      });
      this.mapService.addPolygonMap(name, coordinates, { color: '#053887', weight: 1, fillColor: '#cdd7e7', });
    });
    // this.mapService.togglePolygons(false);

  }
  drawPointInterest(pointInterest: PointInterest | PoiClient) {
    let popup =
      "<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" +
      pointInterest.name +
      '</strong><br><hr><b>Adresse : ' +
      pointInterest.address +
      '</b>';

    let marker: L.Marker | null = null;

    if (this.showpoi) {

      marker = new L.Marker(pointInterest.coordinate);
      marker.on('click', () => {

        this.mapService.map.setView(pointInterest.coordinate, 17);
      });

      marker.on('mouseover', () => {
        marker.openPopup();
      });

      marker.bindPopup(popup);
      marker.setIcon(
        new L.Icon({
          iconUrl: imagesPOIDir + pointInterest.imageUri,
          iconAnchor: [-2, 10],
          popupAnchor: [10, -25],
        })
      );

      this.mapService.addMarkerPoi(marker);

    } else {

      if (pointInterest.type == 'POLYGON') {
        let polygon = new L.Polygon(pointInterest.decode);
        if (pointInterest.plyColor != null) {
          polygon.setStyle({
            fillColor: pointInterest.plyColor,
            color: pointInterest.plyColor,
            weight: 2,
          });
        }
        this.mapService.addPolygonPoi(polygon);
      }
    }


  }

  decode(points: any[]): string {
    let temp = '';
    points.forEach((point, i) => {
      if (i != points.length) temp += point.lat + ',' + point.lng + ';';
      else temp += point.lat + ',' + point.lng;
    });
    return temp;
  }

  searchText: string = '';
  suggestions: any[] = [];

  isSearchVisible: boolean = false;

  // Toggle the search input visibility
  toggleSearch(): void {
    this.isSearchVisible = !this.isSearchVisible;
  }

  fetchSuggestions(): void {
    if (this.searchText.length < 3) {
      this.suggestions = [];
      return;
    }

    this.geocodingService.getSuggestions(this.searchText, 'MAR,MA').subscribe(
      (response) => {
        this.suggestions = response.suggestions || [];
      },
      (error) => {
        console.error('Error fetching suggestions:', error);
      }
    );
  }

  selectSuggestion(suggestion: any): void {
    this.searchText = suggestion.text;
    this.suggestions = [];
    this.isSearchVisible = false; // Close search on selection
    if (suggestion.magicKey) {
      this.geocodingService.getLocationFromSuggestion(suggestion.magicKey).subscribe(
        (locationResponse) => {
          const { x, y } = locationResponse.candidates[0].location;
          this.updateMap(y, x);
        },
        (error) => {
          console.error('Error fetching location:', error);
        }
      );
    }
  }
  coordinate = { lat: 0, lng: 0 };
  updateMap(y: number, x: number) {
    this.mapService.setView({ lat: y, lng: x });
  }

}
