import { Component, OnInit } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
// import { BsLocaleService } from 'ngx-bootstrap';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrService } from 'ngx-toastr';
import { rapportsDir, dns } from '../../global.config';
import {
  Group,
  ImportFileDto,
  RapportDto,
  VehiculesDto,
  PointInterest,
  PoiClient,
  ReportAuto,
} from '../data-management/data-management.model';
import { DataManagementService } from '../data-management/data-management.service';
import { rapportsItem } from '../import-export/report-item';
import { ImportExportService } from './import-export.service';
import { providers, reportOf } from './report-item';
import { User } from 'src/app/authentification/signin/credentials';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { TransportTypeInfoDto, TransportTypeService } from '../charges-pfb';
import { CanalInfoPfbDto } from '../canal-performance';
import { CanalPfbService } from '../charges-pfb/parametrage/_service/canalpfb.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.css'],
})
export class ImportExportComponent implements OnInit {
  /** is multiple select */
  multipleSelect = true;
  /**report by POI */
  isByPOI = false;
  isByPOIClient = false;
  isByNonPOi = false;
  /**loader */
  public ANIMATION_TYPES = ngxLoadingAnimationTypes;
  /**date oly or datetime */
  isDateOnly = false;
  /** var decalaration ! (start) */
  loading = true;
  /**show loader element */
  loader = false;
  /**true if a report need start and end date */
  starEnd = false;
  /**show nested options(all_vehicule,by_group,by_vehicule) */
  reportOf = true;
  /** */
  isExporting = true;
  /**Report of group of vehicule */
  byGroup = false;
  /**report of vehicule */
  byVehicule = false;
  /** show message dialog(alert warning)*/
  showWarning = false;
  /**used to dismiss view */
  isdismissed = true;
  /** loader*/
  groups: Group[];
  /**locations of stored report template(only for downoald) */
  templateDownoaldLocation: string = rapportsDir;
  /**file selected by the user */
  selectedFile: File = null;
  modalBody!: string;
  /**payload contains all nested information for report downoald */
  repport: RapportDto = new RapportDto();
  importData: ImportFileDto = new ImportFileDto();
  vehicules: VehiculesDto[] = [];
  /**available report */
  rapports = rapportsItem;
  /**providers ,we need that for file import */
  providers = providers;
  /**option */
  Selectedoption: string = 'ALL_VEHICULES';
  /**options */
  selectReportOf = reportOf;
  /**current user */
  currentUser: User = new User();

  maxDate: Date = new Date();
  vehiculesItems: { text: string | number; id: number }[] = [];
  vehiculesSelected: any[] = [];
  retard = false;
  fueling = false;
  isByPoint = false;
  isByEndPOI = false;
  isByYear = false;
  withProvider = true;
  choosen = false;

  isPole = false;
  selectedTransport: TransportTypeInfoDto;
  selectedCanal: CanalInfoPfbDto = new CanalInfoPfbDto();

  tousCanal = new CanalInfoPfbDto(-1, 'Tous les Sites');
  tousTransport = { idTransportType: -1, name: 'Tous les Transports' };

  selectedTemplateUpload: string;

  poiName: PointInterest[];
  filteredPoi: PointInterest[] = [];
  // POI CLIENT
  poiClients: PoiClient[] = [];
  poiProspects: PoiClient[] = [];
  poiConcurrents: PoiClient[] = [];
  poiIntermediare: PoiClient[] = [];
  poiConcessionnaires: PoiClient[] = [];
  poiDepots: PoiClient[] = [];
  poiDrivers: PoiClient[] = [];
  poiClientsGros: PoiClient[] = [];
  poiClientSelected: string[] = [];
  clientGrosSelected: string[] = [];
  prospectSelected: string[] = [];
  intermediaireSelected: string[] = [];
  concurrentSelected: string[] = [];
  driverSelected: string[] = [];
  depotSelected: string[] = [];
  concessionnaireSelected: string[] = [];

  groupsItems: { text: string | number; id: number }[] = [];
  selectedGroupsItems: any[] = [];
  selectedGroupsHash: string = '';
  selectedVehiculesHash: string = '';

  // report auto
  reportAutos: ReportAuto[] = [];
  currentReportAuto: ReportAuto = new ReportAuto();
  modeAuto: boolean = false;
  mode = 'SHOW';
  error: { errorMessage: string } = { errorMessage: '' };

  auth: any;
  optionId!: number;
  rapportsItemsAuthority: any[] = new Array();
  rapportsItems: any[] = new Array();

  years = [
    { id: new Date().getFullYear() },
    { id: new Date().getFullYear() - 1 },
    { id: new Date().getFullYear() - 2 },
    { id: new Date().getFullYear() - 3 },
  ];

  selectedYear: number;

  constructor(
    private service: ImportExportService,
    private dataManagementService: DataManagementService,
    private transportTypeService: TransportTypeService,
    private canalPfbService: CanalPfbService,
    private toastr: ToastrService,
    private localeService: BsLocaleService,
    private router: Router,
    public signinService: SigninService
  ) {
    this.repport.type = 'ETAT_ACTUEL';
    this.selectedYear = this.years[0].id;
  }

  ngOnInit() {
    this.localeService.use('fr');

    var toDay = new Date();
    this.repport.dateInterval.startDate = new Date(
      toDay.getFullYear(),
      toDay.getMonth(),
      toDay.getDate(),
      0,
      0,
      0,
      0
    );
    this.repport.dateInterval.endDate = new Date(
      toDay.getFullYear(),
      toDay.getMonth(),
      toDay.getDate(),
      23,
      59,
      59,
      59
    );
    this.repport.dateInterval.startDate.setTime(
      this.repport.dateInterval.startDate.getTime()
    );

    /**set current user  */
    this.currentUser = JSON.parse(
      localStorage.getItem('currentUser') as string
    );

    let k = 0;
    for (let i = 0; i < this.rapports.length; i++) {
      this.auth = this.rapports[i].authority;
      this.optionId = this.rapports[i].id;
      if (
        this.optionId == 0 ||
        this.signinService.isHasRole(this.auth) ||
        this.signinService.isHasOption(this.optionId)
      ) {
        this.rapportsItemsAuthority[k] = this.rapports[i];
        k++;
      }
    }

    this.loadTransportTypes();
    this.loadCanalPfb();

    if (
      this.currentUser &&
      ((this.currentUser.options &&
        this.currentUser.options.find((op) => op.idOption == 15)) ||
        (this.currentUser.authorities &&
          this.currentUser.authorities.find(
            (a) => a.name === 'ROLE_TEMPLATE_CARBURANT_ALEQ'
          )))
    ) {
      if (!this.providers.some((p) => p.name === 'TOTAL_ALEQ')) {
        this.providers.splice(2, 0, {
          name: 'TOTAL_ALEQ',
          label: 'TOTAL ALEQ',
        });
      }
    } else if (
      this.currentUser &&
      ((this.currentUser.options &&
        this.currentUser.options.find((op) => op.idOption == 7)) ||
        (this.currentUser.authorities &&
          this.currentUser.authorities.find(
            (a) => a.name === 'ROLE_TEMPLATE_CARBURANT_NOMATIS'
          )))
    ) {
      if (!this.providers.some((p) => p.name === 'TOTAL_NOMATIS')) {
        this.providers.splice(2, 0, {
          name: 'TOTAL_NOMATIS',
          label: 'TOTAL NOMATIS',
        });
      }
    } else if (
      this.currentUser &&
      ((this.currentUser.options &&
        this.currentUser.options.find((op) => op.idOption == 17)) ||
        (this.currentUser.authorities &&
          this.currentUser.authorities.find(
            (a) => a.name === 'ROLE_TEMPLATE_CARBURANT_AMA'
          )))
    ) {
      if (!this.providers.some((p) => p.name === 'TOTAL_AMA')) {
        this.providers.splice(2, 0, { name: 'TOTAL_AMA', label: 'TOTAL AMA' });
      }
    }
    if (
      this.currentUser &&
      ((this.currentUser.options &&
        this.currentUser.options.find((op) => op.idOption == 25)) ||
        (this.currentUser.authorities &&
          this.currentUser.authorities.find(
            (a) => a.name === 'ROLE_TEMPLATE_CARBURANT_SAM'
          )))
    ) {
      if (!this.providers.some((p) => p.name === 'TOTAL_SAM')) {
        this.providers.splice(3, 0, { name: 'TOTAL_SAM', label: 'SAM' });
      }
    }

    /**get only groups with more than one vehicule */
    this.dataManagementService.getAllGroupsDetails('').subscribe((res) => {
      this.groups = res.filter((group) => group.vehicules.length > 0);
      this.mapGroupsToItems(this.groups);
      if (this.groups) {
        this.getVehicules();
      }
    });

    this.dataManagementService
      .getListNamesOfPointInterests()
      .subscribe((res) => {
        this.poiName = res;
      });

    this.dataManagementService
      .getListOfPointClientsByType('MARKER')
      .subscribe((res) => {
        this.poiClientsGros = res.filter((p) => p.clientType === 'CLIENT_GROS');
        this.poiConcurrents = res.filter((p) => p.clientType === 'CONCURENT');
        this.poiDrivers = res.filter((p) => p.clientType === 'DRIVER');
        this.poiIntermediare = res.filter(
          (p) => p.clientType === 'INTERMEDIAIRE'
        );
        this.poiProspects = res.filter((p) => p.clientType === 'PROSPECT');
        this.poiClients = res.filter((p) => p.clientType === 'CLIENT');
        this.poiConcessionnaires = res.filter(
          (p) => p.clientType === 'CONCESSIONNAIRE'
        );
        this.poiDepots = res.filter((p) => p.clientType === 'DEPOT');
      });

    this.selectImportOrExport('export');
  }

  /**get vehicule  Ids */
  getVehicules() {
    this.groups.forEach((group) => {
      group.vehicules.forEach((vehicule) => {
        let v = new VehiculesDto();
        v.matricule = vehicule.matricule;
        v.idVehicule = vehicule.idVehicule;
        if (this.checkIfVehiculeExists(v)) {
          this.vehicules.push(v);
        }
      });
    });

    this.vehiculesToItems(this.vehicules);
  }

  /** separate rapportsItem by type */
  selectImportOrExport(mode: any) {
    if (mode === 'import') {
      this.rapportsItems = this.rapportsItemsAuthority.filter(
        (s) => s.type === 'IMPORT'
      );
      this.repport.type = 'IMPORT_FUEL';
    } else if (mode === 'export') {
      this.rapportsItems = this.rapportsItemsAuthority.filter(
        (s) => s.type === 'EXPORT'
      );
      this.repport.type = 'ETAT_ACTUEL';
    } else {
      this.rapportsItems = this.rapportsItemsAuthority.filter(
        (s) => s.type === 'LIST'
      );
      this.repport.type = 'RUNNING_DOCUMENT';
    }
    this.onSelectingReport();
  }

  mapGroupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].nameGroupParent) {
        parentName = groups[i].nameGroupParent + ' / ' + groups[i].nom;
      } else {
        parentName = groups[i].nom;
      }
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName,
      });
    }
  }

  selectedGroups(groups: { id: number; text: string }[]) {
    let prefix = '';
    this.selectedGroupsHash = '';
    groups.forEach((group) => {
      this.selectedGroupsHash += prefix + group.id;
      prefix = ',';
    });
    this.repport.rapportPayloadDto.idGroups = this.selectedGroupsHash
      .split(',')
      .map((x) => +x);
  }

  vehiculesToItems(vehicules: VehiculesDto[]) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({
        id: vehicules[i].idVehicule,
        text: vehicules[i].matricule,
      });
    }
  }

  selectedVehicules(vehicules: { id: number; text: string }[]) {
    let prefix = '';
    this.selectedVehiculesHash = '';
    vehicules.forEach((vehicule) => {
      this.selectedVehiculesHash += prefix + vehicule.id;
      prefix = ',';
    });
    this.repport.rapportPayloadDto.idVehicules = this.selectedVehiculesHash
      .split(',')
      .map((x) => +x);
  }

  selectPole() {
    this.selectedTransport.idTransportType;
  }

  selectCanal() {
    this.selectedCanal.idCanal;
  }

  /** check if vehicule already exists */
  checkIfVehiculeExists(vehicule: VehiculesDto) {
    let v = this.vehicules.filter(
      (ve) => ve.idVehicule === vehicule.idVehicule
    );
    if (v.length !== 0) {
      return false;
    } else {
      return true;
    }
  }
  /**start data export*/
  onSelectingReport() {
    this.selectReportOf = reportOf;
    this.init();
    /**stwitch according to report type ,the view will be change on changing report type */
    switch (this.repport.type) {
      /**export */
      case 'KILOMETRAGE':
      case 'RAPPORT_POINTAGE_BY_ZONE':
      case 'PATH_FUEL':
      case 'ACTIVITY':
      case 'ACTIVITY_DAILY_GLOBAL':
      case 'ACTIVITY_GLOBAL':
      case 'ACTIVITY_SPEED':
      case 'ACTIVITY_SPEED_GLOBAL':
      case 'ACTIVITY_MEAN':
      case 'DIVIDED_ACTIVITY_SPEED':
      case 'KILOMETRAGE_DAILY':
      case 'ARRETS':
      case 'FUELING':
      case 'RUNNING_DOCUMENT':
      case 'OIL_CHANGE':
      case 'BRAKE':
      case 'OFFENSE':
      case 'OFFENSES_MODULE':
      case 'ARRETS_GLOBAL':
      case 'TIRE':
      case 'BATTERY':
      case 'REPARATION_MODULE':
      case 'PATH_FUEL':
      case 'PATH_FUEL_POI':
      case 'PATH_POI':
      case 'DAILY_PATH_APPRO_FUEL_POI':
      case 'PATH_POI_COUNT':
      case 'TRANSPORT_BASE':
      case 'PATH_WITH_START_OR_END_POI':
      case 'PATH_ZONE':
      case 'REP_FUEL_VARIATION_GN':
      case 'STOP_BY_POI':
      case 'STOP_BY_POI_CLIENT':
      case 'STOP_NON_POI':
      case 'DAILY_STOP_BY_POI':
      case 'DAILY_STOP_BY_NON_POI':
      case 'DELAY':
      case 'SPEEDING':
      case 'GLOBAL_SPEEDING':
      case 'STOP_IN_POI_NOTIFICATION':
      case 'ACTIVITY_DAILY_NOTIFICATIONS':
      case 'ACTIVITY_NOTIFICATIONS':
      case 'PARCK_MANAGEMENT':
      case 'TRAVEL_SITE':
      case 'EXPIRATION_DOCUMENT':
      case 'CUMULATIVE_COST_OF_PARK_MANAGEMENT':
      case 'DRIVER_ASSIGNMENT_HISTORY':
      case 'OIL_CHANGE_MONITORING':
      case 'FUELING_MONITORING_CONSUMPTION':
      case 'FUEL_CONSUMPTION_DIFFERENCE':
      case 'FUELING_CONSUMPTION_ANALYSIS':
      case 'STOP_BY_GROUP_AND_POI_GLOBAL':
      case 'EVENT_DATA':
      case 'DRIVING_QUALITY':
      case 'ARCHIVEPOI':
        this.isByNonPOi = false;
        this.isByPOI = false;
        this.isByEndPOI = false;
        this.isByPoint = false;
        this.isByYear = false;
        this.isPole = false;
        if (
          this.repport.type === 'PATH_FUEL_POI' ||
          this.repport.type === 'PATH_POI' ||
          this.repport.type === 'PATH_ZONE' ||
          this.repport.type === 'TRANSPORT_BASE' ||
          this.repport.type === 'PATH_POI_COUNT'
        ) {
          if (this.repport.type === 'PATH_ZONE') {
            this.filteredPoi = Array.isArray(this.poiName)
              ? this.poiName.filter((p) => p.type === 'POLYGON')
              : [];
          } else {
            this.filteredPoi = Array.isArray(this.poiName)
              ? this.poiName.filter((p) => p.type === 'MARKER')
              : [];
          }
          this.isByEndPOI = true;
          this.isByPoint = true;
        } else {
          this.filteredPoi = Array.isArray(this.poiName)
            ? this.poiName.filter((p) => p.type === 'MARKER')
            : [];
        }

        this.isDateOnly = this.repport.type === 'ACTIVITY' ? true : false;
        this.isByPOI =
          this.repport.type === 'STOP_BY_POI' ||
          this.repport.type === 'DAILY_STOP_BY_POI'
            ? true
            : false;
        this.isByPOIClient =
          this.repport.type === 'STOP_BY_POI_CLIENT' ? true : false;

        this.retard = false;
        this.fueling = false;
        if (
          this.repport.type === 'EXPIRATION_DOCUMENT' ||
          this.repport.type === 'DRIVER_ASSIGNMENT_HISTORY' ||
          this.repport.type === 'OIL_CHANGE_MONITORING' ||
          this.repport.type === 'FUELING_MONITORING_CONSUMPTION' ||
          this.repport.type === 'FUEL_CONSUMPTION_DIFFERENCE' ||
          this.repport.type === 'FUELING_CONSUMPTION_ANALYSIS'
        ) {
          this.isDateOnly = true;
        }
        if (
          this.repport.type === 'STOP_NON_POI' ||
          this.repport.type === 'DAILY_STOP_BY_NON_POI'
        ) {
          this.isByNonPOi = true;
          this.repport.byNONPOI = true;
          this.repport.byPOI = false;
        }
        if (
          this.repport.type === 'STOP_BY_POI' ||
          this.repport.type === 'DAILY_STOP_BY_POI' ||
          this.repport.type === 'STOP_BY_GROUP_AND_POI_GLOBAL' ||
          this.repport.type === 'ARCHIVEPOI'
        ) {
          this.isByPOI = true;
          this.repport.byPOI = true;
          this.repport.byNONPOI = false;
          if (this.repport.type === 'STOP_BY_GROUP_AND_POI_GLOBAL') {
            this.selectReportOf = reportOf.filter(
              (r) => r.name != 'BY_VEHICULE'
            );
          }
        }
        if (this.repport.type === 'DELAY') {
          this.repport.byPOI = true;
          this.repport.byNONPOI = false;
          this.retard = true;
        }
        if (this.repport.type === 'FUELING') {
          this.fueling = true;
        }

        this.repport.rapportPayloadDto.global =
          this.repport.type === 'ARRETS_GLOBAL' ? true : false;
        this.reportOf = true;
        this.starEnd = true;
        this.isExporting = true;
        if (this.repport.type === 'CUMULATIVE_COST_OF_PARK_MANAGEMENT') {
          this.multipleSelect = false;
        }
        break;
      case 'SITE_CLIENT':
      case 'SITE_POICLIENT':
      case 'DRIVER':
        this.isByPoint = false;
        this.starEnd = false;
        this.reportOf = false;
        this.isExporting = true;
        this.isByYear = false;
        this.isPole = false;
        this.loading = true;
        break;
      case 'PATH_APPRO_FUEL_POI':
        this.isByPoint = false;
        this.starEnd = true;
        this.reportOf = false;
        this.isExporting = true;
        this.isByEndPOI = false;
        this.isByPOIClient = false;
        this.isByYear = false;
        this.isPole = true;
        this.loading = true;
        break;
      case 'MENSUEL_DU_BUDGET_TOTAL':
          this.isByPoint = false;
          this.starEnd = false;
          this.reportOf = true;
          this.isExporting = true;
          this.isByYear = true;
          this.loading = true;
          this.isPole = false;
          break;
      case 'MONTHLY_PARC_MANAGEMENT':
        this.isByPoint = false;
        this.starEnd = false;
        this.reportOf = true;
        this.isExporting = true;
        this.isByYear = true;
        this.loading = true;
        this.isPole = false;
        break;
      case 'ETAT_ACTUEL_STOP':
      case 'ETAT_ACTUEL':
        this.starEnd = false;
        this.reportOf = true;
        this.isExporting = true;
        this.loading = true;
        this.retard = false;
        this.isByYear = false;
        this.isPole = false;
        break;
      /**imports */
      case 'IMPORT_FUEL':
      case 'IMPORT_THEORETICAL_COST':
      case 'IMPORT_OIL_CHANGE':
      case 'IMPORT_NUMERO_OT':
      case 'IMPORT_INSURANCE':
      case 'IMPORT_VISIT':
      case 'IMPORT_ROAD_TAXES':
      case 'IMPORT_OPERATIONAL_CERTIFICATION':
      case 'IMPORT_PERMIT_CIRCULATION':
      case 'IMPORT_METOLOGICA_NOTBOOK':
      case 'IMPORT_EXTINCTEURS':
      case 'IMPORT_TONNAGE_TAX':
      case 'IMPORT_LEASING':
      case 'IMPORT_CA_REALIZATION':
      case 'IMPORT_C_LOCATION':
      case 'IMPORT_DIVERS':
      case 'IMPORT_TONNAGE':
      case 'IMPORT_REPARATION':
      case 'IMPORT_TIRE':
      case 'IMPORT_BRAKE':
      case 'IMPORT_BATTERY':
        this.isPole = false;
        this.loading = false;
        this.isExporting = false;
        this.withProvider = true;
        this.selectedFile = null;
        this.choosen = true;
        if (this.repport.type === 'IMPORT_OIL_CHANGE') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'VidangeTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_NUMERO_OT') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'NumeroOTTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_INSURANCE') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'AssuranceTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_VISIT') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'Visite_TechniqueTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_ROAD_TAXES') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'VignetteTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_OPERATIONAL_CERTIFICATION') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'Certifications_ExploitationTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_METOLOGICA_NOTBOOK') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'Carnets_MetrologiqueTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_PERMIT_CIRCULATION') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'Permis_CirculationTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_EXTINCTEURS') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'ExtincteursTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_DIVERS') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'DiversTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_TONNAGE') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'Tonnage';
          this.isdismissed = true;
          this.loading = true;
        } else if (this.repport.type === 'IMPORT_TONNAGE_TAX') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'Taxe_TonnageTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_LEASING') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'LocationTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_CA_REALIZATION') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'CA_RealiseTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_C_LOCATION') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'C_LOCATIONTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_FUEL') {
          this.withProvider = true;
          this.selectedTemplateUpload = '';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_REPARATION') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'ReparationTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_BRAKE') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'FreinTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_BATTERY') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'BatterieTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_TIRE') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'PneuTemplate';
          this.isdismissed = true;
        } else if (this.repport.type === 'IMPORT_THEORETICAL_COST') {
          this.withProvider = false;
          this.selectedTemplateUpload = 'Cout_TheoriqueTemplate';
          this.isdismissed = true;
        }
    }
  }
  /**on switching between report of all vehicule,by group and by vehicule */
  onSelectingReportOf() {
    /**give ability to downoald */
    this.loading = true;
    switch (this.Selectedoption) {
      case 'ALL_VEHICULES':
        this.repport.rapportPayloadDto.allVehicules = true;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_GROUPS':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = true;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_VEHICULE':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = true;
        break;
      default:
        this.repport.rapportPayloadDto.allVehicules = true;
    }
  }
  /**on switchong  */
  onSelectingProvider(provider: string) {
    this.importData.provideName = provider;
    switch (provider) {
      case 'OTHER':
        this.isdismissed = true;
        this.loading = false;
        break;
      case 'EASY_CONNECT':
      case 'TOTAL':
      case 'TOTAL_NOMATIS':
      case 'TOTAL_ALEQ':
      case 'TOTAL_AMA':
      case 'TOTAL_SAM':
      case 'PETROMIN':
      case 'CUSTOM':
        this.isdismissed = false;
        this.loading = true;
        break;
    }
  }
  /**downoald file */
  onDownoaldingReport() {
    if (this.repport.rapportPayloadDto.byVehicules) {
      this.repport.rapportPayloadDto.idVehicules = this.vehiculesSelected;
    }
    if (this.repport.rapportPayloadDto.byGroups) {
      this.repport.rapportPayloadDto.idGroups = this.selectedGroupsItems;
    }

    this.loader = true;
    let cmp = 0;
    let nbDays = 30;

    if (
      this.repport.type === 'KILOMETRAGE_DAILY' ||
      this.repport.type === 'DIVIDED_ACTIVITY_SPEED' ||
      this.repport.type === 'PATH_WITH_START_OR_END_POI' ||
      this.repport.type === 'STOP_IN_POI_NOTIFICATION' ||
      this.repport.type === 'STOP_BY_POI_CLIENT' ||
      this.repport.type === 'DAILY_PATH_APPRO_FUEL_POI' ||
      this.repport.type === 'PATH_APPRO_FUEL_POI'

    ) {
      cmp =
        this.repport.dateInterval.endDate.getTime() -
        this.repport.dateInterval.startDate.getTime();
      cmp = cmp / 1000;
      if (
        this.repport.type === 'PATH_WITH_START_OR_END_POI' ||
        this.repport.type === 'STOP_IN_POI_NOTIFICATION' ||
        this.repport.type === 'STOP_BY_POI_CLIENT' ||
        this.repport.type === 'DAILY_PATH_APPRO_FUEL_POI' ||
        this.repport.type === 'PATH_APPRO_FUEL_POI'

      )
        nbDays = 7;
      if (cmp > nbDays * 86400) {
        this.toastr.warning(
          'La durée pour ce rapport ne peut pas dépasser ' +
            nbDays +
            ' jours !',
          'Alerte'
        );
        this.loader = false;
        return;
      }
    }

    if (this.repport.type === 'STOP_BY_POI_CLIENT') {
      this.repport.rapportPayloadDto.poiAdresses =
        this.poiClientSelected.concat(
          this.driverSelected,
          this.prospectSelected,
          this.clientGrosSelected,
          this.concurrentSelected,
          this.intermediaireSelected,
          this.depotSelected,
          this.concessionnaireSelected
        );
    }
    if (this.repport.type === 'MENSUEL_DU_BUDGET_TOTAL') {
      this.repport.dateInterval.startDate = new Date(
        this.selectedYear,
        0,
        1,
        0,
        0,
        0,
        0
      );
      this.repport.dateInterval.endDate = new Date(
        this.selectedYear,
        12,
        0,
        23,
        59,
        59,
        59
      );
    }

    if (this.repport.type === 'MONTHLY_PARC_MANAGEMENT') {
      this.repport.dateInterval.startDate = new Date(
        this.selectedYear,
        0,
        1,
        0,
        0,
        0,
        0
      );
      this.repport.dateInterval.endDate = new Date(
        this.selectedYear,
        12,
        0,
        23,
        59,
        59,
        59
      );
    }

    let vehiculesCanalIds = [];
    let vehiculesTransportIds: any[] = [];
    if (this.repport.type == 'PATH_APPRO_FUEL_POI') {
      if (this.selectedCanal.idCanal == -1)
        vehiculesCanalIds = this.getVehiculeIdsFromCanal(
          this.canalPfbService.canals
        );
      else {
        const filteredCanals: CanalInfoPfbDto[] =
          this.canalPfbService.canals.filter(
            (c) => c.idCanal === this.selectedCanal.idCanal
          );
        vehiculesCanalIds = this.getVehiculeIdsFromCanal(filteredCanals);
      }

      if (this.selectedTransport.idTransportType == -1)
        vehiculesTransportIds = this.getVehiculeIdsFromTransport(
          this.transportTypeService.transportTypes
        );
      else {
        const filteredTransport: TransportTypeInfoDto[] =
          this.transportTypeService.transportTypes.filter(
            (t) => t.idTransportType === this.selectedTransport.idTransportType
          );
        vehiculesTransportIds =
          this.getVehiculeIdsFromTransport(filteredTransport);
      }

      let intersection: number[] = vehiculesCanalIds.filter((id) =>
        vehiculesTransportIds.includes(id)
      );

      this.repport.rapportPayloadDto.allVehicules = false;
      this.repport.rapportPayloadDto.byGroups = false;
      this.repport.rapportPayloadDto.byVehicules = true;
      this.repport.rapportPayloadDto.idVehicules = intersection;
    }

    this.service.getRepport(this.repport).subscribe(
      (blob) => {
        if (blob.size > 0) {
          importedSaveAs(
            blob,
            'Rapport ' + this.service.getReportName(this.repport.type) + '.xlsx'
          );
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        }
        this.loader = false;
      },
      (error) => {
        this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        this.loader = false;
      }
    );
  }

  getVehiculeIdsFromCanal(data: CanalInfoPfbDto[]): any[] {
    return data.reduce(
      (acc, level1) =>
        acc.concat(
          level1.groups.reduce(
            (accGroup, group) =>
              accGroup.concat(
                group.vehicules.map((vehicule) => vehicule.idVehicule)
              ),
            []
          )
        ),
      []
    );
  }

  getVehiculeIdsFromTransport(data: TransportTypeInfoDto[]): any[] {
    return data.reduce(
      (acc, level1) =>
        acc.concat(level1.vehicules.map((vehicule) => vehicule.idVehicule)),
      []
    );
  }

  /**end data export*/
  onFileChanged(files: FileList) {
    this.selectedFile = files.item(0);
  }
  onValidUploadedFile() {
    this.loader = true;
    this.service
      .validaDatabeforImport(
        this.selectedFile,
        this.repport.type,
        this.importData.provideName
      )
      .subscribe(
        (blob) => {
          if (blob.size === 0) {
            this.modalBody = 'message de confirmation';
            this.toastr.success('Fichier importé avec succés !');
            this.loader = false;
          }
          if (blob.size !== 0) {
            this.loader = false;
            /**if the returned contains uncheck matricules show warning message */
            this.showWarning = true;
            if (this.selectedTemplateUpload == 'Tonnage')
              this.toastr.warning(
                'Certains conducteur ne sont pas incarnés sur la plateforme.'
              );
            else
              this.toastr.warning(
                'Certains immatricules ne sont pas incarnés sur la plateforme.'
              );
            importedSaveAs(blob, this.selectedFile.name);
            if (this.selectedTemplateUpload == 'Tonnage')
              this.modalBody =
                'Certains conducteur ne sont pas incarnés sur la plateforme !  Voulez-vous continuer ?';
            else
              this.modalBody =
                'Certains immatricules ne sont pas incarnés sur la plateforme !  Voulez-vous continuer ?';
          }
        },
        (error) => {
          this.loader = false;
          if (this.selectedTemplateUpload == 'Tonnage')
            this.toastr.error(
              'ERROR!<br>Vérifier si le fichier est volumineux',
              '',
              {
                enableHtml: true,
              }
            );
          else this.toastr.error('ERROR !');
        }
      );
  }
  onUpload() {
    /**show loader */
    this.loader = true;
    /**hide warning message */
    this.showWarning = false;
    this.service
      .importData(
        this.selectedFile,
        this.repport.type,
        this.importData.provideName
      )
      .subscribe(
        (res) => {
          if (res === true) {
            /**hide loader */
            this.loader = false;
            this.toastr.success('Enregistré !');
          }
        },
        (error) => {
          this.loader = false;
          this.toastr.success('ERROR !');
        }
      );
  }
  concel() {
    /**delete selected file */
    this.showWarning = false;
    this.selectedFile = null;
    this.toastr.warning('Operation annulée');
  }
  dismiss() {
    this.loading = true;
    this.isdismissed = false;
  }
  init() {
    this.multipleSelect = true;
    this.reportOf = false;
    this.isByPOI = false;
    this.repport.rapportPayloadDto.byVehicules = false;
    this.repport.rapportPayloadDto.byGroups = false;
    this.Selectedoption = 'ALL_VEHICULES';
    this.repport.rapportPayloadDto.allVehicules = true;
  }

  onAdd() {
    this.service.add(this.currentReportAuto).subscribe(
      (reportAuto) => {
        this.toastr.success('Rapport automatique bien ajoutée');
        this.ngOnInit();
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }

  changeModeAuto() {
    this.modeAuto = !this.modeAuto;
  }
  choseePeriode(periodeType: any) {
    this.currentReportAuto.period = periodeType;
  }

  /**downoald file */
  saveAutoReport() {
    this.loader = true;
    let cmp = 0;

    if (
      this.repport.type === 'KILOMETRAGE_DAILY' ||
      this.repport.type === 'DIVIDED_ACTIVITY_SPEED'
    ) {
      cmp =
        this.repport.dateInterval.endDate.getTime() -
        this.repport.dateInterval.startDate.getTime();
      cmp = cmp / 1000;
      if (cmp > 30 * 86400) {
        this.toastr.warning(
          'La durée pour ce rapport ne peut pas dépasser 30 jours !',
          'Alerte'
        );
        this.loader = false;
        return;
      }
    }

    this.currentReportAuto.payload = JSON.stringify(this.repport);
    this.currentReportAuto.url =
      window.location.protocol +
      '//' +
      window.location.host +
      dns +
      'generateRepport?timezoneOffset=' +
      new Date().getTimezoneOffset();
    this.currentReportAuto.signinUrl =
      window.location.protocol + '//' + window.location.host + dns + 'signin';

    this.service.add(this.currentReportAuto).subscribe(
      (reportAuto) => {
        this.loading = false;
        this.toastr.success('Rapport Automatique bien ajouter !');
        this.router.navigate(['/client/dataExport']);
        this.currentReportAuto = new ReportAuto();
      },
      (error) => {
        this.loading = false;
        this.toastr.error('Veuillez contacter votre administrateur !');
      }
    );
  }

  loadAllReports() {
    this.loading = true;
    this.service.getAllReportSent().subscribe(
      (reportAutos) => {
        this.reportAutos = reportAutos;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      // this.loading = true;
      this.transportTypeService.findAllTransportTypes().subscribe(
        (transportTypes) => {
          this.transportTypeService.transportTypes = transportTypes;
          if (transportTypes.length > 0) {
            this.selectedTransport = transportTypes[transportTypes.length - 1];
            //this.loadTrackingInfo();
          }
          // this.loading = false;
        },
        (err) => {
          // this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Type de transport');
        }
      );
    } else {
      this.selectedTransport =
        this.transportTypeService.transportTypes[
          this.transportTypeService.transportTypes.length - 1
        ];
      // this.loading = false;
    }
  }

  loadCanalPfb() {
    if (this.canalPfbService.canals.length <= 0) {
      // this.loading = true;
      this.canalPfbService.findAllCanalPfb().subscribe(
        (canals) => {
          this.canalPfbService.canals = canals;
          if (canals.length > 0) {
            const canalFound = canals.find((p) => p.name.startsWith('201'));
            if (canalFound) {
              this.selectedCanal = canalFound;
            }
          }
          // this.loading = false;
        },
        (err) => {
          // this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Canal');
        }
      );
    } else {
      const canalFound = this.canalPfbService.canals.find((p) =>
        p.name.startsWith('201')
      );
      if (canalFound) {
        this.selectedCanal = canalFound;
      }
      // this.loading = false;
    }
  }

  setTimeTo2359() {
    // Check if endDate is set and not just an empty string
    if (this.repport.dateInterval.endDate) {
      // Set the time to 23:59
      this.repport.dateInterval.endDate.setHours(23);
      this.repport.dateInterval.endDate.setMinutes(59);
    }
  }
}
