<div class="default-container">
    <div class="default-pic">
        <div>
            <i class="ri-slideshow-view icon"></i>
        </div>
        <div>
            <p class="paragraph-1">Cliquer sur <i class="fa fa-file-excel-o flaticon-view icon-eyse "></i>
                Pour afficher</p>
            <p class="paragraph-1" style="text-align: justify;margin-left: 117px;">votre Rapport</p>
        </div>
    </div>
</div>