<div class="row" style="height: 100%;
background-color: #f8f9fa;
display: flex;
gap: 15px;">

    <div class="col-lg-3 col-xxl-3 table-pdf">
        <app-pdf-table></app-pdf-table>
    </div>
    <div class="col-lg-9 col-xxl-9 content-section">
        <div class="display-setting-container">
            <app-pdf-settings></app-pdf-settings>
        </div>
        <div class="display-section-container">
            <app-pdf-display-section></app-pdf-display-section>
        </div>
    </div>
</div>