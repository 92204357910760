<div class="row" style="font-family: 'Inconsolata', monospace">
  <section class="">
    <div class="row" style="padding-top: 20px; margin-top: -15px ;">
      <div class="col-md-2" style="margin-top: -7px">
        <!-- <select class="form-control input-sm select3" (change)="onChange($event.target.value)"
          style="margin-bottom: 20px;">
          <option [value]="year.id" *ngFor="let year of Years">
            {{year.name}}
          </option>
        </select> -->
        <div class="form-group" style="padding-left: 39px;">
          <select class="form-control input-sm select3" (change)="onChange($event.target.value)"
            style="margin-bottom: 20px; width: 209px; margin-left: -11px">
            <option [value]="year.id" *ngFor="let year of Years" [selected]="year.id == 2">
              {{ year.name }}
            </option>
          </select>
          <label>Période</label>
        </div>
        <div class="row" style="margin-top: 5px; padding-left: 42px">
          <div class="col-md-6" style="padding: 0px; padding-left: 10px">
            <div class="form-group">
              <select class="form-control input-sm select3" [(ngModel)]="selectedCanal" (change)="onCanalChange()"
                style="margin-bottom: 20px; margin-left: -11px">
                <option class="ng-tns-c20-1 ng-star-inserted" *ngFor="let canal of canals" [value]="canal">
                  {{ canal }}
                </option>
              </select>
              <label>Canal</label>
            </div>
          </div>
          <div class="col-md-6" style="padding: 0px">
            <div class="form-group" style="padding-left: 10px">
              <select class="form-control input-sm select3" [(ngModel)]="selectedCanalGroup"
                (change)="onCanalGroupChange()" style="margin-bottom: 20px; margin-left: -11px">
                <option class="ng-tns-c20-1 ng-star-inserted" *ngFor="let Canalgroup of Canalgroups"
                  [value]="Canalgroup">
                  {{ Canalgroup }}
                </option>
              </select>
              <label>Groupe</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <div class="cardss" style="margin-top: -25px">
          <div class="stati" style="color: #7f8c8d">
            <ngx-loading [show]="showdepense"></ngx-loading>
            <img class="stati-icon" src="assets/icons/tmm/insurance.png" alt="" style="
                filter: invert(79%) sepia(9%) saturate(145%) hue-rotate(139deg)
                  brightness(92%) contrast(91%);
              " />

            <div>
              <b style="font-size: 20px">{{ insurance | number : "1.0-2"}} </b>
              <span style="font-size: larger"><strong style="color: #7f8c8d">{{
                  (insurance / totaldepense) * 100 | number : "1.0-2"
                  }}
                  %</strong></span>
            </div>
            <b class="titlecard" style="margin-left: -28px">Assurance</b>
          </div>

          <!-- Leasing -->
          <div class="stati" style="color: #7f8c8d">
            <img class="stati-icon" src="assets/icons/tmm/leasing.svg" alt="" style="
                filter: invert(84%) sepia(23%) saturate(6860%) hue-rotate(2deg)
                  brightness(107%) contrast(88%);
              " />
            <div>
              <b style="font-size: 20px">{{ leasing | number : "1.0-2"}}</b>
              <span style="font-size: larger"><strong style="color: #7f8c8d">{{
                  (leasing / totaldepense) * 100 | number : "1.0-2"
                  }}
                  %</strong></span>
            </div>
            <b class="titlecard" style="margin-left: -41px">Leasing</b>
          </div>

          <!-- Carburant -->
          <div class="stati" style="color: #7f8c8d">
            <img class="stati-icon" src="assets/icons/tmm/gasoline.svg" alt="" style="
                filter: invert(57%) sepia(16%) saturate(4319%) hue-rotate(90deg)
                  brightness(88%) contrast(70%);
              " />
            <div>
              <b style="font-size: 20px">{{ carburant | number : "1.0-2"}}</b>
              <span style="font-size: larger"><strong style="color: #7f8c8d">{{
                  (carburant / totaldepense) * 100 | number : "1.0-2"
                  }}
                  %</strong></span>
            </div>
            <b class="titlecard">Carburant</b>
          </div>

          <!-- Maintenance -->

          <div class="stati" style="color: #7f8c8d" (click)="showGraphMaintenace()">
            <img class="stati-icon" src="assets/icons/tmm/tools.svg" alt="" style="
                filter: invert(10%) sepia(93%) saturate(4956%)
                  hue-rotate(358deg) brightness(105%) contrast(94%);
                margin-left: 16px;
              " />
            <div>
              <b style="font-size: 20px">{{ maintenance | number : "1.0-2"}}</b>
              <span style="font-size: larger"><strong style="color: #7f8c8d">{{
                  (maintenance / totaldepense) * 100 | number : "1.0-2" }}
                  %</strong></span>
            </div>
            <b class="titlecard" style="margin-left: -51px">Maintenance</b>
          </div>

          <!-- C.P -->

          <!-- <div class="stati" style="color: #7F8C8D;">
            <img class="stati-icon" src="assets/images/icons/money.svg" alt=""
              style="filter: invert(66%) sepia(49%) saturate(608%) hue-rotate(93deg) brightness(90%) contrast(93%)">
            <div>
              <b style="font-size: 20px;">{{ca |number:'.2-3'}}</b>
              <span style="font-size: larger;"><strong style="color: #7F8C8D;">.</strong></span>
            </div>
            <b class="titlecard" style="margin-left: -26px;">C.A</b>
          </div> -->

          <!-- Dépenses -->

          <div class="stati" style="color: #7f8c8d" (click)="showGraph()">
            <img class="stati-icon" src="assets/icons/tmm/expense.svg" alt="" style="
                filter: invert(67%) sepia(17%) saturate(1352%)
                  hue-rotate(164deg) brightness(92%) contrast(91%);
              " />
            <div>
              <b style="font-size: 20px">{{ totaldepense | formatNumber }} </b>
              <span style="font-size: larger"><strong style="color: #7f8c8d">... </strong></span>
            </div>
            <b class="titlecard" style="margin-left: -42px">Dépenses</b>
          </div>
        </div>
      </div>

      <div class="col-md-3" *ngIf="displayCalendar" style="margin-left: 38px">
        <form #dashbordForm="ngForm" class="form-horizontal">
          <div class="form-group">

            <div class="col-lg-5 form-group" style="margin-right: -35">
              <input type="text" style="
                  height: 32px;
    font-size: 10px;
    padding: 0px 3px;
    width: 93px;
                " class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" placement="bottom" required
                (ngModelChange)="onChangeStartDate()" />
              <label style="bottom: 47px">Du</label>
            </div>

            <div class="col-lg-5 form-group" style="margin-left: -55px;">
              <input type="text" style="
                 height: 32px;
    font-size: 10px;
    padding: 0px 3px;
    width: 92px;
                " class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [minDate]="startDate" placement="bottom"
                required />
              <label style="bottom: 47px">Au</label>
            </div>

            <div class="col-lg-2 orm-group" style="position: relative;
    margin-left: -55px;">
              <button type="button" (click)="onValidate()" class="btn btn-block btn-success btn-flat"
                style="margin-top: 4px">
                <i class="fa fa-search pull-left" aria-hidden="true" style="margin-left: -7px"></i>
              </button>
            </div>

          </div>
        </form>
      </div>
      <!-- <div class="col-md-4">
        <select class="form-control input-sm select3" [(ngModel)]="IsByParent" (ngModelChange)="onChangeType()"
          style="margin-bottom: 20px;">
          <option [ngValue]="false">
            Par Groupe
          </option>
          <option [ngValue]="true">
            Par Parent
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <select class="form-control input-sm select3" [(ngModel)]="isByMultipleGroups"
          (ngModelChange)="onChangeByMultipleGroups()" style="margin-bottom: 20px;">
          <option [ngValue]=false>
            Tous les Groupes
          </option>
          <option [ngValue]=true>
            Groupe de Véhicules
          </option>
        </select>
      </div> -->
    </div>

    <!-- lin 2 -->
    <div *ngIf="isByMultipleGroups && !IsByParent" class="row">
      <div class="col-md-10">
        <form #groupForm="ngForm" class="form-horizontal">
          <div class="col-lg-10 col-md-10">
            <label class="col-lg-4 col-md-4 control-label">Groupes</label>
            <div class="col-lg-8 form-group">
              <ng-multiselect-dropdown-angular7 name="groupSelected" [data]="groups"
                placeholder="aucun groupe selectionné" [(ngModel)]="groupIds" [settings]="groupDropdownSettings"
                required>
              </ng-multiselect-dropdown-angular7>
            </div>
          </div>

          <div class="col-lg-2 orm-group" *ngIf="!displayCalendar">
            <button type="button" (click)="onValidate()" class="btn btn-block btn-success btn-flat">
              <i class="fa fa-refresh pull-left" aria-hidden="true"></i>
            </button>
          </div>
        </form>
      </div>
    </div>

  </section>
  <div class="col-md-2">
    <ngx-loading [show]="showdepense"></ngx-loading>
    <div class="col-md-12 vertical">
      <div class="stati" style="color: #7f8c8d">
        <img class="stati-icon" src="../../../../assets/icons/btp/oil-change.svg" alt="" style="
            filter: invert(72%) sepia(48%) saturate(4793%) hue-rotate(64deg)
              brightness(96%) contrast(88%);
            padding-top: 0px;
          " />
        <div>
          <b style="font-size: 20px">{{ vidange | formatNumber }}</b>
          <span style="font-size: larger"><strong style="color: #7f8c8d">{{
              (vidange / maintenance) * 100 | number : "1.0-2"}}
              %</strong></span>
        </div>
        <b class="titlecard" style="margin-left: -28px">Vidange</b>
      </div>
    </div>
    <div class="col-md-12 vertical">
      <div class="stati" style="color: #7f8c8d">
        <img class="stati-icon" src="../../../../assets/icons/tmm/tires.svg" alt="" style="
            filter: invert(72%) sepia(48%) saturate(4793%)
              hue-rotate(166deg) brightness(96%) contrast(88%);
            padding-top: 0px;
          " />
        <div>
          <b style="font-size: 20px">{{ tire | number : "1.0-2"}}</b>
          <span style="font-size: larger"><strong style="color: #7f8c8d">{{ (tire / maintenance) * 100 | number :
              "1.0-2" }} %</strong></span>
        </div>
        <b class="titlecard" style="margin-left: -28px">Pneus</b>
      </div>
    </div>
    <div class="col-md-12 vertical">
      <div class="stati" style="color: #7f8c8d">
        <img class="stati-icon" src="../../../../assets/icons/tmm/repair.svg" alt="" style="
            filter: invert(72%) sepia(48%) saturate(4793%)
              hue-rotate(228deg) brightness(96%) contrast(88%);
            padding-top: 0px;
          " />
        <div>
          <b style="font-size: 20px">{{ reparation | number : "1.0-2"}} </b>
          <span style="font-size: larger"><strong style="color: #7f8c8d">{{
              (reparation / maintenance) * 100 | number : "1.0-2"
              }}
              %</strong></span>
        </div>
        <b class="titlecard" style="margin-left: -28px">Reparation</b>
      </div>
    </div>
    <div class="col-md-12 vertical">
      <div class="stati" style="color: #7f8c8d">
        <img class="stati-icon" src="../../../../assets/icons/btp/battery.svg" alt="" style="
            filter: invert(72%) sepia(48%) saturate(4793%)
              hue-rotate(341deg) brightness(96%) contrast(88%);
            padding-top: 0px;
          " />
        <div>
          <b style="font-size: 20px">{{
            batteryMaintenance| number : "1.0-2"}}</b>
          <span style="font-size: larger"><strong style="color: #7f8c8d">{{
              (batteryMaintenance / maintenance) * 100 | number : "1.0-2"}}
              %</strong></span>
        </div>
        <b class="titlecard" style="margin-left: -28px">Batteries</b>
      </div>
    </div>
    <div class="col-md-12 vertical">
      <div class="stati" style="color: #7f8c8d">
        <img class="stati-icon" src="../../../../assets/icons/btp/brake.svg" alt="" style="
            filter: invert(67%) sepia(17%) saturate(1352%)
              hue-rotate(164deg) brightness(92%) contrast(91%);
            padding-top: 0px;
          " />
        <div>
          <b style="font-size: 20px">{{ brakeMaintenance | number:'1.0-2' }}
          </b>
          <span style="font-size: larger"><strong style="color: #7f8c8d">{{
              (brakeMaintenance / maintenance) * 100 | number : "1.0-2"}}
              %</strong></span>
        </div>
        <b class="titlecard" style="margin-left: -28px">Freins</b>
      </div>
    </div>
  </div>
  <div class="col-md-10">
    <div class="row">
      <div class="col-md-4">
        <div class="card box-style">
          <div class="card-body">
            <app-depense-chart [depensedata]="depensedata" [show]="show"></app-depense-chart>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card box-style">
          <div class="card-body">
            <app-vidange-chart [groups]="groups"></app-vidange-chart>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card box-style">
          <div class="card-body">
            <app-running-document-chart [depensedata]="depensedata" [show]="show"></app-running-document-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div>
          <div class="card box-style">
            <div class="card-body">
              <app-monthly-available-vehicules-chart [groups]="groups">
              </app-monthly-available-vehicules-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <div class="card box-style">
            <div class="card-body">
              <app-history-canal-group-vehicule></app-history-canal-group-vehicule>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <div class="card box-style">
            <div class="card-body">
              <app-monthly-maintenance-costs [groups]="groups"  [depensedataNew]="depensedataNew"></app-monthly-maintenance-costs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card box-style">
        <div class="card-body">
          <app-sinistre-chart [depensedata]="depensedata" [show]="show"></app-sinistre-chart>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card box-style">
        <div class="card-body">
          <app-charge #appcharge [depensedata]="depensedata" [groupeStatisticsResult]="groupeStatistics"
            [show]="show"></app-charge>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div class="modal fade" bsModal #dashboardModal="bs-modal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="dashboardModal.hide()">
          &times;
        </button>

        <h4 class="modal-title" style="
            font-family: 'Inconsolata', monospace;
            font-weight: bolder;
            color: #3c8dbc;
            margin-left: 200px;
          ">
          <!-- <i class="fa fa-pie-chart"></i> -->
          Dépenses du {{ depensedata?.datestart | date : "dd/MM/yyyy" }} au
          {{ depensedata?.dateEnd | date : "dd/MM/yyyy" }} :
          {{ dashbordInfo?.total | number : ".2-3" }} DH
        </h4>
      </div> 

      <div class="modal-body">
        <div style="text-align: center">
          <div id="dashbordpie"></div>
          <div>
            <!-- <app-vidange-chart [groups]="groups"></app-vidange-chart> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #dashboardModal1="bs-modal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content"> 
      <!-- ========================== Modal TITLE (START) ===============================-->
      <div class="modal-header">
        <button type="button" class="close" (click)="dashboardModal1.hide()">
          &times;
        </button>

        <h4 class="modal-title" style="
            font-family: 'Inconsolata', monospace;
            font-weight: bolder;
            color: #3c8dbc;
            margin-left: 200px;
          ">
          <!-- <i class="fa fa-pie-chart"></i> -->
          Dépenses du maintenance
          {{ depensedata?.datestart | date : "dd/MM/yyyy" }} au
          {{ depensedata?.dateEnd | date : "dd/MM/yyyy" }} :
          {{ maintenance | number : ".2-3" }} DH
          <!-- <span *ngIf="dashbordInfo" style="color: red; font-weight: bold;font-size: 14px;">TOTAL:
              {{dashbordInfo?.total | number:'.2-3'}} DH</span>
             comment ---<span *ngIf="dashbordInfo" style="color: red; font-weight: bold;font-size: 14px; margin-left:10px">CA:
              {{dashbordInfo?.turnover | number:'.2-3'}} DH</span>
            <span *ngIf="dashbordInfo" style="color: red; font-weight: bold;font-size: 14px; margin-left:10px">Résultat:
              {{
              (dashbordInfo?.turnover - dashbordInfo?.total) | number:'.2-3'}} DH</span> -->
        </h4>
      </div>
      <!-- ========================== Modal TITLE (END) ===============================-->

      <div class="modal-body">
        <div style="text-align: center">
          <div id="dashbordpie1"></div>
          <div>
            <!-- <app-vidange-chart [groups]="groups"></app-vidange-chart> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>