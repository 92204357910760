import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { dns } from '../../global.config';
import { DateInterval } from '../../shared/model';
import { GeocodingService } from '../../utils/leaflet/service/geocoding.service';
import { MapService } from '../../utils/leaflet/service/map.service';
import { createAuthorizationHeader } from '../../utils/security/headers';
import { DataManagementService } from '../data-management/data-management.service';
import { Alert, AlertConfiguration, Notification, FuelingNotificationDto } from './alert';
import Timer = NodeJS.Timer;
import * as L from 'leaflet';
import { HttpClient } from '@angular/common/http';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import {Icon, Marker} from "leaflet";
@Injectable()
export class AlertManagementService {
  days: string[] = [];
  km: { value: string | number }[] = [];
  isByGroup = false;
  isByAllVehicules = false;
  isByType = false;
  alertConfiguration: AlertConfiguration;

  toastrMessages: { message: string, title: string, type: 'error' | 'success' | 'info' }[] = [];
  toastrMessagesTimer: number;

  constructor(
    private _http: HttpClient,
    private mapService: MapService,
    private geocodingService: GeocodingService,
    private dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private signinService: SigninService
  ) {

    /** SHOW TOASTR NOTIFICATIONS BY INTERVAL TIME **/
    this.toastrMessagesTimer = window.setInterval(() => {
      if (this.toastrMessages.length > 0) {
        const toastrMessage = this.toastrMessages.shift();
        if (toastrMessage) {
          switch (toastrMessage.type) {
            case 'error':
              this.toastr.error(toastrMessage.message, toastrMessage.title);
              break;
            case 'success':
              this.toastr.success(toastrMessage.message, toastrMessage.title);
              break;
            case 'info':
              this.toastr.info(toastrMessage.message, toastrMessage.title);
          }
        }
      }
    }, 2000);
  }

  getAlertByNameAndDeviceId(name: string, deviceId: number): Observable<Alert> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Alert>(dns + 'alerts/' + deviceId + '/' + name, { headers: headers })
      ;
  }
  updateAlert(alert: Alert): Observable<Alert> {
    if (this.dataManagementService.selectedGroup) {
      alert.isByGroup = this.dataManagementService.selectedGroup.enbaleGlobalAlert ?? false;
      alert.groupId = this.dataManagementService.selectedGroup.idGroupe ?? 0;
    } else {
      alert.isByGroup = false;
      alert.groupId = 0;
    }

    alert.isByAllVehicules = this.isByAllVehicules;

    if (this.isByType) {
      alert.isByType = true;
      alert.subMark = this.dataManagementService.selectedSubmark ?? '';
    }

    const headers = createAuthorizationHeader();
    return this._http.put<Alert>(dns + 'alerts', alert, { headers });
  }

  setEmailToAllAlert(emailhash: string, deviceId: number, enableGlobalSettings: boolean, enableGlobalConfigToAllVehicules: boolean): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'alerts/emails/' + deviceId +
      '/?emailsHash=' + emailhash + '&enableGlobalSettings=' + enableGlobalSettings + '&enableGlobalConfigToAllVehicules=' + enableGlobalConfigToAllVehicules, { headers: headers });
  }

  getNotificationsByAlertNameOfDeviceId(
    name: string,
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<Notification[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<Notification[]>(
        dns + 'alerts/' + deviceId + '/' + name + '/notifications',
        dateInterval,
        { headers: headers }
      )
      ;
  }


  getFuelingNotificationsOfDeviceId(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<FuelingNotificationDto[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<FuelingNotificationDto[]>(
        dns + 'alerts/fueling/' + deviceId + '/notifications',
        dateInterval,
        { headers: headers }
      )
      ;
  }

  getNotifications(dateInterval: DateInterval): Observable<Notification[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<Notification[]>(dns + 'alerts/notifications', dateInterval, { headers: headers })
      ;
  }

  getNotificationsByUserId(dateInterval: DateInterval): Observable<Notification[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<Notification[]>(dns + 'alerts/notifications/' + this.signinService.isRootAdmin() + "/" + this.signinService.getUserId(), dateInterval, { headers: headers });
  }

  getFuelingNotification(dateInterval: DateInterval): Observable<FuelingNotificationDto[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<FuelingNotificationDto[]>(dns + 'alerts/fueling', dateInterval, { headers: headers });
  }

  displayNotification(notification: Notification) {
    this.mapService.removeMarkersFromMap();
    let marker = new Marker({ lat: notification.lat, lng: notification.lng });
    let popup = notification.message;
    marker.setIcon(
      new Icon({
        iconUrl: 'http://robertfmurray.com/wp-content/uploads/siren.png',
        iconAnchor: [6, 40],
        popupAnchor: [10, -25],
        iconSize: [30, 30]
      })
    );

    this.mapService.map.setView(
      { lat: notification.lat + (0.0002), lng: notification.lng - (0.002) },
      17
    );

    this.geocodingService
      .inverseGeoconding(notification.lat, notification.lng, 18)
      .subscribe(
        adress => {
          notification.address = adress.display_name;

          if (notification.address) {
            // Autoroute ou non
            if (notification.address.toLowerCase().indexOf("autoroute") > -1) {
              notification.highway = true;
            } else {
              notification.highway = false;
            }
          }

          popup = popup + '<hr><b>' + notification.address + '</b><br>';
        },
        err => { },
        () => {
          marker.bindPopup(popup).openPopup();
        }
      );

    marker.on('mouseover', () => {
      marker.openPopup();
    });

    marker.on('click', () => {
      this.mapService.map.setView(
        { lat: notification.lat + (0.0002), lng: notification.lng - (0.002) },
        17
      );
    });

    marker.on('add', () => {
      marker.openPopup();
    });
    this.mapService.addMarker(marker);
  }

  getTitleFromNotification(notification: Notification): string {
    let title = '';
    switch (notification.name) {
      case 'SPEED':
      case 'SPEED_CARTO': {
        title = 'Excès de vitesse (' + notification.alertValue + ' km/h)';
        break;
      }
      case 'INGNITION_V0': {
        title = 'Contact v=0';
        break;
      }
      case 'ZONE': {
        title = (notification.alertValue == "IN ZONE") ? 'Entrée dans la zone' : 'Sortie de la zone';
        break;
      }
      case 'STOP_POI':
      case 'POI': {
        title = 'Arrêt P.O.I';
        break;
      }
      case 'TOW': {
        title = 'Remorquage';
        break;
      }
      case 'NO_POI': {
        title = 'Arrêt hors P.O.I';
        break;
      }
      case 'CHOMAGE': {
        title = 'Arrêt hors CHOMAGE';
        break;
      }
      case 'DRIVING_QUALITY': {
        title = 'Qualite de conduite';
        break;
      }
      case 'DOC': {
        title = 'Documents circulation';
        break;
      }
      case 'VIDANGE': {
        title = 'Vidange';
        break;
      }
      case 'TIRE': {
        title = 'Pneus';
        break;
      }
      case 'BATTERY': {
        title = 'Batterie';
        break;
      }
      case 'BRAKE': {
        title = 'Frein';
        break;
      }
      case 'CHARGER': {
        title = 'Batterie';
        break;
      }
      case 'CONSUMPTION': {
        title = 'Consommation';
        break;
      }
      case 'ACTIVITY_DAILY': {
        title = 'Heure d\'activité (' + notification.alertValue + ' h)';
        break;
      }
      case 'ACTIVITY': {
        title = 'Activité (' + notification.alertValue + ' h)';
        break;
      }
      case 'REMISAGE': {
        title = 'Remisage';
        break;
      }
      case 'HARSH_ACCELERATE': {
        title = 'Accélération rapide (' +((+notification.alertValue * 9.80665).toFixed(2))+ ' m/s2)'
        break;
      }
      case 'HARSH_BRAKE': {
        title = 'Freinage rapide (' +((+notification.alertValue * 9.80665).toFixed(2))+ ' m/s2)'
        break;
      }
      case 'HARSH_CORNER_LEFT': {
        title = 'Déviation rapide (' +((+notification.alertValue * 9.80665).toFixed(2))+ ' m/s2)'
        break;
      }
      case 'APPRO_FUEL': {
        title = 'Approvisionnement carburant (' + notification.alertValue + ' L)';
        break;
      }
      default: {
        title = 'Alert';
        break;
      }
    }
    return title;
  }
  initDays() {
    for (let i = 1; i <= 90; i++) {
      this.days.push(i + '');
    }
  }
  initKm() {
    let j = 100;
    for (let i = 1; i <= 20; i++) {
      this.km.push({ value: j }); // Poussez un objet avec la propriété value
      j += 100;
    }
    this.km.push({ value: 'Autre..' }); // Poussez également un objet pour 'Autre..'
  }

  /** */
  initializeAlertConfiguration(type: any) {
    this.alertConfiguration = new AlertConfiguration();
    this.alertConfiguration.type = type;
    this.alertConfiguration.value1 = 0;
    this.alertConfiguration.value2 = '';
    this.alertConfiguration.isActive = false;
    return this.alertConfiguration;
  }

  initializeAlertConfigurationWithSameValue1(
    val1: number,
    alertConfig: AlertConfiguration[]
  ) {
    for (let i = 0; i < alertConfig.length; i++) {
      alertConfig[i].isActive = true;
      alertConfig[i].value1 = val1;
    }
  }
}
