import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger, } from '@angular/animations';
import { Subscription } from 'rxjs/Subscription';
import { Mileage, Notification } from '../../overview/notification';
import { DateInterval } from '../../../shared/model';
import { MileageService } from '../../historical/mileage/mileage.service';
import { DatePipe } from '@angular/common';
import { GroupDto } from '../../data-management/data-management.model';
import { Router } from '@angular/router';
import { MapService } from '../../../utils/leaflet/service/map.service';
import { DashBordExpensesInfoDTO, DashbordInfo, GroupDashbordDto } from '../../parc-management/model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DashbordService } from '../../parc-management';
import { DataManagementService } from '../../data-management';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
import { DepenseChartService } from '../../overview/dashbord-chart/depense-chart/depense-chart.service';
import { ChargeComponent } from './graph/charge/charge.component';
import { ParentData } from "../models/parent-data.model";
import moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';


const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

interface Year {
  id: number;
  name: string;
}

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        animate(
          900,
          keyframes([
            style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(15px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
          ])
        ),
      ]),
      transition('* => void', [
        animate(
          300,
          keyframes([
            style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.7 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class ExpensesComponent implements OnInit, OnDestroy {
  loaded$: Subscription;
  @ViewChild('appcharge', { static: false }) appcharge: ChargeComponent;

  dashboardData: { [key: string]: DashBordExpensesInfoDTO[] };
  selectedCanal: string;
  selectedCanalGroup: string;

  notifications: Notification[] = [];
  mileages: Mileage[] = [];
  dashbordInfo: DashbordInfo | null = null;
  groups: GroupDto[] = [];
  options: Object | null = null;
  groupeStatistics: any[] = [];
  cost: number;
  idGroup: number;
  idVehicule: number = -1;
  date: DateInterval = new DateInterval();
  endDate: Date | undefined = new Date();
  startDate: Date | undefined = new Date();
  selectedMonth: number;
  loader = false;
  error = false;
  canals: string[] = [];
  Canalgroups: string[] = [];

  /** recherch bar satart */
  displayCalendar: boolean = false;
  globalLoading: boolean;
  IsByParent = false;
  groupIds: GroupDto[] = [];
  Years: Year[] = [];
  isByMultipleGroups: boolean = false;

  groupDropdownSettings = {};
  depensedata: ParentData = new ParentData();
  depensedataNew : ParentData = new ParentData();
  carburant: number;
  carburantFormatted: string;
  vidange: number;
  vidangeFormatted: string;
  brakeMaintenance: number;
  brakeMaintenanceFormatted: string;
  batteryMaintenance: number;
  batteryMaintenanceFormatted: string;
  reparation: number;
  reparationFormatted: string;
  leasing: number;
  leasingFormetted: string;
  maintenance: number;
  maintenanceFormatted: string;
  insurance: number;
  FormattedInsurance: string;
  ca: number;
  totaldepense: number;
  totaldepenseFormetted: string;
  tire: number;
  tireFormatted: string;


  /**end  */

  months = [
    { id: 0, name: 'Janvier' },
    { id: 1, name: 'Février' },
    { id: 2, name: 'Mars' },
    { id: 3, name: 'Avril' },
    { id: 4, name: 'Mai' },
    { id: 5, name: 'Juin' },
    { id: 6, name: 'Juillet' },
    { id: 7, name: 'Août' },
    { id: 8, name: 'Septembre' },
    { id: 9, name: 'Octobre' },
    { id: 10, name: 'Novembre' },
    { id: 11, name: 'Décembre' },
  ];

  now: Date = new Date();

  @ViewChild('dashboardModal', { static: false }) dashboardModal: ModalDirective;
  @ViewChild('dashboardModal1', { static: false }) dashboardModal1: ModalDirective;

  show: boolean = true;
  costDetails: DashbordInfo;
  showdepense: boolean = false;
  selected: { startDate: moment.Moment, endDate: moment.Moment } | null = {
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day')
  };

  locale: LocaleConfig = {
    format: 'DD/MM/YYYY HH:mm',
    applyLabel: 'Appliquer',
    customRangeLabel: ' - ',
    daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    monthNames: [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juill', 'Août', 'Septem', 'Octob', 'Nove', 'Déce'
    ],
    firstDay: 1
  }

  dateRangeFired = true;
  maxDate = moment();
  dateLimit = { days: 5 };

  constructor(
    private mileageService: MileageService,
    public router: Router,
    private mapService: MapService,
    public datepipe: DatePipe,
    private dashbordService: DashbordService,
    private groupService: DataManagementService,
    private chartConfig: ChartConfigService,
    private depensechartservice: DepenseChartService,) {
    this.Years = this.getYears();
    this.loaded$ = this.mapService.mapLoaded.subscribe((value: boolean) => {
      this.mapService.map.removeLayer(this.mapService.baseMaps['Google Sat']);
      this.mapService.map.addLayer(this.mapService.baseMaps.OpenStreetMap);
    });
    this.getThisMonth();
  }


  onCanalChange(): void {
    if (this.selectedCanal === 'All') {
      this.loadCostParcAll()
    }
    this.updateCanalgroups();
  }

  onCanalGroupChange(): void {
      const nomGroup = this.selectedCanalGroup?.toLowerCase().trim(); // Ensure selectedCanalGroup is defined
      const group = this.groups.find(group => group.nom?.toLowerCase().trim() === nomGroup);
      if (group) {
        this.loadCostGroupChange(group.idGroupe);
        this.getLoadDate(group.idGroupe);
      }
      this.updateDashboardData();
  }

  getLoadDate(idGroupe : number){
    this.depensedataNew = new ParentData();
    this.depensedataNew.dateEnd = this.depensedata.dateEnd,
    this.depensedataNew.datestart = this.depensedata.datestart,
    this.depensedataNew.groups = idGroupe.toString();
    console.log(this.depensedataNew);
  }

  loadCostGroupChange(idGroupe : number) {
    this.showdepense = true;
    this.costDetails = new DashbordInfo();
    this.dashbordService
      .getDashbordInfo(idGroupe, -1, {
        startDate: this.depensedata.datestart,
        endDate: this.depensedata.dateEnd,
      })
      .subscribe(
        (value) => {
          this.updateCostDetails(value);
        },
        (err) => {
          this.showdepense = false;
        }
      );
  }


  ngAfterViewInit() {
    this.fetchDashboardData();
  }



  ngOnInit() {
    this.loadCostParcAll();

    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };

    this.show = true;
    let endTime = new Date();
    let month = endTime.getMonth() + 1;
    let startTime = new Date(
      endTime.getFullYear() + '-' + month + '-' + endTime.getDate()
    );

    let dateInterval = new DateInterval();

    dateInterval.startDate = startTime;
    dateInterval.endDate = endTime;
    this.mileageService
      .getMileageDetailsParc(dateInterval)
      .subscribe((response) => {
        this.mileageManipulation(response);
      });
    this.loadGroups();
    this.getDepenseInfo();
  }



  loadCostParcAll() {
    this.showdepense = true;
    var date = new Date();
    this.costDetails = new DashbordInfo();
    this.dashbordService
      .getDashbordInfo(-1, -1, {
        startDate: this.depensedata.datestart,
        endDate: this.depensedata.dateEnd,
      })
      .subscribe(
        (value) => {
          this.updateCostDetails(value);
        },
        (err) => {
          this.showdepense = false;
        }
      );
  }


  updateCostDetails(value: any) {
    this.costDetails = value;
    this.carburant = this.costDetails.fuel;
    this.vidange = this.costDetails.oilChange;
    this.reparation = this.costDetails.reparation;
    this.brakeMaintenance = this.costDetails.brakeMaintenance;
    this.batteryMaintenance = this.costDetails.batteryMaintenance;
    this.maintenance =
      this.costDetails.reparation +
      this.costDetails.oilChange +
      this.costDetails.tireMaintenance +
      this.costDetails.batteryMaintenance +
      this.costDetails.brakeMaintenance;
    this.ca = this.costDetails.turnover;
    this.totaldepense = this.costDetails.total;
    this.tire = this.costDetails.tireMaintenance;
    this.leasing = this.costDetails.leasing;
    this.insurance = this.costDetails.insurance;
    this.showdepense = false;
  }
  


  loadGroups() {
    this.groupService.getAllGroups().subscribe((groups) => {
      this.groups = groups;
    });
  }

  formatNumberToK(value: number): string {

    const suffixes = ['', 'k', 'M', 'B', 'T'];
    let suffixIndex = 0;

    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
      value /= 1000;
      suffixIndex++;
    }

    return value.toFixed(3).replace(/\.?0+$/, '') + ' ' + suffixes[suffixIndex];
  }



  mileageManipulation(mileages: Mileage[]) {
    for (let j = 0; j < mileages.length; j++) {
      this.prototypeToHHMMSS(mileages[j]);
      this.mileages.push(mileages[j]);
    }
  }

  prototypeToHHMMSS(mileage: Mileage) {
    var hours = Math.floor(mileage.drivingDuration / 3600);
    var minutes = Math.floor((mileage.drivingDuration - hours * 3600) / 60);
    var seconds = mileage.drivingDuration - hours * 3600 - minutes * 60;
    if (hours == 0)
      mileage.drivingDurationStr = minutes + ' min ' + seconds + ' sec';
    else {
      mileage.drivingDurationStr =
        hours + ' h ' + minutes + ' min ' + seconds + ' sec';
    }
  }


  fetchDashboardData(): void {
    if (this.appcharge) {
      this.appcharge.createChartDashboard([]);
    } else {
      console.error('appcharge is undefined');
    }

    this.date.startDate = this.startDate ? this.startDate.getTime() : new Date().getTime();
    this.date.endDate = this.endDate ? this.endDate.getTime() : new Date().getTime();

    this.dashbordService
      .getDashbordInfoExpenses(this.date)
      .subscribe((data) => {
        this.dashboardData = data; 
        this.canals = Object.keys(this.dashboardData);
        console.log(this.canals);
        this.canals.push('All');

        this.selectedCanal = this.canals.length > 0 ? this.canals[this.canals.length - 1] : 'Default Canal';

        this.updateCanalgroups();
      });
  }



  updateCanalgroups(): void {
    this.Canalgroups = [];
    if (this.selectedCanal) {
      const categoryData = this.dashboardData[this.selectedCanal];
      if (this.selectedCanal != 'All') {
        this.Canalgroups = Array.from(new Set(categoryData.map((item) => item.nom)));
        console.log(this.Canalgroups)
      }
      this.Canalgroups.push('All');
      this.selectedCanalGroup = this.Canalgroups.length > 0 ? this.Canalgroups[this.Canalgroups.length - 1] : 'Default Canal Group';
      this.updateDashboardData();
    } else {
      this.Canalgroups = [];
    }
  }


  updateDashboardData(): void {

    let dashboardData_canal: any[] = [];
    let dashboardData_canal_groupe: any[] = [];
    if (this.selectedCanal != "All") {

      dashboardData_canal = this.dashboardData[this.selectedCanal];

      if (this.selectedCanalGroup == "All")
        dashboardData_canal_groupe = dashboardData_canal;
      else
        dashboardData_canal_groupe = dashboardData_canal.filter(item => item.nom === this.selectedCanalGroup);
    }
    else {
      let canals_fil;
      canals_fil = this.canals.filter((canal) => canal !== 'All');
      canals_fil.forEach((canal) => {
        dashboardData_canal_groupe = dashboardData_canal_groupe.concat(this.dashboardData[canal]);
      });
    }

    dashboardData_canal = [];
    this.appcharge.createChartDashboard(dashboardData_canal_groupe);

  }


  showGraph() {
    this.configHighcharts(this.costDetails);
    this.dashboardModal.show();
  }

  showGraphMaintenace() {
    this.configHighchartsMaintenace(this.costDetails);
    this.dashboardModal1.show();
  }

  configHighcharts(dashbordInfo: DashbordInfo) {
    this.dashbordInfo = dashbordInfo;
    this.options = {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'percentage',
          data: [
            [
              'Assurances :' +
              this.chartConfig.numberWithSpaces(dashbordInfo.insurance) +
              ' DH',
              (dashbordInfo.insurance * 100) / dashbordInfo.total,
            ],
            [
              'Visites :' +
              this.chartConfig.numberWithSpaces(dashbordInfo.visit) +
              ' DH',
              (dashbordInfo.visit * 100) / dashbordInfo.total,
            ],
            [
              'Vignettes :' +
              this.chartConfig.numberWithSpaces(dashbordInfo.roadTaxes) +
              ' DH',
              (dashbordInfo.roadTaxes * 100) / dashbordInfo.total,
            ],
            [
              'C.exploitation :' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.operationalCertification
              ) +
              ' DH',
              (dashbordInfo.operationalCertification * 100) /
              dashbordInfo.total,
            ],
            [
              'Permis circulation :' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.permitCirculation
              ) +
              ' DH',
              (dashbordInfo.permitCirculation * 100) / dashbordInfo.total,
            ],
            [
              'Carnet métrologique :' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.metologicalNotbook
              ) +
              ' DH',
              (dashbordInfo.metologicalNotbook * 100) / dashbordInfo.total,
            ],
            [
              'Extincteurs :' +
              this.chartConfig.numberWithSpaces(dashbordInfo.extincteurs) +
              ' DH',
              (dashbordInfo.extincteurs * 100) / dashbordInfo.total,
            ],
            [
              'Taxe tonnage :' +
              this.chartConfig.numberWithSpaces(dashbordInfo.tonnageTax) +
              ' DH',
              (dashbordInfo.tonnageTax * 100) / dashbordInfo.total,
            ],
            [
              'Carburant : ' +
              this.chartConfig.numberWithSpaces(dashbordInfo.fuel) +
              ' DH',
              (dashbordInfo.fuel * 100) / dashbordInfo.total,
            ],
            {
              name:
                'Vidange : ' +
                this.chartConfig.numberWithSpaces(dashbordInfo.oilChange) +
                ' DH',
              y: (dashbordInfo.oilChange * 100) / dashbordInfo.total,
              sliced: true,
              selected: true,
            },
            [
              'Pneu : ' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.tireMaintenance
              ) +
              ' DH',
              (dashbordInfo.tireMaintenance * 100) / dashbordInfo.total,
            ],
            [
              'Batterie : ' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.batteryMaintenance
              ) +
              ' DH',
              (dashbordInfo.batteryMaintenance * 100) / dashbordInfo.total,
            ],
            [
              'Reparation : ' +
              this.chartConfig.numberWithSpaces(dashbordInfo.reparation) +
              ' DH',
              (dashbordInfo.reparation * 100) / dashbordInfo.total,
            ],
            [
              'Dégâts : ' +
              this.chartConfig.numberWithSpaces(dashbordInfo.losses) +
              ' DH',
              (dashbordInfo.losses * 100) / dashbordInfo.total,
            ],
            [
              'Frein : ' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.brakeMaintenance
              ) +
              ' DH',
              (dashbordInfo.brakeMaintenance * 100) / dashbordInfo.total,
            ],
            [
              'Location : ' +
              this.chartConfig.numberWithSpaces(dashbordInfo.leasing) +
              ' DH',
              (dashbordInfo.leasing * 100) / dashbordInfo.total,
            ],
            [
              'Parking : ' +
              this.chartConfig.numberWithSpaces(dashbordInfo.parking) +
              ' DH',
              (dashbordInfo.parking * 100) / dashbordInfo.total,
            ],
            [
              'Lavage : ' +
              this.chartConfig.numberWithSpaces(dashbordInfo.lavage) +
              ' DH',
              (dashbordInfo.lavage * 100) / dashbordInfo.total,
            ],
            [
              'Péage : ' +
              this.chartConfig.numberWithSpaces(dashbordInfo.tool) +
              ' DH',
              (dashbordInfo.tool * 100) / dashbordInfo.total,
            ],
            [
              'Charge conducteur :' +
              this.chartConfig.numberWithSpaces(dashbordInfo.driverCharge) +
              ' DH',
              (dashbordInfo.driverCharge * 100) / dashbordInfo.total,
            ],
          ],
        },
      ],
    };

    Highcharts.chart('dashbordpie', this.options);
  }

  configHighchartsMaintenace(dashbordInfo: DashbordInfo) {
    this.setHighCartsLang();
    this.dashbordInfo = dashbordInfo;
    this.options = {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'percentage',
          data: [
            [
              'Reparation :' +
              this.chartConfig.numberWithSpaces(
                this.formatNumberToK(dashbordInfo.reparation)
              ) +
              ' DH',
              (dashbordInfo.reparation * 100) / this.maintenance,
            ],
            [
              'Batteries :' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.batteryMaintenance
              ) +
              ' DH',
              (dashbordInfo.batteryMaintenance * 100) / this.maintenance,
            ],
            [
              'Freins :' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.brakeMaintenance
              ) +
              ' DH',
              (dashbordInfo.brakeMaintenance * 100) / this.maintenance,
            ],
            [
              'Vidanges :' +
              this.chartConfig.numberWithSpaces(dashbordInfo.oilChange) +
              ' DH',
              (dashbordInfo.oilChange * 100) / this.maintenance,
            ],
            [
              'Pneus :' +
              this.chartConfig.numberWithSpaces(
                dashbordInfo.tireMaintenance
              ) +
              ' DH',
              (dashbordInfo.tireMaintenance * 100) / this.maintenance,
            ],
            // [
            //   "Chiffre d'affaire :" + this.chartConfig.numberWithSpaces(dashbordInfo.turnover) + " DH",
            //   (dashbordInfo.turnover * 100) / (dashbordInfo.total)
            // ]
          ],
        },
      ],
    };

    Highcharts.chart('dashbordpie1', this.options);
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#9b20d9',
        '#9215ac',
        '#861ec9',
        '#7a17e6',
        '#7010f9',
        '#691af3',
        '#6225ed',
        '#5b30e7',
        '#533be1',
        '#4c46db',
        '#4551d5',
        '#3e5ccf',
        '#3667c9',
        '#2f72c3',
        '#277dbd',
        '#1f88b7',
        '#1693b1',
        '#0a9eaa',
        '#03c69b',
        '#00f194',
      ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: "Aucune donnée n'est disponible",
      },
    });
  }

  /** rechrch bar start ts */


  getDepenseInfo() {
    this.globalLoading = true;
    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    if (this.IsByParent) this.groupIds = [];
    var groups = this.groupIds.map((g) => g.idGroupe).toString();

    this.depensedata = new ParentData();
    this.depensedataNew = new ParentData();
    this.depensedataNew.dateEnd = this.endDate.getTime();
    this.depensedataNew.datestart = this.startDate.getTime();

    this.depensedata.dateEnd = this.endDate.getTime();
    this.depensedata.datestart = this.startDate.getTime();
    this.depensedata.IsByParents = this.IsByParent;
    this.depensedata.groups = groups;
    this.depensedata.activatechange = true;

    this.depensechartservice
      .getDepenseInfo(this.date, groups, this.IsByParent)
      .subscribe((res) => {
        this.groupeStatistics = res as GroupDashbordDto[];
        if (this.IsByParent) {
          this.depenseParParentChart();
        } else {
          this.depenseChart();
        }
        this.globalLoading = false;
      },
        (error) => {
          console.error('Erreur lors de la récupération des données de dépense :', error);
          this.globalLoading = false;
        })
  }





  getThisMonth() {
    this.selectedMonth = new Date().getMonth() + 1;
    this.endDate = new Date(
      new Date().getFullYear(),
      this.selectedMonth,
      0,
      23,
      59,
      59,
      59
    );
    this.startDate = new Date(
      new Date().getFullYear(),
      this.selectedMonth - 1,
      1,
      0,
      0,
      0,
      0
    );
    if (this.IsByParent) this.groupIds = [];
    var groups = this.groupIds.map((g) => g.idGroupe).toString();
    this.depensedata = new ParentData();
    this.depensedata.dateEnd = this.endDate.getTime();
    this.depensedata.datestart = this.startDate.getTime();
    this.depensedata.IsByParents = this.IsByParent;
    this.depensedata.groups = groups;
    this.depensedata.activatechange = true;
  }

  getLastMonth() {
    /* this.selectedMonth = new Date().getMonth() - 1;
    this.endDate = new Date(new Date().getFullYear(), this.selectedMonth, 0, 23, 59, 59, 59);
    this.startDate = new Date(new Date().getFullYear(), this.selectedMonth - 1, 1, 0, 0, 0, 0); */

    const currentDate = new Date();
    // Subtract 1 from the current month to get the last month
    currentDate.setMonth(currentDate.getMonth() - 1);
    this.selectedMonth = currentDate.getMonth();
    this.endDate = new Date(
      currentDate.getFullYear(),
      this.selectedMonth + 1,
      0,
      23,
      59,
      59,
      59
    );
    this.startDate = new Date(
      currentDate.getFullYear(),
      this.selectedMonth + 1 - 1,
      1,
      0,
      0,
      0,
      0
    );
    if (this.IsByParent) this.groupIds = [];
    var groups = this.groupIds.map((g) => g.idGroupe).toString();
    this.depensedata = new ParentData();
    this.depensedata.dateEnd = this.endDate.getTime();
    this.depensedata.datestart = this.startDate.getTime();
    this.depensedata.IsByParents = this.IsByParent;
    this.depensedata.groups = groups;
    this.depensedata.activatechange = true;
  }

  depenseParParentChart() {
    // chart depense parent
  }

  depenseChart() {
    // depenseChart
  }

  getYearSelected(year: number): void {
    this.startDate = new Date(new Date().getFullYear() - year, 0, 1);
    this.endDate = new Date(this.startDate.getFullYear(), 11, 31);
    if (this.IsByParent) this.groupIds = [];
    var groups = this.groupIds.map((g) => g.idGroupe).toString();
    this.depensedata = new ParentData();
    this.depensedata.dateEnd = this.endDate.getTime();
    this.depensedata.datestart = this.startDate.getTime();
    this.depensedata.IsByParents = this.IsByParent;
    this.depensedata.groups = groups;
    this.depensedata.activatechange = true;
  }

  onChange(year: number): void {
    this.vidange = 0;
    this.brakeMaintenance = 0;
    this.batteryMaintenance = 0;
    this.reparation = 0;
    this.carburant = 0.0;
    this.carburantFormatted = '0.0';
    this.vidange = 0.0;
    this.vidangeFormatted = '0.0';
    this.reparation = 0.0;
    this.reparationFormatted = '0.0';
    this.brakeMaintenance = 0.0;
    this.brakeMaintenanceFormatted = '0.0';
    this.batteryMaintenance = 0.0;
    this.batteryMaintenanceFormatted = '0.0';
    this.maintenance =
      this.costDetails.reparation +
      this.costDetails.oilChange +
      this.costDetails.tireMaintenance +
      this.costDetails.batteryMaintenance +
      this.costDetails.brakeMaintenance;
    this.maintenanceFormatted = '0.0';
    this.ca = 0.0;
    this.totaldepense = 0.0;
    this.totaldepenseFormetted = '0.0';
    this.tire = 0.0;
    this.tireFormatted = '0.0';
    this.leasing = 0.0;
    this.leasingFormetted = '0.0';
    this.insurance = 0.0;
    this.FormattedInsurance = '0.0';
    this.tire = 0;

    if (year == 2) {
      this.getThisMonth();
    } else if (year == 3) {
      this.getThisCemaine();
    } else if (year == 4) {
      this.getLastMonth();
    } else if (year == 0 || year == 1) {
      this.getYearSelected(year);
    }


    this.getDepenseInfo();
    this.loadCostParcAll();
    this.fetchDashboardData();


    if (year != 5) {
      this.displayCalendar = false;
    } else {
      this.displayCalendar = true;
    }
  }

  getYears() {
    return [
      // { id: 3, name: 'cette semaine' },
      { id: 2, name: 'ce mois-ci' },
      { id: 4, name: 'Mois dernier' },
      { id: 0, name: 'Année en cours' },
      { id: 1, name: 'Année antérieure ' },
      { id: 5, name: 'Personnalisée' },
    ];
  }

  getThisCemaine() {
    this.endDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    let dates = new Date().getDay();
    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - dates
    );

    if (this.IsByParent) this.groupIds = [];
    var groups = this.groupIds.map((g) => g.idGroupe).toString();
    this.depensedata = new ParentData();
    this.depensedata.dateEnd = this.endDate.getTime();
    this.depensedata.datestart = this.startDate.getTime();
    this.depensedata.IsByParents = this.IsByParent;
    this.depensedata.groups = groups;
    this.depensedata.activatechange = true;

  }

  onValidate() {
    this.fetchDashboardData();
    this.getDepenseInfo();
    this.loadCostParcAll();
  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    // Vérification que startDate et endDate ne sont pas null ou undefined
    if (this.endDate && this.startDate && this.endDate < this.startDate) {
      // Si endDate est plus petite que startDate, réinitialiser endDate à la même valeur que startDate
      this.endDate = new Date(this.startDate.getTime());
    }
  }

  ngOnDestroy(): void {
    if (this.loaded$) { this.loaded$.unsubscribe() }
  }
}
