<div class="table-container">
    <table *ngIf="excelData">
        <!-- Header Section -->
        <thead>
            <tr *ngIf="excelData[3].length">
                <th colspan="2">
                    <span class="fw-semibold">{{ currentUser.username | uppercase }}</span>
                </th>
                <th [attr.colspan]="excelData[0].length - 3" style="text-align: center;">
                    {{ excelData[0][1] }}
                </th>
                <th colspan="2" style="text-align: right; width: 50px;">
                    {{ excelData[0][excelData[0].length - 1] }}
                </th>
            </tr>
            <tr class="header-row">
                <th [attr.colspan]="2">{{excelData[3][0]}}</th>
                <th [attr.colspan]="2">{{excelData[3][2]}}</th>
                <th [attr.colspan]="2">{{excelData[3][4]}}</th>
                <th [attr.colspan]="2" style="width: 50px;">{{excelData[3][6]}}</th>
            </tr>
        </thead>

        <!-- Data Section -->
        <tbody>
            <tr *ngFor="let row of excelData.slice(4); let i = index" [class.selected]="selectedRowIndex === i"
                (click)="selectRow(i)">

                <!-- Première et deuxième colonne fusionnées -->
                <td [attr.colspan]="2">
                    {{ row[0] }}
                </td>

                <!-- Troisième et quatrième colonnes fusionnées -->
                <td [attr.colspan]="2">
                    {{ row[2] }}
                </td>

                <!-- Cinquième et sixième colonnes fusionnées -->
                <td [attr.colspan]="2">
                    {{ row[4] }}
                </td>

                <!-- Septième et huitième colonnes fusionnées -->
                <td [attr.colspan]="2" style="max-width: 80px;">
                    {{ row[6] }}
                </td>
            </tr>
        </tbody>
    </table>
</div>