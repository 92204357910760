export const rapportsItem = [
  {
    name: 'ETAT_ACTUEL',
    label: 'Etat actuel des boîtiers',
    type: 'EXPORT',
    authority: 'ROLE_ETAT_ACTUEL',
    id: 0,
  },
  {
    name: 'ETAT_ACTUEL_STOP',
    label: 'Etat des boitiers en arrêt',
    type: 'EXPORT',
    authority: 'ROLE_ETAT_ACTUEL_STOP',
    id: 0,
  },
  {
    name: 'PATH_FUEL',
    label: 'Rapport de trajets et approvisionnements périodique',
    type: 'EXPORT',
    authority: 'ROLE_PATH_FUEL',
    id: 8,
  },
  {
    name: 'TRANSPORT_BASE',
    label: 'La Base Transports',
    type: 'EXPORT',
    authority: 'ROLE_TRANSPORT_BASE',
    id: 19,
  },
  {
    name: 'PATH_WITH_START_OR_END_POI',
    label: 'Rapport des trajets POI',
    type: 'EXPORT',
    authority: 'ROLE_PATH_WITH_START_OR_END_POI',
    id: 0,
  },
  {
    name: 'PATH_POI',
    label: 'Rapport des voyages Par POI',
    type: 'EXPORT',
    authority: 'ROLE_PATH_POI',
    id: 21,
  },
  {
    name: 'PATH_ZONE',
    label: 'Rapport des voyages Par Zone',
    type: 'EXPORT',
    authority: 'ROLE_PATH_ZONE',
    id: 21,
  },
  {
    name: 'PATH_FUEL_POI',
    label: 'Rapport voyage par vehicule',
    type: 'EXPORT',
    authority: 'ROLE_PATH_POI',
    id: 21,
  },
  {
    name: 'PATH_APPRO_FUEL_POI',
    label: 'Rapport des voyages et approvisionnements périodique',
    type: 'EXPORT',
    authority: 'ROLE_PATH_POI',
    id: 21,
  },
  {
    name: 'DAILY_PATH_APPRO_FUEL_POI',
    label: 'Rapport des voyages journalier et approvisionnements périodique',
    type: 'EXPORT',
    authority: 'ROLE_PATH_POI',
    id: 21,
  },
  {
    name: 'RAPPORT_POINTAGE_BY_ZONE',
    label: 'Générez un rapport de pointage par entrée et sortie du site.',
    type: 'EXPORT',
    authority: 'ROLE_PATH_POI',
    id: 21,
  },
  {
    name: 'PATH_POI_COUNT',
    label: 'Rapport du nombre des voyages Par POI',
    type: 'EXPORT',
    authority: 'ROLE_PATH_POI',
    id: 21,
  },
  {
    name: 'REP_FUEL_VARIATION_GN',
    label: "Rapport d'approvisionnement carburant",
    type: 'EXPORT',
    authority: 'ROLE_REP_FUEL_VARIATION_GN',
    id: 9,
  },
  {
    name: 'KILOMETRAGE',
    label: 'Kilométrage',
    type: 'EXPORT',
    authority: 'ROLE_KILOMETRAGE',
    id: 0,
  },
  {
    name: 'KILOMETRAGE_DAILY',
    label: 'Kilométrage journalier',
    type: 'EXPORT',
    authority: 'ROLE_KILOMETRAGE_DAILY',
    id: 0,
  },
  {
    name: 'SITE_CLIENT',
    label: "Liste des points d'interêts",
    type: 'EXPORT',
    authority: 'ROLE_SITE_CLIENT',
    id: 0,
  },
  {
    name: 'SITE_POICLIENT',
    label: 'Liste des clients',
    type: 'EXPORT',
    authority: 'ROLE_SITE_POICLIENT',
    id: 0,
  },
  {
    name: 'DRIVER',
    label: 'Liste des conducteurs',
    type: 'EXPORT',
    authority: 'ROLE_ETAT_ACTUEL',
    id: 0,
  },
  {
    name: 'ARRETS',
    label: 'Liste des arrêts',
    type: 'EXPORT',
    authority: 'ROLE_ARRETS',
    id: 0,
  },
  {
    name: 'DASHBOARD_PERFORMANCE',
    label: 'Rapport TDB',
    type: 'EXPORT',
    authority: 'DASHBOARD_PERFORMANCE',
    id: 0,
  },
  {
    name: 'ARRETS_GLOBAL',
    label: 'Liste globale des arrêts',
    type: 'EXPORT',
    authority: 'ROLE_ARRETS_GLOBAL',
    id: 0,
  },
  {
    name: 'STOP_BY_POI',
    label: 'Arrêt POI ',
    type: 'EXPORT',
    authority: 'ROLE_STOP_BY_POI',
    id: 0,
  },
  {
    name: 'STOP_NON_POI',
    label: 'Arrêt Non POI ',
    type: 'EXPORT',
    authority: 'ROLE_STOP_NON_POI',
    id: 0,
  },

  {
    name: 'DAILY_STOP_BY_POI',
    label: 'Arrêt POI Journalier ',
    type: 'EXPORT',
    authority: 'ROLE_DAILY_STOP_BY_POI',
    id: 0,
  },
  {
    name: 'DAILY_STOP_BY_NON_POI',
    label: 'Arrêt Non POI  Journalier',
    type: 'EXPORT',
    authority: 'ROLE_DAILY_STOP_BY_NON_POI',
    id: 0,
  },

  {
    name: 'STOP_BY_GROUP_AND_POI_GLOBAL',
    label: 'Arrêt POI globale',
    type: 'EXPORT',
    authority: 'ROLE_STOP_POI_GLOBAL',
    id: 0,
  },
  {
    name: 'STOP_BY_POI_CLIENT',
    label: 'Arrêt POI CLIENT',
    type: 'EXPORT',
    authority: 'ROLE_STOP_BY_POI_CLIENT',
    id: 0,
  },
  {
    name: 'ARCHIVEPOI',
    label: "Passage par points d'interêts",
    type: 'EXPORT',
    authority: 'ROLE_ARRETS',
    id: 0,
  },
  {
    name: 'DELAY',
    label: ' Rapport des retard et des arrêts POI',
    type: 'EXPORT',
    authority: 'ROLE_DELAY',
    id: 10,
  },
  {
    name: 'ACTIVITY_GLOBAL',
    label: 'Activité',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY_GLOBAL',
    id: 0,
  },
  {
    name: 'ACTIVITY',
    label: 'Activité journaliere avec pré-contact',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY',
    id: 11,
  },
  {
    name: 'ACTIVITY_DAILY_GLOBAL',
    label: 'Activité journaliere globale',
    type: 'EXPORT',
    authority: 'ACTIVITY_DAILY_GLOBAL',
    id: 16,
  },
  {
    name: 'ACTIVITY_SPEED_GLOBAL',
    label: 'Activité avec vitesse',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY_SPEED',
    id: 0,
  },
  {
    name: 'ACTIVITY_SPEED',
    label: 'Activité journaliere avec vitesse',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY_SPEED',
    id: 0,
  },
  {
    name: 'ACTIVITY_MEAN',
    label: ' Moyenne activité journaliere',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY_MEAN',
    id: 12,
  },
  {
    name: 'DIVIDED_ACTIVITY_SPEED',
    label: 'Horaire activité',
    type: 'EXPORT',
    authority: 'ROLE_DIVIDED_ACTIVITY_SPEED',
    id: 13,
  },
  {
    name: 'SPEEDING',
    label: 'Rapport des excés de vitesse',
    type: 'EXPORT',
    authority: 'ROLE_SPEEDING',
    id: 0,
  },
  {
    name: 'GLOBAL_SPEEDING',
    label: 'Rapport des excés de vitesse global',
    type: 'EXPORT',
    authority: 'ROLE_GLOBAL_SPEEDING',
    id: 28,
  },
  {
    name: 'STOP_IN_POI_NOTIFICATION',
    label: 'Notification Arrêt POI',
    type: 'EXPORT',
    authority: 'ROLE_STOP_IN_POI_NOTIFICATION',
    id: 0,
  },
  {
    name: 'ACTIVITY_DAILY_NOTIFICATIONS',
    label: 'Rapport dépassement des Heures de travail',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY_DAILY_NOTIFICATIONS',
    id: 22,
  },
  {
    name: 'ACTIVITY_NOTIFICATIONS',
    label: "Rapport dépassement des Heures d'activité",
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY_NOTIFICATIONS',
    id: 22,
  },
  {
    name: 'RUNNING_DOCUMENT',
    label: 'Liste documents de circulation',
    type: 'LIST',
    authority: 'ROLE_RUNNING_DOCUMENT',
    id: 0,
  },
  {
    name: 'EXPIRATION_DOCUMENT',
    label: 'Liste expiration documents',
    type: 'LIST',
    authority: 'ROLE_EXPIRATION_DOCUMENT',
    id: 0,
  },
  {
    name: 'FUELING',
    label: 'Liste des carburants',
    type: 'LIST',
    authority: 'ROLE_FUELING',
    id: 0,
  },
  {
    name: 'OIL_CHANGE',
    label: 'Liste des vidanges',
    type: 'LIST',
    authority: 'ROLE_OIL_CHANGE',
    id: 0,
  },
  {
    name: 'REPARATION_MODULE',
    label: 'Liste des reparations',
    type: 'LIST',
    authority: 'ROLE_REPARATION_MODULE',
    id: 0,
  },
  {
    name: 'OFFENSES_MODULE',
    label: 'Liste des dégâts',
    type: 'LIST',
    authority: 'ROLE_OFFENSES_MODULE',
    id: 0,
  },
  {
    name: 'IMPORT_FUEL',
    label: 'Importer template Carburant ',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_FUEL',
    id: 0,
  },
  {
    name: 'IMPORT_THEORETICAL_COST',
    label: 'Importer template Coût Théorique',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_FUEL',
    id: 0,
  },
  {
    name: 'IMPORT_OIL_CHANGE',
    label: 'Importer template Vidange ',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_OIL_CHANGE',
    id: 0,
  },
  {
    name: 'IMPORT_INSURANCE',
    label: 'Importer template Assurance',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_INSURANCE',
    id: 0,
  },
  {
    name: 'IMPORT_VISIT',
    label: 'Importer template Visite Techniques',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_VISIT',
    id: 0,
  },
  {
    name: 'IMPORT_ROAD_TAXES',
    label: 'Importer template Vignettes',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_ROAD_TAXES',
    id: 0,
  },
  {
    name: 'IMPORT_OPERATIONAL_CERTIFICATION',
    label: 'Importer template C.exploitations',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_OPERATIONAL_CERTIFICATION',
    id: 0,
  },
  {
    name: 'IMPORT_PERMIT_CIRCULATION',
    label: 'Importer template Permis Circulation',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_PERMIT_CIRCULATION',
    id: 0,
  },
  {
    name: 'IMPORT_METOLOGICA_NOTBOOK',
    label: 'Importer template Carnet métrologique',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_METOLOGICA_NOTBOOK',
    id: 0,
  },
  {
    name: 'IMPORT_EXTINCTEURS',
    label: 'Importer template Extincteurs',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_EXTINCTEURS',
    id: 0,
  },
  {
    name: 'IMPORT_TONNAGE_TAX',
    label: 'Importer template Taxe de tonnage',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_TONNAGE_TAX',
    id: 0,
  },
  {
    name: 'IMPORT_LEASING',
    label: 'Impoter template Location',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_LEASING',
    id: 0,
  },
  {
    name: 'IMPORT_DIVERS',
    label: 'Importer template Divers',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_DIVERS',
    id: 0,
  },
  {
    name: 'IMPORT_TONNAGE',
    label: 'Importer template tonnage',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_TONNAGE',
    id: 0,
  },
  {
    name: 'IMPORT_TIRE',
    label: 'Importer template Pneu',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_TIRE',
    id: 0,
  },
  {
    name: 'IMPORT_BATTERY',
    label: 'Importer template Batterie',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_BATTERY',
    id: 0,
  },
  {
    name: 'IMPORT_REPARATION',
    label: 'Importer template Réparation',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_REPARATION',
    id: 0,
  },
  {
    name: 'IMPORT_BRAKE',
    label: 'Importer template Frein',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_BRAKE',
    id: 0,
  },
  {
    name: 'IMPORT_CA_REALIZATION',
    label: 'Impoter template C.A Réalisé',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_CA_REALIZATION',
    id: 14,
  },
  {
    name: 'IMPORT_C_LOCATION',
    label: 'Impoter template C.Location',
    type: 'IMPORT',
    authority: 'ROLE_IMPORT_CA_REALIZATION',
    id: 14,
  },
  {
    name: 'IMPORT_NUMERO_OT',
    label: 'Impoter template Numéro OT',
    type: 'IMPORT',
    authority: 'ROLE_PATH_POI',
    id: 21,
  },
  {
    name: 'CUMULATIVE_COST_OF_PARK_MANAGEMENT',
    label: 'Coûts des documents de circulation',
    type: 'LIST',
    authority: 'ROLE_CUMULATIVE_COST_OF_PARK_MANAGEMENT',
    id: 0,
  },
  {
    name: 'MONTHLY_PARC_MANAGEMENT',
    label: 'Liste des dépenses mensuelles',
    type: 'LIST',
    authority: 'ROLE_RUNNING_DOCUMENT',
    id: 0,
  },
  {
    name: 'MENSUEL_DU_BUDGET_TOTAL',
    label: 'generate rapport dépenses mensuelles',
    type: 'LIST',
    authority: 'ROLE_RUNNING_DOCUMENT',
    id: 0,
  },
  {
    name: 'DRIVER_ASSIGNMENT_HISTORY',
    label: 'Liste des chauffeurs',
    type: 'LIST',
    authority: 'ROLE_DRIVER_ASSIGNMENT_HISTORY',
    id: 0,
  },
  {
    name: 'OIL_CHANGE_MONITORING',
    label: 'Rapport Suivi Vidanges',
    type: 'EXPORT',
    authority: 'ROLE_OIL_CHANGE_MONITORING',
    id: 0,
  },
  // { name: 'FUELING_MONITORING_CONSUMPTION', label: 'Liste Suivi Consommation Saisie', type: 'LIST',authority: 'ROLE_FUELING_MONITORING_CONSUMPTION',id:0},
  {
    name: 'FUELING_CONSUMPTION_ANALYSIS',
    label: "Liste de suivi et d'analyse consommation vehicule",
    type: 'LIST',
    authority: 'ROLE_FUELING',
    id: 0,
  },
  {
    name: 'FUEL_CONSUMPTION_DIFFERENCE',
    label: 'Liste Ecart Consommation Carburant',
    type: 'LIST',
    authority: 'ROLE_FUEL_CONSUMPTION_DIFFERENCE',
    id: 0,
  },
  {
    name: 'DRIVING_QUALITY',
    label: 'qualité de conduite',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY',
    id: 0,
  },
  {
    name: 'EVENT_DATA',
    label: 'Rapport des evenements',
    type: 'EXPORT',
    authority: 'ROLE_ACTIVITY',
    id: 0,
  },
];

export const reportOf = [
  { name: 'BY_VEHICULE', label: 'Quelques véhicules' },
  { name: 'BY_GROUPS', label: 'Groupe de Véhicules' },
  { name: 'ALL_VEHICULES', label: 'Tous les véhicules' },
];

export const providers = [
  { name: 'EASY_CONNECT', label: 'Easy connect' },
  { name: 'TOTAL', label: 'Total' },
  { name: 'PETROMIN', label: 'Petromin' },
  // { name: 'TOTAL_NOMATIS', label: 'TOTAL NOMATIS'},
  // { name: 'TOTAL_ALEQ', label: 'TOTAL ALEQ'},
  // { name: 'TOTAL_AMA', label: 'TOTAL AMA'},
  { name: 'CUSTOM', label: 'Votre modèle' },
  { name: 'OTHER', label: 'RimTelecom' },
];
