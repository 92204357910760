import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewImportExportComponent } from './new-import-export.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';

//import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatNativeDateModule,
  MatOptionModule,
} from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfTableComponent } from './_components/pdf-table/pdf-table.component';
import { PdfSettingsComponent } from './_components/pdf-settings/pdf-settings.component';
import { PdfDisplaySectionComponent } from './_components/pdf-display-section/pdf-display-section.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NewImportExportRoutingModule } from './new-import-export-routing.module';
import { DefaultContentComponent } from './_components/pdf-display-section/default-content/default-content.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PdfExcelCostumComponent } from './_components/pdf-display-section/pdf-excel-costum/pdf-excel-costum.component';
import { PdfExcelCostumEditComponent } from './_components/pdf-display-section/pdf-excel-costum-edit/pdf-excel-costum-edit.component';
import { MileageExcelComponent } from './_components/pdf-display-section/mileage-excel/mileage-excel.component';
import { EtatBoitierExcelComponent } from './_components/pdf-display-section/etat-boitier-excel/etat-boitier-excel.component';
import { VehiculeActivitiesExcelComponent } from './_components/pdf-display-section/vehicule-activities-excel/vehicule-activities-excel.component';
import { MultipleSheetsExcelComponent } from './_components/pdf-display-section/multiple-sheets-excel/multiple-sheets-excel.component';
import { HeaderOptionsComponent } from './_components/pdf-display-section/header-options/header-options.component';
import { GlobalStopsReportComponent } from './_components/pdf-settings/global-stop-report/global-stop-report.component';
import { EventReportComponent } from './_components/pdf-settings/event-report/event-report.component';
import { ListClientReportComponent } from './_components/pdf-settings/list_clients_report/list_clients_report.component';
import { ListDriverReportComponent } from './_components/pdf-settings/list_driver_report/list_driver_report.component';
import { ListPoiReportComponent } from './_components/pdf-settings/list_poi_report/list_poi_report.component';
import { ListOfStopsReportComponent } from './_components/pdf-settings/list-of-stops-report/list-of-stops-report.component';
import { MileageReportComponent } from './_components/pdf-settings/mileage-report/mileage-report.component';
import { DailyMileageReportComponent } from './_components/pdf-settings/daily-mileage-report/daily-mileage-report.component';
import { ActivityContactReportComponent } from './_components/pdf-settings/activity-contact-report/activity-contact-report.component';
import { ActivitySpeedReportComponent } from './_components/pdf-settings/activity-speed-report/activity-speed-report.component';
import { DailyActivitySpeedReportComponent } from './_components/pdf-settings/daily-activity-speed-report/daily-activity-speed-report.component';
import { SpeedingReportComponent } from './_components/pdf-settings/speeding-report/speeding-report.component';
import { DrivingQualityReportComponent } from './_components/pdf-settings/driving-quality-report/driving-quality-report.component';
import { PathPOIReportComponent } from './_components/pdf-settings/path-poi-report/path-poi-report.component';
import { StopNotificationReportComponent } from './_components/pdf-settings/stop-notif-report/stop-notif-report.component';
import { PathFuelReportComponent } from './_components/pdf-settings/path-fuel/path-fuel-report.component';
import { FuelVariationReportComponent } from './_components/pdf-settings/fuel-variation/fuel-variation-report.component';
import { SuiviVidangeReportComponent } from './_components/pdf-settings/suivi-vidange-report/suivi-vidange-report.component';
import { EtatBoitierActuelComponent } from './_components/pdf-settings/etat_boitier_actuel/etat_boitier_actuel.component';
import { EtatBoitierArretComponent } from './_components/pdf-settings/etat_boitier_arret/etat_boitier_arret.component';
import { RouterModule } from '@angular/router'; // Import RouterModule if routing is used
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import Datepicker if you're using it
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { NgxLoadingModule } from 'ngx-loading';

import { NgScrollbarModule } from 'ngx-scrollbar'; // Importez NgScrollbarModule
import { ModalModule } from 'ngx-bootstrap/modal';
import { PointageVehiculesReportComponent } from './_components/pdf-settings/pointage-vehicules/pointage-vehicules-report.component';

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    NewImportExportComponent,
    PdfSettingsComponent,
    DefaultContentComponent,
    PdfDisplaySectionComponent,
    HeaderOptionsComponent,
    PdfTableComponent,
    PdfExcelCostumComponent,
    PdfExcelCostumEditComponent,
    EtatBoitierExcelComponent,
    VehiculeActivitiesExcelComponent,
    MultipleSheetsExcelComponent,
    GlobalStopsReportComponent,
    ListOfStopsReportComponent,
    EtatBoitierActuelComponent,
    EtatBoitierArretComponent,
    ActivityContactReportComponent,
    PointageVehiculesReportComponent,
    ActivitySpeedReportComponent,
    DailyActivitySpeedReportComponent,
    MileageReportComponent,
    DailyMileageReportComponent,
    MileageExcelComponent,
    ListClientReportComponent,
    ListDriverReportComponent,
    ListPoiReportComponent,
    PathPOIReportComponent,
    SuiviVidangeReportComponent,
    EventReportComponent,
    PathFuelReportComponent,
    FuelVariationReportComponent,
    DrivingQualityReportComponent,
    StopNotificationReportComponent,
    SpeedingReportComponent,
  ],

  imports: [
    CommonModule,
    NgScrollbarModule,
    NewImportExportRoutingModule,
    RouterModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    FormsModule,
    NgSelectModule,
    FormsModule,
    CommonModule,
    NgxDaterangepickerMd,
    MatAutocompleteModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
  ],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  exports: [NewImportExportComponent],
})
export class NewImportExportModule {}
