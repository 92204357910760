<div class="table-container">
    <table *ngIf="excelData">
        <!-- Header Section -->
        <thead>
            <tr>
                <th colspan="2">
                    <span class="fw-semibold">{{ currentUser.username | uppercase }}</span>
                </th>
                <th [attr.colspan]="excelData[0].length - 3" style="text-align: center; font-size: 15px;">
                    {{ excelData[0][1] }}
                </th>

                <th colspan="2" style="text-align: right;">
                    {{ excelData[0][excelData[0].length - 2] }}
                </th>
            </tr>


            <tr class="header-row" *ngIf="excelData[3].length > 3">
                <th *ngFor="let header of excelData[3]">
                    {{ header }}
                </th>
            </tr>
        </thead>

        <!-- Data Section -->
        <tbody>
            <tr *ngFor="let row of excelData.slice(4); let i = index" [class.selected]="selectedRowIndex === i"
                (click)="selectRow(i)">
                <td *ngFor="let cell of row" class="truncate-cell">{{ cell }}</td>
            </tr>
        </tbody>

    </table>
</div>