<div class="card">
  <div class="card-header" (click)="showGraphDisponibleFlotte()">
    <p>...</p>
  </div>
  <div class="card-body"><div id="disponibleFlotte" style="zoom: 0.6500;">
  </div></div>
</div>

<div class="modal fade " tabindex="-1" #disponibleFlotte>
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <!-- ========================== Modal TITLE (START) ===============================-->
          <div class="modal-header">

              <button type="button" class="close" (click)="disponibleFlotte.hide()">&times;</button>

              <h4 class="modal-title" style="text-align:center; font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">

              </h4>
          </div>
          <!-- ========================== Modal TITLE (END) ===============================-->

          <div class="modal-body">
              <div style="text-align: center">
                  <div id="disponibleFlotteModal">
                  </div>
              </div>

          </div>

      </div>
  </div>
</div>
