import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewImportExportComponent } from './new-import-export.component';
import { GlobalStopsReportComponent } from './_components/pdf-settings/global-stop-report/global-stop-report.component';
import { EventReportComponent } from './_components/pdf-settings/event-report/event-report.component';
import { ListClientReportComponent } from './_components/pdf-settings/list_clients_report/list_clients_report.component';
import { ListDriverReportComponent } from './_components/pdf-settings/list_driver_report/list_driver_report.component';
import { ListPoiReportComponent } from './_components/pdf-settings/list_poi_report/list_poi_report.component';
import { ActivityContactReportComponent } from './_components/pdf-settings/activity-contact-report/activity-contact-report.component';
import { ActivitySpeedReportComponent } from './_components/pdf-settings/activity-speed-report/activity-speed-report.component';
import { DailyActivitySpeedReportComponent } from './_components/pdf-settings/daily-activity-speed-report/daily-activity-speed-report.component';
import { SpeedingReportComponent } from './_components/pdf-settings/speeding-report/speeding-report.component';
import { DrivingQualityReportComponent } from './_components/pdf-settings/driving-quality-report/driving-quality-report.component';
import { PathPOIReportComponent } from './_components/pdf-settings/path-poi-report/path-poi-report.component';
import { StopNotificationReportComponent } from './_components/pdf-settings/stop-notif-report/stop-notif-report.component';
import { PathFuelReportComponent } from './_components/pdf-settings/path-fuel/path-fuel-report.component';
import { FuelVariationReportComponent } from './_components/pdf-settings/fuel-variation/fuel-variation-report.component';
import { SuiviVidangeReportComponent } from './_components/pdf-settings/suivi-vidange-report/suivi-vidange-report.component';
import { ListOfStopsReportComponent } from './_components/pdf-settings/list-of-stops-report/list-of-stops-report.component';
import { MileageReportComponent } from './_components/pdf-settings/mileage-report/mileage-report.component';
import { DailyMileageReportComponent } from './_components/pdf-settings/daily-mileage-report/daily-mileage-report.component';
import { EtatBoitierActuelComponent } from './_components/pdf-settings/etat_boitier_actuel/etat_boitier_actuel.component';
import { EtatBoitierArretComponent } from './_components/pdf-settings/etat_boitier_arret/etat_boitier_arret.component';
import { AuthGuard } from 'src/app/utils/security/auth.guard';
import { PointageVehiculesReportComponent } from './_components/pdf-settings/pointage-vehicules/pointage-vehicules-report.component';
const appRoutes: Routes = [
  {
    path: 'dataExport',
    component: NewImportExportComponent,
    // canActivate: [AuthGuard],

    children: [
      {
        path: 'global-stops-report',
        component: GlobalStopsReportComponent,
      },
      {
        path: 'list-of-stops',
        component: ListOfStopsReportComponent,
      },
      {
        path: 'etat-actuel',
        component: EtatBoitierActuelComponent,
      },
      {
        path: 'etat-arret',
        component: EtatBoitierArretComponent,
      },
      {
        path: 'activity-contact',
        component: ActivityContactReportComponent,
      },
      {
        path: 'pointage-vehicules',
        component: PointageVehiculesReportComponent,
      },
      {
        path: 'activity-speed',
        component: ActivitySpeedReportComponent,
      },
      {
        path: 'daily-activity-speed',
        component: DailyActivitySpeedReportComponent,
      },

      {
        path: 'mileage',
        component: MileageReportComponent,
      },

      {
        path: 'daily-mileage',
        component: DailyMileageReportComponent,
      },

      {
        path: 'list-client',
        component: ListClientReportComponent,
      },
      {
        path: 'list-drivers',
        component: ListDriverReportComponent,
      },
      {
        path: 'list-poi',
        component: ListPoiReportComponent,
      },
      {
        path: 'path-poi',
        component: PathPOIReportComponent,
      },

      {
        path: 'path-fuel',
        component: PathFuelReportComponent,
      },
      {
        path: 'suivi-vidange',
        component: SuiviVidangeReportComponent,
      },
      {
        path: 'event',
        component: EventReportComponent,
      },

      {
        path: 'fuel-variation',
        component: FuelVariationReportComponent,
      },

      {
        path: 'driving-quality',
        component: DrivingQualityReportComponent,
      },
      {
        path: 'stop-notif',
        component: StopNotificationReportComponent,
      },
      {
        path: 'speeding',
        component: SpeedingReportComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class NewImportExportRoutingModule {}
