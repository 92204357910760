<mat-card class="mb-25 file-manager-box tagus-card" class="sidebar-area bg-white box-shadow"
    [class.dark-sidebar]="isSidebarDark()" [class.right-sidebar]="isRightSidebar()" style="height: 100%; width: 100%; padding: 16px 2px 16px 16px;
    border-radius: 8px;">

    <h4 style="font-family: 'Josefin Sans', sans-serif;
   
    padding: 5px 0px 5px 5px;" class="mb-0">
        Générateur des rapports
    </h4>

    <mat-card-content style="padding: 0 5px; height: 100%;size: 10px">
        <mat-accordion multi="true">
            <ng-scrollbar visibility="hover" style="height: 95%" class="scroll-bar">
                <div class="sidebar-inner side-bar-rapport" style=" padding: 100px 8px 20px;">
                    <div class="sidebar-menu">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style=" box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Liste des boîtiers</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">
                                <li (click)="setCurentRapport('Etat actuel des boîtiers')"
                                    style="padding: 8px 6px 12px 0px;">
                                    <a routerLink="/client/newDataExport/etat-actuel" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Etat Actuel
                                    </a>
                                </li>
                                <li class="sidemenu-item" (click)="setCurentRapport('Etat des boîtiers en arrêt')">
                                    <a routerLink="/client/newDataExport/etat-arret" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Etat des boîtiers en arrêt
                                    </a>
                                </li>

                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Liste des arrêts</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">


                                <li class="sidemenu-item" (click)="setCurentRapport('Rapport globale des arret')"
                                    style="padding: 8px 6px 12px 0px;">
                                    <a routerLink="/client/newDataExport/global-stops-report" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Rapport Global des Arrêts
                                    </a>
                                </li>


                                <li class="sidemenu-item" (click)="setCurentRapport('Liste des Arrêts')"
                                    style="padding: 8px 6px 12px 0px;">
                                    <a routerLink="/client/newDataExport/list-of-stops" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Rapport des arrêts par véhicules
                                    </a>
                                </li>

                                <li class="sidemenu-item" (click)="setCurentRapport('Notification Arrêt POI')">
                                    <a routerLink="/client/newDataExport/stop-notif" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Notification Arrêt POI
                                    </a>
                                </li>

                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="  box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Activité</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">


                                <li class="sidemenu-item" (click)="setCurentRapport('Rapport des activité contact')"
                                    style="padding: 8px 6px 0px 0px;">
                                    <a routerLink="/client/newDataExport/activity-contact" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Activité Contact
                                    </a>
                                </li>


                                <li class="sidemenu-item" (click)="setCurentRapport('Activité avec vitesse')"
                                    style="padding: 8px 6px 12px 0px;">
                                    <a routerLink="/client/newDataExport/activity-speed" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Activité avec vitesse
                                    </a>
                                </li>

                                <li class="sidemenu-item"
                                    (click)="setCurentRapport('Activité Journalier avec vitesse')">
                                    <a routerLink="/client/newDataExport/daily-activity-speed" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Activité Journalier avec vitesse
                                    </a>
                                </li>

                                <li class="sidemenu-item" (click)="setCurentRapport('rapoort pointage des véhicules')"
                                style="padding: 8px 6px 0px 0px;">
                                <a routerLink="/client/newDataExport/pointage-vehicules" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="sidemenu-link sidemenu-link-custom">
                                    pointage des véhicules
                                </a>
                            </li>

                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Kilométrage</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>



                            <ul class="mat-expansion-panel-body ng-tns-c507-0">


                                <li class="sidemenu-item" (click)="setCurentRapport('kilométrage')"
                                    style="padding: 8px 6px 12px 0px;">
                                    <a routerLink="/client/newDataExport/mileage" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Kilométrage
                                    </a>
                                </li>


                                <li class="sidemenu-item" (click)="setCurentRapport('Kilometrage Journalier')">
                                    <a routerLink="/client/newDataExport/daily-mileage" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Kilométrage Journalier
                                    </a>
                                </li>


                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Liste des Clients</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">


                                <li class="sidemenu-item" (click)="setCurentRapport('Lites des Clients')">
                                    <a routerLink="/client/newDataExport/list-client" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Liste des Clients
                                    </a>
                                </li>





                            </ul>
                        </mat-expansion-panel>



                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Liste des Chauffeurs</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">


                                <li class="sidemenu-item" (click)="setCurentRapport('List des chauffeurs')"
                                    style="padding: 8px 6px 6px 0px;">
                                    <a routerLink="/client/newDataExport/list-drivers" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Liste des Chauffeurs
                                    </a>
                                </li>

                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Liste des POIs</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">


                                <li class="sidemenu-item" (click)="setCurentRapport('Liste des POIs')"
                                    style="padding: 8px 6px 12px 0px;">
                                    <a routerLink="/client/newDataExport/list-poi" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Liste des POIs
                                    </a>
                                </li>

                                <li class="sidemenu-item" (click)="setCurentRapport('Liste des Trajet POI')"
                                    style="padding: 8px 6px 6px 0px;">
                                    <a routerLink="/client/newDataExport/path-poi" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Liste des Trajets POI
                                    </a>
                                </li>

                                <li class="sidemenu-item"
                                    (click)="setCurentRapport('Rapport des trajets et approvisionnements ')"
                                    style="padding: 8px 6px 6px 0px;">
                                    <a routerLink="/client/newDataExport/path-fuel" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Liste des Trajets et approvisionnements
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Rapport Suivi Vidanges</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">

                                <li class="sidemenu-item" (click)="setCurentRapport('Suivi Vidanges')">
                                    <a routerLink="/client/newDataExport/suivi-vidange" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Suivi Vidanges
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Rapport des événements</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">

                                <li class="sidemenu-item" (click)="setCurentRapport('Rapport des événements')">
                                    <a routerLink="/client/newDataExport/event" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Rapport des événements
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>



                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Approvisionnement carburant</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">

                                <li class="sidemenu-item" (click)="setCurentRapport('Approvisionnement carburant')">
                                    <a routerLink="/client/newDataExport/fuel-variation" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Approvisionnement carburant
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>



                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Rapport de qualité de conduite</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">

                                <li class="sidemenu-item" (click)="setCurentRapport('Rapport de qualité de conduite')">
                                    <a routerLink="/client/newDataExport/driving-quality" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Rapport de qualité de conduite
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>




                        <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true"
                            style="box-shadow: none;">
                            <mat-expansion-panel-header class="title-header-sec">
                                <mat-panel-title class="panel-title-costum" style="color: rgb(80 78 78 / 87%);">
                                    <span class="title" style="font-weight: 500;">Rapport Excés Vitesse</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="mat-expansion-panel-body ng-tns-c507-0">

                                <li class="sidemenu-item" (click)="setCurentRapport('Rapport de qualité de conduite')">
                                    <a routerLink="/client/newDataExport/speeding" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Rapport Excés Vitesse
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel> -->
                    </div>

                </div>
            </ng-scrollbar>

        </mat-accordion>
    </mat-card-content>
</mat-card>