import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewImportExportService } from '../../../_service/new-import-export.service';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { User } from 'src/app/authentification/signin/credentials';
@Component({
  selector: 'app-multiple-sheets-excel',
  templateUrl: './multiple-sheets-excel.component.html',
  styleUrls: ['./multiple-sheets-excel.component.scss'],
})
export class MultipleSheetsExcelComponent implements OnInit, OnDestroy {
  excelData: any[] = []; // Contient plusieurs feuilles Excel
  excelData$: Subscription;
  current: string = ''; // Utilisateur courant

  public currentUser: User = new User();
  currentSheet: number = 0; // Onglet actif
  sheetNames: string[] = []; // Noms des feuilles

  constructor(
    private importExportService: NewImportExportService,
    private authService: SigninService
  ) {
    const storedUser = localStorage.getItem('currentUser');
    this.currentUser = storedUser ? JSON.parse(storedUser) : {};
  }

  ngOnInit(): void {
    this.fetchDataAndProcess();
    this.fetchUser();
  }

  ngOnDestroy(): void {
    if (this.excelData$) {
      this.excelData$.unsubscribe();
    }
    this.importExportService.setExcelData(null);
    this.importExportService.setCurentRapport(null);
    this.importExportService.setPdfToBePrinted(null);
    this.importExportService.setExcelToBeProcessed(null);
  }

  fetchUser(): void {
    this.current = this.authService.getCurrentUser().displayName;
  }

  fetchDataAndProcess(): void {
    this.excelData$ = this.importExportService
      .getExcelData()
      .subscribe((data) => {
        if (data && Array.isArray(data)) {
          this.excelData = data;
          this.sheetNames = this.excelData.map((sheet) => sheet.sheetName); // Extraire les noms des feuilles
        }
      });
    console.log(this.excelData);
  }

  // Complète une ligne avec des cellules vides pour correspondre au nombre de colonnes
  fillEmptyCells(row: any[], columnCount: number): any[] {
    while (row.length < columnCount) {
      row.push('');
    }
    return row;
  }

  // Retourne les données de la feuille active avec des cellules complétées, et s'arrête à la première ligne vide
  getActiveSheetData(): any[] {
    const sheetData = this.excelData[this.currentSheet]?.data || [];
    if (sheetData.length === 0) return [];

    const columnCount = sheetData[3]?.length || 0; // Nombre de colonnes d'en-tête

    // Trouver la dernière ligne non vide
    const lastNonEmptyRowIndex = sheetData.reduce(
      (lastIndex: number, row: any[], index: number) => {
        const isRowEmpty = row
          .slice(0, columnCount)
          .every((cell: any) => cell === null || cell === '');
        return isRowEmpty ? lastIndex : index;
      },
      -1
    );

    return sheetData
      .slice(0, lastNonEmptyRowIndex + 1)
      .map((row: any[]) => this.fillEmptyCells(row, columnCount));
  }
  getFilteredData(): any[] {
    return this.getActiveSheetData()
      .slice(4)
      .filter((row: any[]) =>
        row.some((cell: any) => cell && cell.trim() !== '')
      );
  }
  isBoldRow(row: string[]): boolean {
    return row.some((cell: any) => cell && cell.includes('Total'));
  }
}
