import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ImportExportService } from 'src/app/client-management/import-export';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { Vehicule } from '../../data-management/data-management.model';
import { DataManagementService } from '../../data-management/data-management.service';
import { Notification, FuelingNotificationDto } from '../alert';
import { AlertManagementService } from '../alert-management.service';
import { User } from 'src/app/authentification/signin/credentials';

@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.css']
})
export class AlertNotificationsComponent implements OnInit {
  startDate: Date = new Date();
  endDate: Date = new Date();
  notifications: Notification[] = [];
  fuelingNotificationDto: FuelingNotificationDto[] = [];
  location = true;

  searchTerm = '';

  selectedAlert:
    | 'SPEED'
    | 'SPEED_CARTO'
    | 'INGNITION_V0'
    | 'ZONE'
    | 'POI'
    | 'STOP_POI'
    | 'TOW'
    | 'NO_POI'
    | 'DRIVING_QUALITY'
    | 'DOC'
    | 'VIDANGE'
    | 'TIRE'
    | 'BATTERY'
    | 'BRAKE'
    | 'CONSUMPTION'
    | 'ACTIVITY'
    | 'ACTIVITY_DAILY'
    | 'CHARGER'
    | 'APPRO_FUEL'
    | '';

  selectedVehicule: Vehicule;

  public loader: boolean = false;

  /**current user */
  currentUser: User = new User();

  constructor(
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService,
    private datePipe: DatePipe,
    private dataManagementService: DataManagementService,
    private localeService: BsLocaleService,
    private exportService: ImportExportService,
    private geocodingService: GeocodingService,
  ) {
    this.localeService.use('fr');
    this.startDate.setTime(this.startDate.getTime() - 3 * 86400000);
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

    this.selectedAlert = 'SPEED';
  }

  ngOnInit() {
    /**set current user  */
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

    if (this.dataManagementService.selectedVehicule) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
    }

    this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
    });
  }

  getNotificationsByAlertNameOfDeviceId() {
    this.notifications = [];
    this.loader = true;

    this.alertManagementService
      .getNotificationsByAlertNameOfDeviceId(
        this.selectedAlert,
        this.selectedVehicule.idDevice,
        {
          startDate: this.startDate.getTime(),
          endDate: this.endDate.getTime()
        }
      )
      .subscribe(notifications => {
        this.onSelectAlertNotif();
        this.notifications = notifications;
        if (notifications.length > 0) {
          this.toastr.success(
            ' a effectué ' +
            notifications.length +
            ' notifications entre ' +
            this.datePipe.transform(this.startDate, 'dd/MM/yyyy HH:mm:ss', '', 'fr-fr') +
            ' et ' +
            this.datePipe.transform(this.endDate, 'dd/MM/yyyy HH:mm:ss', '', 'fr-fr')
          );

        }

        this.loader = false;

      });
  }


  getFuelingNotificationsOfDeviceId() {
    this.fuelingNotificationDto = [];
    this.loader = true;

    this.alertManagementService
      .getFuelingNotificationsOfDeviceId(
        this.selectedVehicule.idDevice,
        {
          startDate: this.startDate.getTime(),
          endDate: this.endDate.getTime()
        }
      )
      .subscribe(fuelingNotifications => {
        this.onSelectAlertNotif();
        this.fuelingNotificationDto = fuelingNotifications;
        if (fuelingNotifications.length > 0) {
          this.toastr.success(
            ' a effectué ' +
            fuelingNotifications.length +
            ' notifications entre ' +
            this.datePipe.transform(this.startDate, 'dd/MM/yyyy HH:mm:ss', '', 'fr-fr') +
            ' et ' +
            this.datePipe.transform(this.endDate, 'dd/MM/yyyy HH:mm:ss', '', 'fr-fr')
          );

        }

        this.loader = false;

      }, error => {
        this.loader = false;
        let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
        if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
        this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      });
  }


  numberOfNotificationsNotHaveAddress: number = 0;


  generateReportNotifications() {
    this.loader = true;
    this.exportService.getNotificationReport(this.selectedVehicule?.device?.idDevice, this.selectedAlert, {
      startDate: this.startDate.getTime(),
      endDate: this.endDate.getTime()
    })
      .subscribe(
        blob => {
          if (blob.size > 0) {
            importedSaveAs(blob, 'rapport_notifications.xlsx');
          } else {
            this.toastr.warning('Aucune donnée entre ces deux dates !', 'Erreur');
          }
          this.loader = false;
        },
        error => {
          this.loader = false;
          this.toastr.warning('Erreur rapport notifications !', 'Erreur');
        }
      );
  }

  generateReportFuelingNotifications() {
    this.exportService.getFuelingNotificationReport(this.selectedVehicule.device.idDevice, this.selectedVehicule.idVehicule, { startDate: this.startDate.getTime(), endDate: this.endDate.getTime() })
      .subscribe(
        blob => {
          if (blob.size > 0) {
            importedSaveAs(blob, 'rapport_notification_carburant.xlsx');
          } else {
            this.toastr.warning('Aucune donnée entre ces deux dates !', 'Erreur');
          }
          this.loader = false;
        },
        error => {
          this.loader = false;
          this.toastr.warning('Erreur rapport notifications !', 'Erreur');
        }
      );
  }


  showNotification(notification: Notification) {
    switch (this.selectedAlert) {
      case 'DOC':
      case 'VIDANGE':
      case 'TIRE':
      case 'BATTERY':
      case 'CHARGER':
      case 'BRAKE':
      case 'CONSUMPTION':
      case 'ACTIVITY':
      case 'ACTIVITY_DAILY':
      case 'APPRO_FUEL':
        this.location = false;
        break;
      default:
        this.alertManagementService.displayNotification(notification);
    }
  }

  onSelectAlertNotif() {
    switch (this.selectedAlert) {
      case 'DOC':
      case 'VIDANGE':
      case 'TIRE':
      case 'BATTERY':
      case 'CHARGER':
      case 'BRAKE':
      case 'CONSUMPTION':
      case 'ACTIVITY':
      case 'ACTIVITY_DAILY':
      case 'APPRO_FUEL':
        this.location = false;
        break;
    }
  }
}
