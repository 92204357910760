
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
<div class="mainContainer">

  <div class="selectContainer">
    <div class="box" style="border-radius: 0px;min-height: unset; margin-bottom: 0px !important;">
      <div class="box-body" style="padding: 8px 10px 0px 10px !important;">
        <form class="" #stopForm="ngForm">
          <div class="form-group row" style="margin-bottom: -4px !important;">

          <div class="selectStyle">
            <div class="grp-slct">
              <select class="form-control input-sm" name="type" [(ngModel)]="selectedType"
                (ngModelChange)="changeType($event)" required>
                <option [ngValue]=1>
                  Ordonnancement
                </option>
                <option [ngValue]=2>
                  Passage
                </option>
              </select>
              <label>Type</label>
          </div>

          <div class="grp-slct">
            <!-- <div class="col-sm-10"> -->
            <!-- <div style="display: grid; grid-template-columns: 106px 48px;"> -->
                  <div>
                  <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [maxDate]="now" placement="bottom" required
                    (ngModelChange)="onChangeStartDate()">
                <!-- </div> -->
                <label>Date</label>
              </div>

              <!-- <div >
                <div class="col-sm-1 form-btns">
                  <button type="button" class="btn btn-success btn-flat btn-sm" [disabled]="loading || !stopForm.form.valid" style="border-radius: 5px;"
                    (click)="stop()" title="Valider"><b><i class="fa fa-play" aria-hidden="true"></i></b>
                  </button>
                </div>
              </div> -->
          <!-- </div> -->
        </div>
        <div>
          <!-- <button type="button" class="btn btn-primary" disabled>
            {{updateTrackingTimer}} <span class="badge badge-light"><i class="fa fa-refresh" aria-hidden="true"></i></span>
          </button> -->
          <!-- <span class="badge badge-info">{{getTrackingTime()}} <br> <i class="fa fa-refresh" aria-hidden="true"></i></span> -->
        </div>
          </div>
          <div class="selectStyle">
            <div class="grp-slct">
                <select class="form-control input-sm" name="transport" [ngModel]="selectedTransport"
                (ngModelChange)="chooseTransport($event)" required>
                <option *ngFor="let transport of transportTypeService?.transportTypes" [ngValue]="transport">
                  {{transport?.name}}
                </option>
              </select>
                <label>Pôle</label>
            </div>
            <div  style="display: grid; grid-template-columns: 106px 48px; gap: 5px;">
              <div class="grp-slct">
                <select class="form-control input-sm" name="passage" [ngModel]="selectedPassage"
                (ngModelChange)="chooseSchedule($event)" required>
                <option *ngFor="let passage of passageService?.passages" [ngValue]="passage">
                  {{passage?.name | capitalizefirst}}
                </option>
              </select>
                <label>Site</label>
            </div>
            <div>
                <div class="form-btns">
                  <button type="button" class="btn btn-success btn-flat btn-sm" [disabled]="loading || !stopForm.form.valid" style="border-radius: 5px; padding: 5px;"
                    (click)="stop()" title="Valider"><span style="animation: blinker 1s linear infinite;"><b>{{getTrackingTime()}}</b></span> <b><i class="fa fa-refresh" [ngClass]="{'fa-spin': loading}" aria-hidden="true"></i></b>
                  </button>
                </div>
            </div>
          </div>
        </div>
          <!-- <div class="selectStyle">
            <div class="col-sm-1 form-btns">
              <button type="button" class="btn btn-success btn-flat btn-sm" [disabled]="loading || !stopForm.form.valid" style="margin-left: 115px;border-radius: 5px;margin-top:5px;"
                (click)="stop()" title="Valider"> Valider
                <b><i class="fa fa-play" aria-hidden="true"></i></b>
              </button>
            </div>
          </div> -->
          </div>

        </form>
      </div>
    </div>
  </div>

  <div class="cardContainer">

  <div class="stati" style="color: #4963fc;" (click)="getTrackingByFiltre('ALL')">
    <img class="stati-icon" src="assets/icons/concrete-mixer.svg" alt="" style="filter: invert(34%) sepia(71%) saturate(4874%) hue-rotate(227deg) brightness(106%) contrast(98%);">
      <div>
        <b *ngIf="!loading && vehiculesLength">{{vehiculesLength}}</b>
        <b *ngIf="!loading && !vehiculesLength"> 0</b>
          <span class="stati-text" ><strong style="color: #4963fc;">Flotte {{selectedTransportType}}</strong></span>
      </div>
  </div>

      <!-- ==================== Disponible Section ======================= -->

      <div class="stati" style="color: #CB4335;"  (click)="getTrackingByFiltre('Stop')">
        <img class="stati-icon" src="assets/icons/trucks.svg" alt="" style="filter: invert(35%) sepia(12%) saturate(4621%) hue-rotate(325deg) brightness(99%) contrast(101%);">
          <div>
            <b *ngIf="!loading && availableLength">{{availableLength}}</b>
          <b *ngIf="!loading && !availableLength"> 0</b>
              <span class="stati-text" ><strong style="color: #CB4335;">Disponible</strong></span>
          </div>
      </div>

      <!-- ==================== Retour Section (click)="getTrackingByFiltre('BackToSite')"======================= -->
  <div class="stati" style="color: #2C3E50;" (click)="navigateToAnotherPage()">
    <img class="stati-icon" src="assets/icons/trucks.svg" alt="" style="filter: invert(19%) sepia(24%) saturate(846%) hue-rotate(169deg) brightness(96%) contrast(87%);">
      <div>
        <b *ngIf="!loading && backToSiteLength">{{backToSiteLength}}</b>
        <b *ngIf="!loading && !backToSiteLength"> 0</b>
          <span class="stati-text" ><strong style="color: #2C3E50;">Retour Site</strong></span>
      </div>
  </div>


<!-- ==================== Flotte affectée Section ======================= -->

  <div class="stati" style="color: #ffc535;"  (click)="getTrackingByFiltre('Between')">
    <img class="stati-icon" src="assets/icons/content.svg" alt="" style="filter: invert(75%) sepia(65%) saturate(554%) hue-rotate(339deg) brightness(103%) contrast(104%);">
      <div>
        <b *ngIf="!loading && productionLength">{{productionLength}}</b>
      <b *ngIf="!loading && !productionLength"> 0</b>
          <span class="stati-text" ><strong style="color: #ffc535;">Affecté</strong></span>
      </div>
  </div>


<!-- ==================== En mission Section ======================= -->
  <div class="stati" style="color: #27c144;"  (click)="getTrackingByFiltre('Start')">
    <img class="stati-icon" src="assets/icons/fast-delivery.svg" alt="" style="filter: invert(57%) sepia(79%) saturate(478%) hue-rotate(79deg) brightness(89%) contrast(92%);">
      <div>
        <b *ngIf="!loading && ActiveLength">{{ActiveLength}}</b>
      <b *ngIf="!loading && !ActiveLength"> 0</b>
          <span class="stati-text" ><strong style="color: #27c144;">En mission</strong></span>
      </div>
  </div>

<!-- ==================== En Panne Section ======================= -->

  <div class="stati" style="color: #000;"  (click)="getTrackingByFiltre('BreakDown')">
    <img class="stati-icon" src="assets/icons/wrench.svg" alt="" style="filter: invert(0%) sepia(3%) saturate(8%) hue-rotate(358deg) brightness(104%) contrast(102%);">
      <div>
        <b *ngIf="!loading && breakDownLength">{{breakDownLength}}</b>
      <b *ngIf="!loading && !breakDownLength"> 0</b>
          <span class="stati-text" ><strong style="color: #000;">En Panne</strong></span>
      </div>
  </div>

<!-- ==================== Non Actif Section ======================= -->


  <div class="stati" style="color: #a8a7a7;"  (click)="getTrackingByFiltre('Available')">
    <img class="stati-icon" src="assets/icons/forbidden.svg" alt="" style="filter: invert(73%) sepia(20%) saturate(0%) hue-rotate(282deg) brightness(87%) contrast(94%);">
      <div>
        <b *ngIf="!loading && unactiveLength">{{unactiveLength}}</b>
        <b *ngIf="!loading && !unactiveLength"> 0</b>
          <span class="stati-text" ><strong style="color: #a8a7a7;">Non Actif</strong></span>
      </div>
  </div>

<!-- ==================== Autre Site Section ======================= -->
  <div class="stati" style="color: #c744ac;"  (click)="getTrackingByFiltre('Other')">
    <img class="stati-icon" src="assets/icons/trucks.svg" alt="" style="filter: invert(63%) sepia(57%) saturate(6778%) hue-rotate(285deg) brightness(82%) contrast(87%);">
      <div>
        <b *ngIf="!loading && availableInOtherSiteLength">{{availableInOtherSiteLength}}</b>
        <b *ngIf="!loading && !availableInOtherSiteLength"> 0</b>
          <span class="stati-text" ><strong style="color: #c744ac;">Autre Site</strong></span>
      </div>
  </div>


</div>

</div>
<!-- <div class="row">
  <div *ngIf="loading" class="col-sm-12 text-center" style="font-size: 12px;margin-top: 15px ;margin-bottom: 20px;">
    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
  </div>
  <div class="col-sm-12 text-center" *ngIf="!loading && updateTrackingTimer" style="font-size: 12px;margin-top: 15px ;margin-bottom: 20px;">
    <i class="fa fa-hourglass-half" aria-hidden="true"></i>
    Mise à jour dans {{updateTrackingTimer}} seconds...
  </div>
</div> -->

<div class="row">
  <div *ngIf="isToday && selectedType == 1" class="col-xl-12 col-md-12 mb-12 col-xs-12" style="margin-top: 20px">
    <div class="col-xl-2 col-md-2 mb-2 col-xs-2" *ngIf="availableLength > 0">
      <div class="option-dashbord">
        <span title="Client" (click)="showClientForm()"><i class="fa fa-plus-circle add"></i> Client</span>
      </div>
    </div>
    <div class="col-xl-2 col-md-2 mb-2 col-xs-2" *ngIf="vehiculesLength > 0">
      <div class="option-dashbord">
        <span title="Panne" (click)="showBreakForm(true)"><i class="fa fa-plus-circle add"></i>
          Panne</span>
      </div>
    </div>
    <div class="col-xl-2 col-md-2 mb-2 col-xs-2" *ngIf="vehiculesLength > 0">
      <div class="option-dashbord">
        <span title="Panne" (click)="showBreakForm(false)"><i class="fa fa-plus-circle add"></i>
          Activer/Désactiver</span>
      </div>
    </div>
    <div class="col-xl-2 col-md-2 mb-2 col-xs-2" *ngIf="vehiculesLength > 0">
      <div class="option-dashbord">
        <span title="OT" (click)="showOTForm()"><i class="fa fa-plus-circle add"></i>
          OT</span>
      </div>
    </div>

    <div class="col-xl-2 col-md-2 mb-2 col-xs-2" *ngIf="vehiculesLength > 0">
      <div class="option-dashbord">
          <div class="card cardOt">
            <div class="card-header otHeader">
              <div>
                <span (click)="expendTable()">
                  <i class="fa fa-list" aria-hidden="true"></i>
                  <b> Liste Numéro OT</b>
                </span>
              </div>
              <div>
              <input class="form-control form-control-sm" type="text" name="search" *ngIf="otExpanded" [(ngModel)]="searchText.matricule" placeholder="Matricule...">
              </div>
              <div>
                <button class="btn btn-info btn-sm" (click)="getOtNumberList()" style="border-radius: 5px;"><i class="fa fa-refresh" aria-hidden="true"></i></button>
              </div>
            </div>
            <div class="card-body scroll-bar" *ngIf="otExpanded" style="">
              <div *ngFor="let item of vehiculeOTList | filterBy:searchText">
                <div class="card cardList" [ngStyle]="{'background-color': item.background}">
                  <div class="listVec" [ngStyle]="{'color': item.color}">
                      <div class="matricule">{{item.matricule}}</div>
                      <div class="otNumber">{{item.otNumber}}</div>
                      <div class="date">{{item.date | date:'HH:mm'}}</div>
                  </div>
                </div>
              </div>

            </div>
            <!-- <div class="card-footer"></div> -->
          </div>
      </div>
    </div>
  </div>

  <div class="col-xl-12 col-md-12 mb-12 col-xs-12">
    <!-- <div class=" mt-2 mb-2 text-center well bgg">
      <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
        <i class="fa fa-plus-circle add"></i>
        <span> Ordonnancement</span>
      </h4>
    </div> -->

    <div *ngIf="!loading">
    <app-schedule-chart *ngIf="scheduleListOrg.length > 0 && selectedType == 1" [scheduleList]="scheduleListOrg">
    </app-schedule-chart>
    </div>
    <app-passage-chart *ngIf="selectedType == 2" [selectedDate]="dateInterval?.startDate" [numberOfPassage]="selectedPassage?.numberOfPassage"
      [passageList]="scheduleListOrg"></app-passage-chart>
  </div>
</div>

<!-- ========================================== Client Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #clientModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style=" border-radius: 23px;">

      <!-- ========================== Modal TITLE (START) ===============================-->
      <div class="modal-header">

        <button type="button" class="close" (click)="clientModalhide()">&times;</button>


        <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
          <i class="fa fa-user" aria-hidden="true"></i> Client
        </h4>
      </div>
      <!-- ========================== Modal TITLE (END) ===============================-->

      <div class="modal-body">
        <div class="box-body">
          <app-scheduling-form [scheduleList]="scheduleListForm" [clientSource]="clientSource" [selectedPassage]="selectedPassage"
            [isBreakDown]="false" [pointInterests]="pointInterests" [poiClient] = "poiClient" [validators]="validators" (scheduleSaved)="clientWasSaved()" (hideschedule)="hideschedule()">
          </app-scheduling-form>
        </div>
      </div>

      <div class="box-footer" style="border-radius: 23px;">
        <button (click)="clientModalhide()" class="btn btn-default pull-right">
          Fermer
        </button>
      </div>

    </div>

  </div>
</div>
<!-- ========================================== client Modal (END) ====================================================-->



<!-- ========================================== Panne Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #breakModal="bs-modal" bsModal role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- ========================== Modal TITLE (START) ===============================-->
      <div class="modal-header">

        <button type="button" class="close" (click)="breakModalhide()">&times;</button>

        <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
          <i class="fa fa-gear" aria-hidden="true"></i>
          <span *ngIf="isBreakDownModal">Panne</span>
          <span *ngIf="!isBreakDownModal">Activer/Désactiver</span>
        </h4>
      </div>
      <!-- ========================== Modal TITLE (END) ===============================-->

      <div class="modal-body">
        <div class="box-body">
          <app-scheduling-form [isBreakDownModal]="isBreakDownModal" [scheduleList]="scheduleListBreakForm" [selectedPassage]="selectedPassage"
            [isBreakDown]="true" (scheduleSaved)="breakWasSaved()" ></app-scheduling-form>
        </div>
      </div>

      <div class="box-footer">
        <button (click)="breakModalhide()" class="btn btn-default pull-right">
          Fermer
        </button>
      </div>

    </div>

  </div>
</div>
<!-- ========================================== Panne Modal (END) ====================================================-->



<!-- ========================================== OT Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #OTModal="bs-modal" bsModal role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- ========================== Modal TITLE (START) ===============================-->
      <div class="modal-header">

        <button type="button" class="close" (click)="OTModalhide()">&times;</button>

        <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
          <i class="fa fa-gear" aria-hidden="true"></i>
          <span> OT</span>

        </h4>
      </div>
      <!-- ========================== Modal TITLE (END) ===============================-->

      <div class="modal-body">
        <div class="box-body">
            <app-numero-ot [scheduleListForm]="scheduleListForm" [scheduleList]="scheduleList" [passage]="selectedPassage" [passages]="passageService?.passages" (hideOtMadal)="hideOtMadal($event)"></app-numero-ot>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- ========================================== OT Modal (END) ====================================================-->



