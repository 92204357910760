<div class="table-container">
    <table *ngIf="excelData">
        <!-- Header Section -->
        <thead>
            <tr *ngIf="excelData[3].length > 3; else singleHeaderRow">
                <th colspan="2">
                    <span class="fw-semibold">{{currentUser.username | uppercase }}</span>

                </th>
                <th [attr.colspan]="excelData[0].length - 3" style="text-align: center; font-size: 15px;">
                    {{ excelData[0][1] }}
                </th>

                <th colspan="4" style="text-align: right;">
                    {{ excelData[0][excelData[0].length - 1] }}
                </th>
            </tr>
            <ng-template #singleHeaderRow>
                <tr>
                    <th [attr.colspan]="excelData[0].length || 1" style="text-align: left; padding: 0; height: 40px;">
                        <div
                            style="display: flex; justify-content: space-between; align-items: center; width: 100%; height: 100%;">
                            <span style="text-align: left; margin-left: 2em;">{{ currentUser }}</span>
                            <span style="text-align: center; flex-grow: 1;">{{ excelData[0][2] }}</span>
                            <span style="text-align: right;">{{ excelData[0][excelData[0].length - 1] }}</span>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <tr class="header-row" *ngIf="excelData[3].length <= 3">
                <th>{{ excelData[3][0] }}</th>
                <th>{{ excelData[3][excelData[3].length - 1] }}</th>
            </tr>
            <tr class="header-row" *ngIf="excelData[3].length > 3">
                <th *ngFor="let header of excelData[3]">
                    {{ header }}
                </th>
            </tr>
        </thead>

        <!-- Data Section -->
        <tbody>
            <tr *ngFor="let row of excelData.slice(4); let i = index" [class.selected]="selectedRowIndex === i"
                (click)="selectRow(i)">

                <!-- Première colonne avec affichage conditionnel du rowspan -->
                <td *ngIf="excelData[3][0] !== ('Prénom' || 'Groupe') && rowspans[i] > 0" [attr.rowspan]="rowspans[i]"
                    class="header-row">
                    {{ row[0] }}
                </td>

                <!-- Si excelData[3][0] === 'Prénom', on affiche simplement la valeur sans traitement de rowspan -->
                <td *ngIf="excelData[3][0] === 'Prénom'" class="header-row">
                    {{ row[0] }}
                </td>

                <!-- Autres colonnes, avec fusion des cellules vides uniquement si excelData[3].length <= 3 -->
                <ng-container *ngIf="excelData[3].length <= 3">
                    <ng-container *ngFor="let cell of row.slice(1); let j = index">
                        <td *ngIf="cell && row[j+1] !== ''" class="truncate-cell">
                            {{ cell }}
                        </td>
                        <td *ngIf="!cell && row[j+1] === ''" [attr.colspan]="2" class="truncate-cell">
                            {{ cell }}
                        </td>
                    </ng-container>
                </ng-container>

                <!-- Si excelData[3].length > 3, on ne fusionne pas les cellules vides, on affiche normalement -->
                <ng-container *ngIf="excelData[3].length > 3">
                    <ng-container *ngFor="let cell of row.slice(1)">
                        <td class="truncate-cell">
                            {{ cell }}
                        </td>
                    </ng-container>
                </ng-container>

            </tr>
        </tbody>

    </table>
</div>