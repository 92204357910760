<div class="app-content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">

  <div id="map" class="leaflet-fullscreen-on" [ngClass]="!isFullscreen && according ? 'according-screen' : ''">
    <!-- Icon to toggle the search input -->
    <div
      style="position: absolute; top: 10px; right: 45px; z-index: 99999; display: flex; align-items: center; gap: 5px;">
      <!-- Search Icon -->
      <i *ngIf="!isSearchVisible" class="fa fa-search btnShowStyle" (click)="toggleSearch()">
      </i>

      <!-- Close Icon -->
      <i *ngIf="isSearchVisible" class="fa fa-times btnCloseStyle" (click)="toggleSearch()">
      </i>
    </div>

    <!-- Search Input -->
    <div *ngIf="isSearchVisible" style="display: flex; flex-direction: column; gap: 10px; z-index: 19000;">
      <input [(ngModel)]="searchText" (input)="fetchSuggestions()" placeholder="Type a city or place"
        class="inputTextSerchStyle" />
      <ul *ngIf="suggestions.length" class="dropList dropListstyle">
        <li *ngFor="let suggestion of suggestions"
          style="padding: 5px; cursor: pointer; background-color: #d7e0f2; margin: 1px;"
          (click)="selectSuggestion(suggestion)">
          {{ suggestion.text }}
        </li>
      </ul>

    </div>

    <div *ngIf="showButtonPoi" style="position: absolute; top: 191px; right: 45px; z-index: 99999; display: flex; align-items: center; gap: 5px;">
      <i class="fa fa-map-marker testBtn" (click)="showpoiclick()"></i>

      <i class="fa fa-map-o testBtn" (click)="showpoizoneclick()">
      </i>
    </div>



  </div>


  <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header black-bg">


          <h4 *ngIf="showButtons" class="modal-title pull-left"
            style="font-family: 'Inconsolata', monospace;font-size: 17px;">
            <i class="fa fa-plus-circle"></i>
            <span *ngIf="pointInterest.type == 1 || poiClient.type == 1">Nouvelle zone</span>
            <span *ngIf="(selectedPoiForm === 'POI') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau point
              d'intérêt</span>
            <span *ngIf="(selectedPoiForm === 'CLIENT') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau
              client</span>
          </h4>
          <h4 *ngIf="!showButtons" class="modal-title pull-left"
            style="font-family: 'Inconsolata', monospace;font-size: 17px;">
            <i class="fa fa-plus-circle"></i>
            <span *ngIf="pointInterest.type == 1 || poiClient.type == 1">Nouvelle zone</span>

            <span *ngIf="(selectedType != 'client') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau point
              d'intérêt</span>
            <span *ngIf="(selectedType === 'client') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau
              client</span>
          </h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div *ngIf="showButtons" align="center" style="padding-top: 15px;">
          <button type="button" (click)='selectedPoiType(1)'
            [ngClass]="status ? 'btn-success': 'btn-secondary active-point'" aria-haspopup="true" aria-expanded="false"
            style="width: 30%;" [disabled]="status" class="btn btn-secondary">
            <font size="4">Client</font>
          </button>
          <button type="button" (click)='selectedPoiType(2)'
            [ngClass]="!status ? 'btn-success': 'btn-secondary active-point'" aria-haspopup="true" aria-expanded="false"
            style="width: 30%;" [disabled]="!status" class="btn">
            <font size="4">Point d'intérêt</font>
          </button>
        </div>

        <div class="modal-body">
          <app-poi-form *ngIf="selectedType != 'client' " [pointInterest]="pointInterest"
            [selectedPoiForm]="selectedPoiForm" [mode]="'ADD'" (pointInterestWasAdded)="childModal.hide()">
          </app-poi-form>
          <app-poi-client-form *ngIf="selectedType === 'client'" [poiItems]="poiClientsPoint" [poiClient]="poiClient"
            [mode]="'ADD'" (poiClientWasAdded)="childModal.hide()"></app-poi-client-form>
        </div>
      </div>
    </div>
  </div>

</div>