import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Observable, Subscription } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';
import { DataManagementService, GroupDto, RapportDto } from '../../data-management';
import { PathService } from '../../historical';
import { FuelingPriceSettingService, TravelReportDto } from '../../parc-management';
import { saveAs as importedSaveAs } from 'file-saver';
import { ImportExportService } from '../../import-export';
import { TransportTypeInfoDto, TransportTypeService, TransportTypeTravelReport } from '../parametrage/transport-type';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CanalInfoPfbDto } from '../../canal-performance';
import { CanalPfbService } from '../parametrage/_service/canalpfb.service';

@Component({
  selector: 'app-travel-report',
  templateUrl: './travel-report.component.html',
  styleUrls: ['./travel-report.component.css']
})
export class TravelReportComponent implements OnInit, OnDestroy {





  @ViewChild('costomizeModal', { static: false }) costomizeModal: ModalDirective;
  repportGenarate: RapportDto = new RapportDto();
  maxDate: Date = new Date();
  tousCanal = new CanalInfoPfbDto(-1, 'Tous les Sites');
  tousTransport = { idTransportType: -1, name: 'Tous les Transports' };



  selectedCanal: CanalInfoPfbDto = new CanalInfoPfbDto();



  travelReports: Observable<TravelReportDto[]>;
  expandData = false;
  selectedGroup = new GroupDto();
  groups: GroupDto[] = [];
  loading = false;
  travelReportLoader = false;
  loader = false;
  dateInterval: DateInterval = new DateInterval();
  startDate: Date = new Date();
  selectedTransport = new TransportTypeTravelReport();
  selectedTransportII: TransportTypeInfoDto;


  vehiculesId: number[] = [];
  // travelReports$: Observable<TravelReportDto[]>;
  travelReportToExport: TravelReportDto[] = [];
  repport = new RapportDto();

  updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  expand: boolean[] = [];
  sizeTable = 0;
  s201 = 0;
  s202 = 0;
  s203 = 0;
  s204 = 0;
  s205 = 0;
  s211 = 0;
  s212 = 0;

  idSelected: number | null = null;

  constructor(
    private groupService: DataManagementService,
    public transportTypeService: TransportTypeService,
    public toastr: ToastrService,
    private pathService: PathService,
    private canalPfbService: CanalPfbService,
    private fuelingPriceSettingService: FuelingPriceSettingService,
    private service: ImportExportService
  ) {
    this.travelReports = this.pathService.getTravelReport();

    this.repport.type = 'PATH_APPRO_FUEL_POI';
    this.repport.rapportPayloadDto.byGroups = false;
    this.repport.rapportPayloadDto.byVehicules = true;
    this.repport.rapportPayloadDto.allVehicules = false;

    this.travelReports.subscribe(travelReport => {
      this.travelReportToExport = travelReport;
      this.sizeTable = travelReport.length;
      this.expand = new Array(travelReport.length).fill(false);
      const count: Record<string, number> = {};
      const sites = travelReport.map(x => x.startAddress);
      sites.forEach(i => count[i] = (count[i] || 0) + 1);
      const objToMap = new Map(Object.entries(count));
      this.s201 = Number(objToMap.get("201") || 0);
      this.s202 = Number(objToMap.get("202") || 0);
      this.s203 = Number(objToMap.get("203") || 0);
      this.s204 = Number(objToMap.get("204") || 0);
      this.s205 = Number(objToMap.get("205") || 0);
      this.s211 = Number(objToMap.get("211") || 0);
      this.s212 = Number(objToMap.get("212") || 0);
    });
  }

  ngOnInit(): void {
    this.loadLastFueulingPrice();
    this.initializeDateInterval();
    this.loadTransportTypes();
    this.startUpdateTrackingTimer();
    this.loadCanalPfb();
  }

  initializeDateInterval(): void {
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.startDate.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.repportGenarate.dateInterval.endDate = this.dateInterval.endDate;
    this.repportGenarate.dateInterval.startDate = this.dateInterval.startDate;
  }

  loadLastFueulingPrice(): void {
    this.loading = true;
    this.fuelingPriceSettingService.getLastFuelingPrice().subscribe(
      fuelingPrice => {
        this.pathService.fuelingPriceSetting = fuelingPrice;
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.toastr.error('Erreur lors du chargement', 'Prix Carburant');
      }
    );
  }

  loadTransportTypes(): void {
    if (this.transportTypeService.transportTypeTravelReport.length <= 0) {
      this.loading = true;
      this.transportTypeService.getAllTransportType().subscribe(
        transportTypes => {
          // this.transportTypeService.transportTypes = transportTypes;
          this.transportTypeService.transportTypeTravelReport = transportTypes;
          if (transportTypes.length > 0) {
            this.selectTransport(transportTypes[transportTypes.length - 1]);
            this.loadTravelReport();
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.toastr.error('Erreur lors du chargement', 'Type de transport');
        }
      );
    } else {
      this.selectTransport(this.transportTypeService.transportTypeTravelReport[this.transportTypeService.transportTypeTravelReport.length - 1]);
      this.loadTravelReport();
    }
  }

  selectTransport(transport: TransportTypeTravelReport): void {
    this.selectedTransport = transport;
    this.idSelected = transport.idTransportType;
    this.vehiculesId = transport.vehicules.map(v => v.idVehicule);
    this.stopTracking();
    this.loadTravelReport();
    this.startUpdateTrackingTimer();
  }


  openPopUp() {
    this.costomizeModal.show();
    this.loadCanalPfb();
    this.loadTransportTypesII();

  }

  chooseTransport(transport: any) {
    this.idSelected = transport.idTransportType;
    this.selectedTransport = transport;
    this.vehiculesId = this.selectedTransport.vehicules.map(v => { return v.idVehicule });
    this.stopTracking();
    this.loadTravelReport();
    this.startUpdateTrackingTimer();
  }

  loadTransportTypesII() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.transportTypeService.findAllTransportTypes().subscribe(
        (transportTypes) => {
          this.transportTypeService.transportTypes = transportTypes;

          if (transportTypes.length > 0) {
            this.selectedTransportII = transportTypes[transportTypes.length - 1];
          }
        },
        (err) => {
          this.toastr.error('Erreur lors de chargements', 'Type de transport');
        }
      );
    } else {
      this.selectedTransportII =
        this.transportTypeService.transportTypes[
        this.transportTypeService.transportTypes.length - 1
        ];
      // this.loading = false;
    }
  }



  loadTravelReport(): void {
    this.loading = true;
    const transportTypeId = [this.selectedTransport.idTransportType];
    const startDate = this.pathService.deviceStartDateMap.get(transportTypeId[0]) || new Date();
    startDate.setHours(0, 0, 0, 0);
    this.pathService.generateTravelReport(transportTypeId, { startDate, endDate: this.dateInterval.endDate }
    ).subscribe(
      travelReport => {
        this.pathService.updateTravelReport(travelReport, this.selectedTransport.vehicules, transportTypeId, startDate);
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.toastr.error('Erreur lors du chargement', 'Véhicules');
      }
    );
  }

  expandRow(i: number): void {
    this.expand[i] = !this.expand[i];
  }

  expendedRow(i: number) {
    return this.expand[i]
  }


  expandAll(): void {
    this.expand = new Array(this.sizeTable).fill(!this.expand[0]);
  }

  stopTracking(): void {
    this.updateTrackingTimerSubscription?.unsubscribe();
    this.updateTrackingTimer = null;
  }

  ngOnDestroy(): void {
    this.stopTracking();
  }

  startUpdateTrackingTimer(): void {
    this.stopTracking();
    this.updateTrackingTimer = 1200;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      if (this.updateTrackingTimer && this.updateTrackingTimer > 0) {
        this.updateTrackingTimer--;
      } else {
        this.stopTracking();
        this.loadTravelReport();
      }
    });
  }

  refresh(): void {
    this.loadTravelReport();
    this.startUpdateTrackingTimer();
  }

  exportTravelReport(): void {
    this.travelReportLoader = true;
    this.repport.dateInterval = this.dateInterval;
    this.repport.rapportPayloadDto.idVehicules = this.selectedTransport.vehicules.map(v => v.idVehicule);
    this.repport.travelsReport = this.travelReportToExport;

    this.service.getTravelRepport(this.repport, this.selectedTransport.name).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'Suivi voyages.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates!', 'Erreur');
        }
        this.travelReportLoader = false;
      },
      () => {
        this.toastr.warning('Aucune donnée entre ces deux dates!', 'Erreur');
        this.travelReportLoader = false;
      }
    );
  }

  setTimeTostart() {
    if (this.repportGenarate.dateInterval.startDate) {
      let startDate = new Date(this.repportGenarate.dateInterval.startDate);
      startDate.setHours(0, 0, 0, 0); // Set to 00:00:00

      this.repportGenarate.dateInterval.startDate = startDate;
    }
  }


  setTimeToend() {
    if (this.repportGenarate.dateInterval.endDate) {
      let endDate = new Date(this.repportGenarate.dateInterval.endDate);
      endDate.setHours(23, 59, 0, 0);
      this.repportGenarate.dateInterval.endDate = endDate;
    }
  }



  canals: CanalInfoPfbDto[] = [];


  loadCanalPfb() {
    this.canalPfbService.findAllCanalPfb().subscribe(
      (canals) => {
        this.canals = canals;
        if (canals.length > 0) {
          const canalFound = canals.find((p) => p.name.startsWith('201'));
          if (canalFound) {
            this.selectedCanal = canalFound;
          }
        }
      },
      (err) => {
        this.toastr.error('Erreur lors de chargements', 'Canal');
      }
    );
  }

  selectCanal() {
    this.selectedCanal.idCanal;
  }

  selectPole() {
    this.selectedTransportII.idTransportType;
  }

  onUpload() {
  }

  concel() {
    this.costomizeModal.hide();
  }





  onDownoaldingReport() {
    this.loader = true;
    let cmp = 0;
    let nbDays = 30;
    this.repportGenarate.format = "EXCEL";
    this.repportGenarate.type = 'PATH_APPRO_FUEL_POI'
    if (
      this.repportGenarate.type === 'PATH_APPRO_FUEL_POI'
    ) {
      cmp =
        this.repportGenarate.dateInterval.endDate.getTime() -
        this.repportGenarate.dateInterval.startDate.getTime();
      cmp = cmp / 1000;
      if (
        this.repportGenarate.type === 'PATH_APPRO_FUEL_POI'
      )
        nbDays = 7;
      if (cmp > nbDays * 86400) {
        this.toastr.warning(
          'La durée pour ce rapport ne peut pas dépasser ' +
          nbDays +
          ' jours !',
          'Alerte'
        );
        this.loader = false;
        return;
      }
    }

    let vehiculesCanalIds = [];
    let vehiculesTransportIds: any[] = [];
    if (this.repportGenarate.type == 'PATH_APPRO_FUEL_POI') {

      if (this.selectedCanal.idCanal == -1) {
        vehiculesCanalIds = this.getVehiculeIdsFromCanal(
          this.canals
        );
      }
      else {
        const filteredCanals: CanalInfoPfbDto[] =
          this.canals.filter(
            (c) => c.idCanal === this.selectedCanal.idCanal
          );
        vehiculesCanalIds = this.getVehiculeIdsFromCanal(filteredCanals);
      }

      if (this.selectedTransport.idTransportType == -1) {
        vehiculesTransportIds = this.getVehiculeIdsFromTransport(
          this.transportTypeService.transportTypeTravelReport
        );
      }

      else {
        const filteredTransport: TransportTypeTravelReport[] = this.transportTypeService.transportTypeTravelReport.filter(
          (t) => t.idTransportType === this.selectedTransport.idTransportType
        );
        vehiculesTransportIds = this.getVehiculeIdsFromTransport(filteredTransport);
      }

      let intersection: number[] = vehiculesCanalIds.filter((id) =>
        vehiculesTransportIds.includes(id)
      );

      this.repportGenarate.rapportPayloadDto.allVehicules = false;
      this.repportGenarate.rapportPayloadDto.byGroups = false;
      this.repportGenarate.rapportPayloadDto.byVehicules = true;
      this.repportGenarate.rapportPayloadDto.idVehicules = intersection;
    }

    this.service.getRepport(this.repportGenarate).subscribe(
      (blob) => {
        if (blob.size > 0) {
          importedSaveAs(
            blob,
            'Rapport ' + this.service.getReportName(this.repportGenarate.type) + '.xlsx'
          );
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        }
        this.loader = false;
        this.concel();
      },
      (error) => {
        this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        this.loader = false;
      }
    );
  }




  getVehiculeIdsFromCanal(data: CanalInfoPfbDto[]): any[] {
    return data.reduce(
      (acc, level1) =>
        acc.concat(
          level1.groups.reduce(
            (accGroup, group) =>
              accGroup.concat(
                group.vehicules.map((vehicule) => vehicule.idVehicule)
              ),
            []
          )
        ),
      []
    );
  }

  getVehiculeIdsFromTransport(data: TransportTypeTravelReport[]): any[] {

    return data.reduce(
      (acc, level1) =>
        acc.concat(level1.vehicules.map((vehicule) => vehicule.idVehicule)),
      []
    );
  }

}