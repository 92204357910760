import { Component, Input } from "@angular/core";
import { DeviceCarb } from "src/app/client-management/statistical/technical-data/technical-data";
import { ToastrService } from "ngx-toastr";
import { Vehicule } from "../../../data-management/data-management.model";
import { Fueling } from "../../model";
const Highcharts = require('highcharts');

@Component({
    selector: 'app-carburant-dashbord',
    styleUrls: ['./carburant-dashbord.component.css'],
    templateUrl: './carburant-dashbord.component.html'
})
export class CarburantDashBordComponent {


      options: Highcharts.Options;
      Highcharts: typeof Highcharts = Highcharts;

    @Input()
    set fuels(fuels: Fueling[]) {
        if (fuels) {
            this._fuels = Object.assign([], fuels);
            this.CreateChart();
        }
    }

    @Input()
    set vehicule(vehicule: Vehicule) {
        if (vehicule) {
            this.selectedVehicule = vehicule;
        }
    }

    @Input()
    currentCarbSetting: DeviceCarb = new DeviceCarb();

    _fuels: Fueling[] = [];

    selectedVehicule: Vehicule = null;

    // options of cart
    // options: Object | null = null;

    constructor(public toastr: ToastrService) { }

    CreateChart() {

        if (!this.selectedVehicule || !this.currentCarbSetting.fuelAverage) return;

        var dataConsumption: any[] = [];
        var dataConsumptionDiff: any[] = [];
        var dataConsumptionVehicule: any[] = [];

        // sort by date asc
        this._fuels = this._fuels.sort((one, two) => (one.operationTime < two.operationTime ? -1 : 1));

        // generate infos
        let index = 0;
        for (let fuel of this._fuels) {
            index++;
            let consumption: number = 0;
            if (fuel.consumption) {
                consumption = fuel.consumption;
            } else {
                if (index > 1 && index <= this._fuels.length && fuel.quantity) {
                    if (fuel.signification.toLowerCase() == 'h') {
                        consumption = +(fuel.quantity / (fuel.odometre - this._fuels[index - 2].odometre)).toFixed(2);
                    } else {
                        consumption = +(fuel.quantity / (fuel.odometre - this._fuels[index - 2].odometre) * 100).toFixed(2);
                    }
                }
            }
            dataConsumption.push([fuel.operationTime, consumption]);
            dataConsumptionDiff.push([fuel.operationTime, Number((consumption - this.currentCarbSetting.fuelAverage).toFixed(2))]);
            dataConsumptionVehicule.push([fuel.operationTime, this.currentCarbSetting.fuelAverage + this.currentCarbSetting.margin, this.currentCarbSetting.fuelAverage - this.currentCarbSetting.margin]);
        }

        // set lang hightCart
        this.setHighCartsLang();


        this.options = {
            chart: {
              type: 'line',
            },
            title: {
              text: 'Rapport graphique de consommation carburants',
            },
            subtitle: {
              text: `Véhicule : ${this.selectedVehicule.matricule} (CT=${this.currentCarbSetting.fuelAverage}%)`,
            },
            xAxis: {
              type: 'datetime',
            },
            yAxis: {
              title: {
                text: 'Consommation (%)',
              },
            },
            tooltip: {
              shared: true,
              valueSuffix: '%',
            },
            plotOptions: {
              line: {
                dataLabels: {
                  enabled: true,
                },
                enableMouseTracking: true,
              },
              arearange: {
                dataLabels: {
                  enabled: false,
                },
                enableMouseTracking: true,
              },
            },
            series: [
              {
                name: 'Réel',
                type: 'line',
                data: dataConsumption,
                zIndex: 1,
                color: '#7cb5ec',
                marker: {
                  fillColor: 'white',
                  lineWidth: 2,
                  lineColor: '#7cb5ec',
                },
              } as Highcharts.SeriesLineOptions,
              {
                name: 'Théorique',
                type: 'arearange',
                data: dataConsumptionVehicule,
                zIndex: 0,
                lineWidth: 0,
                color: '#ffad33',
                marker: {
                  enabled: true,
                  radius: 2,
                  lineWidth: 2,
                },
              } as Highcharts.SeriesArearangeOptions,
              {
                name: 'Différence',
                type: 'line',
                data: dataConsumptionDiff,
                zIndex: 1,
                visible: false,
                color: '#FF0000',
                lineWidth: 1,
                marker: {
                  fillColor: 'white',
                  lineWidth: 1,
                  lineColor: '#FF0000',
                },
              } as Highcharts.SeriesLineOptions,
            ],
          };

    }

    // function for set lang hightcarts
    setHighCartsLang() {
        Highcharts.setOptions({
            lang: {
                printChart: 'Imprimer',
                thousandsSep: ',',
                months: [
                    'Janvier', 'Février', 'Mars', 'Avril',
                    'Mai', 'Juin', 'Juillet', 'Août',
                    'Septembre', 'Octobre', 'Novembre', 'Décembre'
                ],
                weekdays: [
                    'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
                    'Jeudi', 'Vendredi', 'Samedi'
                ],
                shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
                downloadPNG: 'Télécharger image PNG',
                downloadJPEG: 'Télécharger image JPEG',
                downloadPDF: 'Télécharger image PDF',
                downloadSVG: 'Télécharger image SVG'
            }
        });
    }
}
