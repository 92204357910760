import { Component, OnInit } from '@angular/core';
import { SigninService } from '../../authentification/signin/signin.service';
import { User } from 'src/app/authentification/signin/credentials';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.css']
})
export class AccountManagementComponent implements OnInit {
  accounts: User[];
  loading = false;
  constructor(private signinService: SigninService) {
  }

  ngOnInit() {
    this.signinService.getAccounts().subscribe( listAcount =>{
      console.log(listAcount)
      this.accounts =listAcount;

    })
  }
}
